import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';

import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { config } from '../../../shared/smartadmin.config';
import { UserService } from '../../../shared/user';
const swal = require('sweetalert2');

@Component({
    selector: 'app-form-preview-print',
    templateUrl: './form-preview-print.component.html',
    styleUrls: ['./form-preview-print.component.css']
})
export class FormPreviewPrintComponent implements OnInit {

    itemToSave: any;
    endPointReport: any;
    currentLink: any;
    typeFile : String = '';
    isGenererQuitus: boolean = false;
    user: any = {}
    currentPrestataire: any = {};
    busySave: Subscription;
    titleReportDocumentSortie : string;

    constructor(public sanitizer: DomSanitizer, private api: Api, public bsModalRefPreview: BsModalRef, public router: Router, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
        this.user = this.userService.getCurrentUser();
        this.itemToSave = {
            isReady: false,
            //filUri : "https://angular.io/api/platform-browser/DomSanitizer",
            // filUri : "http://localhost:82/PointagePro/wepApi/Report/ReportsPPM/PaiementElectronicPeriodeOuReference.php?date_debut=2018-03-01&date_fin=2018-03-04&libellereport=BORDEREAU&libellesociete=ANSUT#toolbar=1&amp;zoom=80,0,0&amp;page=1&amp;view=FitH"
        };

        this.getCurrentFile();

    }

    gotoDashbordPage() {
        this.hideModale();
        this.router.navigate(['/dashbord']); 
    }

    getCurrentFile()
    {
        console.log("this.titleReportDocumentSortie",this.titleReportDocumentSortie);

        setTimeout(() => {
            let link = this.currentLink;
            // link = link.replace("https://easy-enrolement-demo.smile.ci/api/","https://certificat-residence-demo.smile.ci/api/certificatResidence/")
            console.log(" le lien du fichier ",link);
            //Dans le cas ou c'est une impression de document de sortie patienter 3 secondes
            // if(this.titleReportDocumentSortie)
            // {
            //     this.itemToSave.fileUrlFinale = null;
            //     this.notificationService.showMessageSucces("Document de sortie","Veuillez patienter, chargement du document de sortie en cours !");
            // }
            this.cleanURL(link);  
           
        }, 1000);
    }

    cleanURL(oldURL) {
       
        if (!this.itemToSave.isReady && oldURL) {
            this.itemToSave.isReady = true;
            this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
        }

    }

    hideModale() {
        this.bsModalRefPreview.content.isSave = false;
        this.bsModalRefPreview.hide();
    }

    ngOnInit() {
    }
    

}
