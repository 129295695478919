import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
import { Observable } from 'rxjs';
import { enumKeyCrypto_AES } from '../../../shared/utils/enumerator';

@Component({
  selector: 'app-main-user-session-active',
  templateUrl: './main-user-session-active.component.html',
  styleUrls: ['./main-user-session-active.component.css']
})
export class MainUserSessionActiveComponent implements OnInit {

  
  results: Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeCartes: Array<any> = [];
  ListeClients :  Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  requestRefresh: Subscription;
  currentPositionGps : any = {};
  customMaskContact : string = "";

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {

    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15; 
    this.currentItemChecked = {};
    this.itemToSearch = {};
    let  maxLengthContact = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.longueurContactParam)) || 0;
    this.customMaskContact = this.utilities.formatMaskContact(maxLengthContact);

  }

  pageChanged(event: any): void {

    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();

  }

  changeItermPerPage() {

    this.currentPage = 1;
    this.getData();

  }

  refreshData() {
    this.requestRefresh = Observable.interval(1*60*1000)
        .subscribe(i => {
            this.getData()
        })
  }

  getData() {

    let userLogin = this.userService.getCurrentUser();

    if(!userLogin.token) return;

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if (search_value && this.oldValueSearch) {
      if (search_value != this.oldValueSearch) {
        this.currentPage = 1;
      }
    }

    if (search_value) {
      if (search_value.length > 0 && search_value.length < 3) {
        if (!this.isChangeItermPerPage) {
          return;
        }
      }
      else {
        if (search_value == this.oldValueSearch) {
          return;
        }
        this.oldValueSearch = search_value;
      }
    }
    else {
      if (this.oldValueSearch == "" && isSearch) {
        return;
      }

      this.oldValueSearch = "";
    }

    var request = {
      user: this.user.proxy,
      isRefreshAuto : true,
      data: {
        libelle: this.itemToSearch.fullText || null,
        client : (this.itemToSearch && this.itemToSearch.clientSearch)  ? this.itemToSearch.clientSearch.id : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('utilisateurs/getSessionActive', request)
      .subscribe(
        res => {

          if (res && res['items']) {
            this.results = _.orderBy(res['items'], ['id'], ['desc']);

            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
        }
      );

  }

  confirmFermerSession(obj) {

    swal({
      title: 'Fermerture de session',
      text: 'Désirez-vous fermer la session de cet utilisateur ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.fermerSessionUser(obj);
      } 
    })
  }

  fermerSessionUser(item)
  {
  
    const request = {
      data:
        {
          id: item.id,
          source: "Web",
          referenceSource: navigator.userAgent,
          positionGps: (this.currentPositionGps && this.currentPositionGps.positionGps) ? this.currentPositionGps.positionGps : "0,0"
        } 
    }

    this.busyGet = this.api.post('utilisateurs/logout', request)
      .subscribe(
        res => {
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Fermerture de session", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.getData();
          }
          else
          {
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
        }
      );
  }


  getPositionGps() {
    this.currentPositionGps.positionGps = '0,0';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
       
        if (position && position.coords) {
          this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
        }
        
      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }


  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  ngOnDestroy() {
    if (this.requestRefresh) this.requestRefresh.unsubscribe();
  }

  ngOnInit() {
    this.getData();
    this.refreshData();
    window.scrollTo(0, 0);
  }


}
