import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UserService} from "../../user/user.service";
import { timeout } from 'rxjs/operators'; 

@Injectable()
export class Api {
    url: any;
    user :any;
    constructor(public http: HttpClient,private userService: UserService) {
         this.user = this.userService.getCurrentUser();  
         this.url = 'https://certificat-residence-demo.smile.ci/api/certificatResidence/'; 
        //  this.url = 'https://certificat-residence.oneci.ci/api/certificatResidence/'; 
    }


    get(endpoint: string, params?: any, options?: HttpParams) {

        return this.http.get(this.url + '/' + endpoint);
    }

    getOption(endpoint: string, params?: any, options?: any) {

        return this.http.get(this.url + '/' + endpoint,options);
    }

    postOption(endpoint: string, body: any, options: any) {
        return this.http.post(this.url + '/' + endpoint, body,options);
    }

    post(endpoint: string, body: any, options?: any) {
       
        this.user = this.userService.getCurrentUser(); 
        if(this.user && this.user.token) 
        {
            options = {
                headers: { 'token': this.user.token || this.user.session }
            }
        }
        
        return this.http.post(this.url + '/' + endpoint, body,options).pipe(timeout(1*60*1000)); 
    }

    postValidationCode(endpoint: string, body: any, options?: any) {
        console.log(" Appel post connexion ");
        return this.http.post(this.url + '/' + endpoint, body,options).pipe(timeout(1*60*1000)); 
    }

    postTest(endpoint: string, body: any, options?: RequestOptions) {
      
        return this.http.post('http://e-tiers-api.swanpro.net/ispc-backend' + '/' + endpoint, body);
    }

    put(endpoint: string, body: any, options?: RequestOptions) {
        return this.http.put(this.url + '/' + endpoint, body);
    }

    delete(endpoint: string, options?: RequestOptions) {
        return this.http.delete(this.url + '/' + endpoint);
    }

    patch(endpoint: string, body: any, options?: RequestOptions) {
        return this.http.put(this.url + '/' + endpoint, body);
    }
}
