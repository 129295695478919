import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
type AOA = any[][];
import * as XLSX from 'xlsx';
import { enumParametreBase, enumModeDeRecherche, enumCategorieDemandeur, enumKeyCrypto_AES, enumLibelleCustom, enumProfilGratuit, enumDefaultNumeroPaiement, enumTypeDemandeur } from '../../../shared/utils/enumerator';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
import * as moment from 'moment';
import { listLocales, defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {frLocale} from 'ngx-bootstrap/locale';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-form-create-demande',
  templateUrl: './form-create-demande.component.html',
  styleUrls: ['./form-create-demande.component.css']
})
export class FormCreateDemandeComponent implements OnInit {

  locale = 'fr';
  results: Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeCartes: Array<any> = [];
  ListeDaysNaissance: Array<any> = [];
  ListeMonthNaissance: Array<any> = [];
  ListeObjetsDemande: Array<any> = [];
  ListeDocumentsJustificatif: Array<any> = [];
  ListeNaturesPieceWithPhoto: Array<any> = [];
  isAgentBieAndObjetDemandeDifferentPJ : boolean = false;
  ListePiecesJointePossibleAAuthentifier: Array<any> = [];
  ListeNaturesPieceMandataireOrTitulaire: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  montantPaiementParametre: number;
  itemDocumentAdd: any = {};
  currentPositionGps: any = {};
  itemToSave: any = {};
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any = {};
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  categorieDemandeurEnum: any = {};
  stepWizard: number = 1;
  ListeDemandes: Array<any> = [];
  ListeNaturesDemandes: Array<any> = [];
  ListeNaturesPiece: Array<any> = [];
  currentFileJoint: any = {};
  fileJoint: any = {};
  currentItemSelected: any = {};
  oldValueDocumentUpdate: any = {};
  currentLigneDocumentJoint: any = {};
  maxLengthContact: number;
  idDemandeToUpdate: number;
  ListePieceJustificativesSupprime: Array<any> = [];
  ListeDepartements: Array<any> = [];
  bsModalRefPreview: BsModalRef;
  userHasProfilWithDemandeGratuit: boolean = false;
  modeRechercheEnum: any = {};
  customMaskContact: string = '';
  libelleEnumCustom: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api, private router: Router, private modalService: BsModalService, private route: ActivatedRoute, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService,private localeService: BsLocaleService) {

    this.user = this.userService.getCurrentUser();
    this.categorieDemandeurEnum = enumCategorieDemandeur;
    this.modeRechercheEnum = enumModeDeRecherche;
    this.libelleEnumCustom = enumLibelleCustom;

    this.getDaysNaissance();
    this.getMonthNaissance();

    this.cancelSave();
    this.localeService.use(this.locale);

    this.userHasProfilWithDemandeGratuit = _.find(this.user.fonctionnalites, (o) => { return o.code == enumProfilGratuit.DEMANDE_GRATUITE; });; //Vérifier si l'user à ce profil

    //Dans le cas ou l'user est un agent BIE preselectionner procedure judiciaire
    if (this.user.isAgentBie) {
      setTimeout(() => {
        this.itemToSave.objetDemandeSelected = _.find(this.ListeObjetsDemande, { 'libelle': enumLibelleCustom.ProcedureJudiciaire });
      }, 500);
    }

    this.route.params.subscribe(params => {

      if (params['isUpdateDemande']) {

        let currentDemande = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.currentDemandeToUpdate)); //JSON.parse(sessionStorage.getItem('currentDemandeToUpdate'));

        if (currentDemande) {

          // if(currentDemande.document.isGratuit) 
          // {
          //   currentDemande.document.numeroPaiement = enumDefaultNumeroPaiement.defaultCaractere; 
          // }

          this.idDemandeToUpdate = currentDemande.document.id;
          this.itemToSave = currentDemande.demandeursDto;
          this.itemToSave.emailDemandeurPP = this.itemToSave.emailDemandeur;
          this.itemToSave.emailDemandeurPM = this.itemToSave.emailDemandeur;
          this.itemToSave.contactDemandeurPP = this.itemToSave.contactDemandeur;
          this.itemToSave.contactDemandeurPM = this.itemToSave.contactDemandeur;


          if (currentDemande.document && currentDemande.document.jourNaissance) {
            if (currentDemande.document.jourNaissance < 10) {
              currentDemande.document.jourNaissance = '0' + currentDemande.document.jourNaissance;
            }
            currentDemande.document.jourNaissanceSelected = _.find(this.ListeDaysNaissance, { 'day': currentDemande.document.jourNaissance.toString() });
          }

          if (currentDemande.document && currentDemande.document.moisNaissance) {
            if (currentDemande.document.moisNaissance < 10) {
              currentDemande.document.moisNaissance = '0' + currentDemande.document.moisNaissance;
            }

            currentDemande.document.moisNaissanceSelected = currentDemande.document.moisNaissance.toString(); //_.find(this.ListeMonthNaissance, { 'month': currentDemande.document.moisNaissance.toString() });
          }

          this.montantPaiementParametre = currentDemande.document.montantPaiement;
          this.ListeDemandes = [currentDemande.document]; //Ajouter un element à la liste des documents à authentifier mais il sera masqué

          this.getDataParametreBase(); //Verifier le montant de paiement 

          if (currentDemande.demandeursDto && currentDemande.demandeursDto.typeDemandeur) {

            this.changeDemandeurInformation(currentDemande.demandeursDto.typeDemandeur);

            if (currentDemande.demandeursDto.typeDemandeur == enumTypeDemandeur.Mandataire) {
              this.getPiecesJustificativesByTypeDemandeur(true);
            }
            else {
              this.getPiecesJustificativesByTypeDemandeur(false);
            }

          }

          //Preselectionner la nature et l'objet
          setTimeout(() => {
            this.getDataToUpdate();
          }, 500);
          
        }

      }
    });

    //Verifier si c'est une demande gratuite, alors affecter un tiret à la demande
    if (this.userHasProfilWithDemandeGratuit && !this.idDemandeToUpdate) {
      this.itemDocumentAdd['numeroPaiement'] = enumDefaultNumeroPaiement.defaultCaractere;
    }
  }

  maxDate = new Date();


  getDataToUpdate() {
    console.log("l'objet de la modification ");
    if (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) {
      this.itemToSave.natureDemandeSelected = _.find(this.ListeNaturesDemandes, { 'id': this.itemToSave.natureDemande });
      this.itemToSave.objetDemandeSelected = _.find(this.ListeObjetsDemande, { 'id': this.itemToSave.objetDemande });
      this.itemToSave.lieuDemandeSelected = _.find(this.ListeDepartements, { 'libelle': this.itemToSave.lieuDemande });

      //Dans le cas ou c'est une procedure judiciaire preselectionné le type de document de sortie
      if(this.itemToSave.objetDemandeSelected && this.itemToSave.objetDemandeSelected.libelle == enumLibelleCustom.ProcedureJudiciaire)
      {
        this.itemToSave.typeDocumentSortieSelected = this.itemToSave.documentSortie; 
      }

      //Recuperer la nature de piece si elle existe
      if (this.itemToSave.naturePiece) {
        this.itemToSave.natureDemandePPSelected = _.find(this.ListeNaturesPieceMandataireOrTitulaire, { 'id': this.itemToSave.naturePiece });
      }
      else {
        //Preselectionner aucun dans le cas ou lorsqu'aucune piece n'est 
        if (!this.itemToSave.numeroPiece) {
          this.itemToSave.natureDemandePPSelected = enumLibelleCustom.LIBELLE_NATURE_PIECE_AUCUN;
        }
      }


      if (this.itemToSave.categorieDemandeur == enumCategorieDemandeur.PersonnePhysique) {
        this.itemToSave.contactDemandeurPP = this.itemToSave.contactDemandeur;
      }
      else {
        this.itemToSave.contactDemandeurPM = this.itemToSave.contactDemandeur;
      }

      this.ListeDemandes[0].piecesJustificativesForVueFront = this.ListeDemandes[0].piecesJustificatives;
      this.modifiedItem(this.ListeDemandes[0], true); //Cela correspond a l objet demande en cours de modification

    }
  }

  transformUppercase(item, isPieceJointe, isNumeroDoc,isLibelleAutreObjet) {

    if(isLibelleAutreObjet)
    {
      this.itemToSave.libelleAutreObjet = item.toUpperCase();
      return;
    }
    
    if (!isPieceJointe && !isNumeroDoc ) {
      this.itemToSave.libelleAutreNatureDocument = this.itemToSave.libelleAutreNatureDocument.toUpperCase();
    }
    else {
      if (!isNumeroDoc) {
        this.itemDocumentAdd.libelleAutreNatureDocument = this.itemDocumentAdd.libelleAutreNatureDocument.toUpperCase();
      }
      else {
        if(isPieceJointe)
        {
          this.itemDocumentAdd.numeroDocument = item.toUpperCase();
        }
        
      }
    }
  }

  showPreviewPrintModal(fileBase64, urlFile) {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = fileBase64 || urlFile;
    
  }

  gotoNexStep(indice, isGotoPrecedentWizard) {

    if (indice) {

      if (indice == 2) {

        if (!isGotoPrecedentWizard) //On fait un retour à l'etape precedente
        {
 
          //verifier selectionner la date de demande
          if (!this.itemToSave.dateDemande) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la date de la demande.");
            return;
          }

          //Test formatage de la date
          let dateFormatOperation = moment(this.itemToSave.dateDemande).format("DD/MM/YYYY");
          if (dateFormatOperation == "Invalid date") {
            dateFormatOperation = this.itemToSave.dateDemande;
          }
          console.log(" dateFormatOperation ",dateFormatOperation); 

          //verifier selectionner la date de demande
          if (!this.itemToSave.lieuDemandeSelected || !this.itemToSave.lieuDemandeSelected.libelle) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le lieu de demande.");
            return;
          }

          //verifier  si la nature demande et l'objet sont selectionné
          if (!this.itemToSave.natureDemandeSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner la nature de la demande.");
            return;
          }

          //verifier  si l'objet sont selectionné
          if (!this.itemToSave.objetDemandeSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner l'objet de la demande.");
            return;
          }

          //verifier  si l'objet sont selectionné et que c'est une procedure judiciaire alors il doit precisé le type de document de sortie
          if (this.itemToSave.objetDemandeSelected.libelle == this.libelleEnumCustom.ProcedureJudiciaire && !this.itemToSave.typeDocumentSortieSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner le type de document de sortie.");
            return;
          }

            //verifier  si l'objet sont selectionné et que le libelle est autre alors il doit renseigne le libelleObjet autre
            if (this.itemToSave.objetDemandeSelected.libelle == this.libelleEnumCustom.LIBELLE_AUTRE && !this.itemToSave.libelleAutreObjet) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le libellé de l'objet.");
              return;
            }


          //Verifier si les infos du demandeur sont renseigner
          if (this.itemToSave.categorieDemandeur == this.categorieDemandeurEnum.PersonnePhysique) {
            if (!this.itemToSave.typeDemandeur) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le type du demandeur.");
              return;
            }

            if (!this.itemToSave.natureDemandePPSelected) {
              this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la nature de pièce.");
              return;
            }

            if (this.itemToSave.natureDemandePPSelected && this.itemToSave.natureDemandePPSelected.libelle == enumLibelleCustom.LIBELLE_AUTRE) {
              if (!this.itemToSave.libelleAutreNatureDocument) {
                this.notificationService.showMessageError("Erreur", "Veuillez renseigner le libelle de pièce.");
                return;
              }
            }

            if (this.itemToSave.natureDemandePPSelected != enumLibelleCustom.LIBELLE_NATURE_PIECE_AUCUN && !this.itemToSave.numeroPiece) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le numéro de pièce.");
              return;
            }

            if (!this.itemToSave.nomDemandeur) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le nom du demandeur.");
              return;
            }

            if (!this.itemToSave.prenomsDemandeur) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le prénoms du demandeur.");
              return;
            }

            if (!this.itemToSave.contactDemandeurPP) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le contact du demandeur.");
              return;
            }

            //---------------------------------------------------------------------------------------------------
            //Reproduire les informations dans le cas ou c'est pr sois même et que la liste des demandes est vide
            //On ne reproduire que le nom et le prenoms et le numéro de document
            //---------------------------------------------------------------------------------------------------

            let thisPieceExistForAuthentification: any = {};
            //Preselectionner la nature de document à authentifier
            if (this.itemToSave.natureDemandePPSelected) {
              thisPieceExistForAuthentification = _.find(this.ListePiecesJointePossibleAAuthentifier, (o) => { return o.id == this.itemToSave.natureDemandePPSelected.id });
              if (thisPieceExistForAuthentification) {

                //--------------------------------------------------------------------------------------
                // Verifier si le numero de document n'est pas renseigné 
                // Ensuite si la nature selectionné fait partie des natures de document a authentifier
                // Dans le cas ou il fait partie de cette liste le preselectionner et affecter le numero
                //---------------------------------------------------------------------------------------

                if (!this.itemDocumentAdd.numeroDocument) {
                  if (this.itemToSave.numeroPiece) {
                    this.itemDocumentAdd.numeroDocument = this.itemToSave.numeroPiece.toUpperCase();
                  }
                }

              }
            }

            if (this.itemToSave.typeDemandeur == enumTypeDemandeur.PourSoiMeme) {
              if (!this.ListeDemandes || this.ListeDemandes.length == 0) {
                //Vérifier si il n'était pas entrain de renseigner des valeurs
                // this.itemDocumentAdd.numeroDocument = !this.itemToSave.numeroPiece ? this.itemToSave.numeroPiece : null;

                if (!this.itemDocumentAdd.nom) {
                  this.itemDocumentAdd.nom = this.itemToSave.nomDemandeur ? this.itemToSave.nomDemandeur : null;
                }

                if (!this.itemDocumentAdd.prenoms) {
                  this.itemDocumentAdd.prenoms = this.itemToSave.prenomsDemandeur ? this.itemToSave.prenomsDemandeur : null;
                }

                //Preselectionner la nature de document à authentifier
                // if (this.itemToSave.natureDemandePPSelected) {
                //   let thisPieceExistForAuthentification = _.find(this.ListePiecesJointePossibleAAuthentifier, (o) => { return o.id == this.itemToSave.natureDemandePPSelected.id });
                if (thisPieceExistForAuthentification) {
                  if (!this.itemDocumentAdd.natureSelectedSearchByNomEtPrenom) {
                    this.itemDocumentAdd.natureSelectedSearchByNomEtPrenom = thisPieceExistForAuthentification;
                  }

                  if (!this.itemDocumentAdd.natureSelectedSearchByNumDoc) {
                    this.itemDocumentAdd.natureSelectedSearchByNumDoc = thisPieceExistForAuthentification;
                  }

                }
                // }


              }

              this.getPiecesJustificativesByTypeDemandeur(false);
            }
            else {
              // Il s'agit d'un mandataire

              if (thisPieceExistForAuthentification) {
                if (!this.itemDocumentAdd.natureSelectedSearchByNumDoc) {
                  this.itemDocumentAdd.natureSelectedSearchByNumDoc = thisPieceExistForAuthentification;
                }

              }

              this.getPiecesJustificativesByTypeDemandeur(true);
            }

          }

          if (this.itemToSave.categorieDemandeur == this.categorieDemandeurEnum.personneMorale) {

            if (!this.itemToSave.raisonSociale) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner la raison sociale.");
              return;
            }

            if (!this.itemToSave.personneAContacter) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner les informations de la personne à contacter.");
              return;
            }

            if (!this.itemToSave.contactDemandeurPM) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le contact du demandeur.");
              return;
            }

            this.getPiecesJustificativesByTypeDemandeur(false);

          }

          let contact = this.itemToSave.contactDemandeurPM || this.itemToSave.contactDemandeurPP;
          if (contact && contact.length < this.maxLengthContact) {
            this.notificationService.showMessageError("Erreur", "Le contact doit être de " + this.maxLengthContact + " caractères .");
            return;
          }

          //Verifier si le mail saisi est correcte
          if (this.itemToSave.emailDemandeurPP || this.itemToSave.emailDemandeurPM) {
            let emailDemandeur = this.itemToSave.emailDemandeurPP || this.itemToSave.emailDemandeurPM;
            let tabSplitArrobase = emailDemandeur.split("@");
            if (!tabSplitArrobase || tabSplitArrobase.length < 2) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner un email valide.");
              return;
            }
            else {
              //Verifier si il y a un point apres l'arobase
              let tabSplitPoint = tabSplitArrobase[1].split(".");
              if (!tabSplitPoint || tabSplitPoint.length < 2) {
                this.notificationService.showMessageError("Erreur", "Veuillez renseigner un email valide.");
                return;
              }
            }
          }

          //Dans le cas ou c'est une demande gratuite, mettre le montant à 0
          if (this.userHasProfilWithDemandeGratuit) {
            this.montantPaiementParametre = 0;
          }

          //Verifier si le profil est BIE et que l'objet selectionné est different de procedure judiciaire, on laisse l'utilisateur
          //Renseigner le numero
          if(this.user.isAgentBie && this.itemToSave.objetDemandeSelected.libelle != this.libelleEnumCustom.ProcedureJudiciaire)
          {
            this.isAgentBieAndObjetDemandeDifferentPJ = true;
            //Retirer le tiret par defaut
            this.itemDocumentAdd.numeroPaiement = "";
          }
          else
          {
            this.isAgentBieAndObjetDemandeDifferentPJ = false;
            
            if(this.userHasProfilWithDemandeGratuit)
            {
              this.itemDocumentAdd.numeroPaiement = enumDefaultNumeroPaiement.defaultCaractere;
            }
            
          }

          if (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) {
            this.ListeDemandes[0].piecesJustificativesForVueFront = this.ListeDemandes[0].piecesJustificativesForVueFront || this.ListeDemandes[0].piecesJustificatives;
            this.modifiedItem(this.ListeDemandes[0], true); //Cela correspond a l objet demande en cours de modification
          }

        }
        else {
          //C'est un retour à l'étape precedente (Vérifier si nous sommes en modification a appellé la fonction de modification)
          if (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) {
            this.ListeDocumentsJustificatif = this.ListeDemandes[0].piecesJustificativesForVueFront;
            this.modifiedItem(this.ListeDemandes[0], true); //Cela correspond a l objet demande en cours de modification
          }
        }

      }


      if (indice == 3 && !isGotoPrecedentWizard) {

        if (this.idDemandeToUpdate) // Ajouter à la liste des demandes masqué toutefois on effectue les controls des champs obligatoires
        {
          this.addListeDemande(this.itemDocumentAdd);

          if (this.itemDocumentAdd.hasError) {
            return;
          }
        }

        //Verifier si la liste des personnes à authentifier est renseigné
        if (!this.ListeDemandes || this.ListeDemandes.length == 0) {
          this.notificationService.showMessageError("Erreur", "Aucun document à authentifier.");
          return;
        }

      }

      this.stepWizard = indice;
    }
  }

  addListeDemande(demande) {

    // console.log(" on passe a la 3 eme etpes ",demande);
    if (demande) {

      //Verifier si il y a un numéro de paiement
      if (!demande.numeroPaiement) {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner le numéro de paiement !");
        demande.hasError = true;
        return;
      }

      //Verifier si il y a ce numero de paiement est déjà dans la liste si c'est un ajout
      if (!this.idDemandeToUpdate) {

        if (((!this.userHasProfilWithDemandeGratuit))) { //Dans le cas ou nous ne sommes pas en modification
          let hasNumeroPaiementInListe = _.find(this.ListeDemandes, (o) => { return o.numeroPaiement.toUpperCase() == demande.numeroPaiement.toUpperCase(); });

          if (hasNumeroPaiementInListe) {
            if (this.oldValueDocumentUpdate && hasNumeroPaiementInListe.numeroPaiement.toUpperCase() != this.oldValueDocumentUpdate.numeroPaiement.toUpperCase()) {
              this.notificationService.showMessageError("Erreur", "Ce numéro de paiement existe déjà dans la liste !");
              demande.hasError = true;
              return;
            }

          }

        }

      }


      //Controle si c'est la recherche par numero de document
      if (this.itemToSave.modeRecherche == enumModeDeRecherche.refDocument) {

        if (!demande.natureSelectedSearchByNumDoc) {
          this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la nature du document !");
          demande.hasError = true;
          return;
        }

        if (!demande.numeroDocument) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner le numéro du document !");
          demande.hasError = true;
          return;
        }

        //Verifier si le même numero avec la meme reference n'est pas deja enregistré
        let hasNatureDemandeAndNumeroDocInListe = _.find(this.ListeDemandes, { 'natureDocument': demande.natureSelectedSearchByNumDoc.id && 'numeroDocument' == demande.numeroDocument });
        if (hasNatureDemandeAndNumeroDocInListe) {
          this.notificationService.showMessageError("Erreur", "Cette nature et ce numéro de document existe déjà dans la liste !");
          demande.hasError = true;
          return;
        }

        demande.natureDocument = demande.natureSelectedSearchByNumDoc.id;

        //Vider les données pour les champs, de nom et prenoms
        demande.nom = null;
        demande.prenoms = null;
        demande.genre = null;
        demande.jourNaissance = null;
        demande.annee = null;
        demande.moisNaissance = null;
        demande.lieuNaissance = null;
        demande.nomPrenomsPere = null;
        demande.nomPrenomsMere = null;

      }
      else {

        //Il s'agit d'une recherche par nom et prénoms
        //On verifie si les champs obligatoires sont renseigné

        if (!demande.natureSelectedSearchByNomEtPrenom) {
          this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la nature du document !");
          demande.hasError = true;
          return;
        }

        if (!demande.nom || !demande.prenoms) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner le nom et prénoms de la personne à authentifier !");
          demande.hasError = true;
          return;
        }

        if (!demande.genre) {
          this.notificationService.showMessageError("Erreur", "Veuillez sélectionner le genre !");
          demande.hasError = true;
          return;
        }

        if (demande.jourNaissanceSelected && demande.jourNaissanceSelected.day) {
          demande.jourNaissance = demande.jourNaissanceSelected.day;
        }
        else {
          demande.jourNaissance = null;
        }

        if (demande.moisNaissanceSelected) {
          demande.moisNaissance = demande.moisNaissanceSelected;
        }
        else {
          demande.moisNaissance = null;
        }

        demande.natureDocument = demande.natureSelectedSearchByNomEtPrenom.id;
        demande.naturePiecesLibelle = demande.natureSelectedSearchByNomEtPrenom.libelle;
        demande.numeroDocument = null; //Vider le numero de document
      }

      demande.isUpdate = null;
      demande.modeRecherche = this.itemToSave.modeRecherche;
      demande.montantPaiement = this.montantPaiementParametre;

      demande.piecesJustificatives = this.getListePieceJointeToSaveByDemande(); //listePiecesJustificativesToSave;
      demande.piecesJustificativesForVueFront = this.ListeDocumentsJustificatif;

      //Verifier si nous somme en modification alors recuperer l'element correspondant et remplacer les valeurs
      if (this.oldValueDocumentUpdate && this.oldValueDocumentUpdate.numeroPaiement) {
        let indiceTableOldValue = this.ListeDemandes.indexOf(this.oldValueDocumentUpdate);
        this.ListeDemandes[indiceTableOldValue] = demande;
        this.initValueDocumentAAjouter();
        return;
      }

      this.ListeDemandes.push(Object.assign({}, demande));
      console.log(" this.ListeDemandes ", this.ListeDemandes);

      this.initValueDocumentAAjouter();
    }
    else {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner les informations de la recherche !");
    }
  }

  getListePieceJointeToSaveByDemande() {
    //Consituter la liste des fichiers réèls à enregistrer en tenant compte des fichiers modifier et supprimé
    let listePiecesJustificativesToSave = [];
    if (this.ListeDocumentsJustificatif && this.ListeDocumentsJustificatif.length > 0) {
      //Recuper la fiste des document ayant id null / isUpdateLigneDocument à true
      listePiecesJustificativesToSave = _.filter(this.ListeDocumentsJustificatif, (o) => { return !o.id || o.isUpdateLigneDocument; });
    }

    //Faire un concat avec la liste des documents ayant un id supprimé
    listePiecesJustificativesToSave = listePiecesJustificativesToSave.concat(this.ListePieceJustificativesSupprime);

    return listePiecesJustificativesToSave;
  }

  initValueDocumentAAjouter() {
    this.ListeDocumentsJustificatif = [];
    this.itemDocumentAdd = {
      numeroPaiement: ((this.userHasProfilWithDemandeGratuit && !this.isAgentBieAndObjetDemandeDifferentPJ)) ? (enumDefaultNumeroPaiement.defaultCaractere) : null
    };
    this.oldValueDocumentUpdate = {};
  }

  removeItem(indice) {

    swal({
      title: 'Suppression document',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.ListeDemandes.splice(indice, 1);
      }
    })
  }


  modifiedItem(item, isPreviewInfoFromUpdate?) {

    if (this.oldValueDocumentUpdate) this.oldValueDocumentUpdate.isUpdate = false; //Pour signifier qu'on ne modifie plus cette ligne, en ce moment le color s'applique sur la ligne selectionnée

    item.isUpdate = true;
    this.oldValueDocumentUpdate = item;

    console.log(" la valeur passée pour la modification ", item);

    //Verifier si il y a un numero de document qui est en cours de saisir
    if (this.itemDocumentAdd && this.itemDocumentAdd.numeroPaiement && this.itemDocumentAdd.numeroPaiement != enumDefaultNumeroPaiement.defaultCaractere && !isPreviewInfoFromUpdate) {
      swal({
        title: 'Modification document',
        text: 'Cette opération annulera les modifications en cours.<br> Desirez-vous poursuivre ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: "#ff7b0c",
        cancelButtonColor: "rgb(154, 154, 153)",
      }).then((result) => {
        if (result) {
          this.itemDocumentAdd = Object.assign({}, item);;
        }
      })
    }
    else {
      this.itemDocumentAdd = Object.assign({}, item);
    }

    if (!item.natureSelectedSearchByNumDoc && item.modeRecherche == enumModeDeRecherche.refDocument) {
      //nous sommes en modification en ce moment verifier si nature document existe et le preselectionner
      if (item.natureDocument) {
        this.itemDocumentAdd.natureSelectedSearchByNumDoc = _.find(this.ListePiecesJointePossibleAAuthentifier, { 'id': item.natureDocument });
      }
    }

    if (!item.natureSelectedSearchByNomEtPrenom && item.modeRecherche == enumModeDeRecherche.NomPrenoms) {
      //nous sommes en modification en ce moment verifier si nature document existe et le preselectionner
      if (item.natureDocument) {
        this.itemDocumentAdd.natureSelectedSearchByNomEtPrenom = _.find(this.ListePiecesJointePossibleAAuthentifier, { 'id': item.natureDocument });
      }
    }

    this.itemDocumentAdd.piecesJustificatives = this.getListePieceJointeToSaveByDemande();

    //Recuperer le mode de la recherche
    this.itemToSave.modeRecherche = item.modeRecherche;

    try {
      this.ListeDocumentsJustificatif = item.piecesJustificativesForVueFront || ((item.piecesJustificatives && item.piecesJustificatives.length > item.piecesJustificativesForVueFront.length) ? item.piecesJustificatives : item.piecesJustificativesForVueFront); //item.piecesJustificatives;
    } catch (error) {
      this.ListeDocumentsJustificatif = [];
    }

  }

  openFormModalModal(template, itemToModified) {

    console.log(" la valeur de modification du modal ", itemToModified);
    if (itemToModified) {

      let detailsObj: any = {};

       //Formater la date de demande
       let dateFormatOperation = moment(this.itemToSave.dateDemande).format("DD/MM/YYYY");
       if (dateFormatOperation == "Invalid date") {
         dateFormatOperation = this.itemToSave.dateDemande;
       }


      //Constituer demandeurDto
      detailsObj.demandeursDto = {
        natureDemandesLibelle: this.itemToSave.natureDemandeSelected.libelle,
        objetDemandesLibelle: this.itemToSave.objetDemandeSelected.libelle,
        categorieDemandeur: this.itemToSave.categorieDemandeur,
        raisonSociale: this.itemToSave.raisonSociale,
        personneAContacter: this.itemToSave.personneAContacter,
        contactDemandeur: this.itemToSave.contactDemandeurPM ? this.itemToSave.contactDemandeurPM : (this.itemToSave.contactDemandeurPP ? this.itemToSave.contactDemandeurPP : null),
        emailDemandeur: this.itemToSave.emailDemandeurPM ? this.itemToSave.emailDemandeurPM : (this.itemToSave.emailDemandeurPP ? this.itemToSave.emailDemandeurPP : null),
        typeDemandeur: this.itemToSave.typeDemandeur,
        nomDemandeur: this.itemToSave.nomDemandeur ? this.itemToSave.nomDemandeur : null,
        prenomsDemandeur: this.itemToSave.prenomsDemandeur ? this.itemToSave.prenomsDemandeur : null,
        numeroPiece: this.itemToSave.numeroPiece ? this.itemToSave.numeroPiece : null,
        naturePiecesLibelle: this.itemToSave.natureDemandePPSelected ? this.itemToSave.natureDemandePPSelected.libelle : null,
        enregistreLe: itemToModified.enregistreLe,
        enregistrePar: itemToModified.enregistrePar,
        modifieLe: itemToModified.modifieLe,
        modifiePar: itemToModified.modifiePar,
        libelleAutreNatureDocument: this.itemToSave.libelleAutreNatureDocument,
        libelleAutreObjet : this.itemToSave.libelleAutreObjet ? this.itemToSave.libelleAutreObjet.toUpperCase() : null,
        dateDemande : dateFormatOperation,
        lieuDemande : this.itemToSave.lieuDemandeSelected.libelle.toUpperCase()
      }

      detailsObj.document = itemToModified;
      detailsObj.document.naturePiecesLibelle = itemToModified.natureSelectedSearchByNumDoc ? itemToModified.natureSelectedSearchByNumDoc.libelle : null
      detailsObj.piecesJustificatives = itemToModified.piecesJustificativesForVueFront;
      this.currentItemSelected = Object.assign({}, detailsObj);
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md modal-custom-detail' }));
  }

  openFormModalModalMiseAJourFichierJoint(template, ligneDocumentJoint) {

    if (ligneDocumentJoint) {
      this.currentLigneDocumentJoint = Object.assign({}, ligneDocumentJoint);
      this.currentLigneDocumentJoint.indexInListe = this.ListeDocumentsJustificatif.indexOf(ligneDocumentJoint);
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-sm' }));
  }

  confirmDeleteLigneDocument(indexRow) {

    swal({
      title: 'Suppression pièces justificatives',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.removeItemNatureDocument(indexRow);
      }
    })
  }

  cancelSave() {

    this.ListeDemandes = [];
    this.stepWizard = 1;
    this.itemToSave = {
      categorieDemandeur: this.categorieDemandeurEnum.PersonnePhysique,
      modeRecherche: enumModeDeRecherche.refDocument
    };
    this.idDemandeToUpdate = null;
    this.itemToSave.dateDemande = new Date();

  }

  confirmSaveItem() {


    swal({
      title: (this.idDemandeToUpdate > 0 ? 'Modifier une demande' : 'Enregistrer une demande'),
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        let objetToSave = {
          demandeursDto: {},
          documents: [],
          document: {},
          source: "",
          referenceSource: "",
          positionGps: ""
        };

        let demandeDto: any = {};

        //Constituer l'objet a envoyer
        if (!this.ListeDemandes || this.ListeDemandes.length == 0) {
          this.notificationService.showMessageError("Erreur", "Veuillez préciser la liste des documents à authentifier.");
          return;
        }

        demandeDto.natureDemande = this.itemToSave.natureDemandeSelected.id;
        demandeDto.objetDemande = this.itemToSave.objetDemandeSelected.id;
        demandeDto.categorieDemandeur = this.itemToSave.categorieDemandeur;

        if(this.itemToSave.objetDemandeSelected.libelle == enumLibelleCustom.LIBELLE_AUTRE)
        {
          demandeDto.libelleAutreObjet = this.itemToSave.libelleAutreObjet.toUpperCase(); 
        }

        let dateFormatOperation : String = ""
        //Formater la date de demande
        if(this.itemToSave.dateDemande && this.itemToSave.dateDemande.length == 10 )
        {
          let dateDemandeSplit = this.itemToSave.dateDemande.split("/");

          if (dateDemandeSplit && dateDemandeSplit.length == 3)
          {
            // En ce moment la date est deja formaté
            dateFormatOperation = this.itemToSave.dateDemande;
          }
         
        }
        else
        {
          dateFormatOperation = moment(this.itemToSave.dateDemande).format("DD/MM/YYYY");
          if (dateFormatOperation == "Invalid date") {
            dateFormatOperation = this.itemToSave.dateDemande;
          }
        }
        

        demandeDto.dateDemande = dateFormatOperation;
        demandeDto.lieuDemande = this.itemToSave.lieuDemandeSelected.libelle.toUpperCase();
        demandeDto.documentSortie = (this.itemToSave.typeDocumentSortieSelected && this.itemToSave.objetDemandeSelected.libelle == this.libelleEnumCustom.ProcedureJudiciaire) ? this.itemToSave.typeDocumentSortieSelected.toUpperCase() : null;

        if (this.itemToSave.categorieDemandeur == this.categorieDemandeurEnum.personneMorale) {
          demandeDto.raisonSociale = this.itemToSave.raisonSociale;
          demandeDto.personneAContacter = this.itemToSave.personneAContacter;
          demandeDto.contactDemandeur = this.itemToSave.contactDemandeurPM ? this.itemToSave.contactDemandeurPM : null;
          demandeDto.emailDemandeur = this.itemToSave.emailDemandeurPM ? this.itemToSave.emailDemandeurPM : null
        }
        else {
          demandeDto.typeDemandeur = this.itemToSave.typeDemandeur;
          demandeDto.naturePiece = (this.itemToSave.natureDemandePPSelected && (this.itemToSave.natureDemandePPSelected != enumLibelleCustom.LIBELLE_NATURE_PIECE_AUCUN)) ? this.itemToSave.natureDemandePPSelected.id : null;
          demandeDto.nomDemandeur = this.itemToSave.nomDemandeur ? this.itemToSave.nomDemandeur : null;
          demandeDto.prenomsDemandeur = this.itemToSave.prenomsDemandeur ? this.itemToSave.prenomsDemandeur : null;
          demandeDto.contactDemandeur = this.itemToSave.contactDemandeurPP ? this.itemToSave.contactDemandeurPP : null;
          demandeDto.emailDemandeur = this.itemToSave.emailDemandeurPP ? this.itemToSave.emailDemandeurPP : null;
          demandeDto.numeroPiece = this.itemToSave.numeroPiece ? this.itemToSave.numeroPiece : null;
          demandeDto.libelleAutreNatureDocument = this.itemToSave.libelleAutreNatureDocument || null;
        }


        //Formater le json pour optimiser la requete
        let listeDemandeToSave = [...this.ListeDemandes]; //Object.assign([], this.ListeDemandes);

        listeDemandeToSave.forEach(doc => {
          doc.moisNaissanceSelected = null,
            doc.natureDocJustifSelected = null;
          doc.jourNaissanceSelected = null;
          doc.natureSelectedSearchByNumDoc = null;

          if (doc && doc.piecesJustificatives) {
            doc.piecesJustificatives.forEach(pieceJustificative => {
              pieceJustificative.allFileBase64 = null,
                pieceJustificative.natureDocJustifSelected = null;
              pieceJustificative.fileName = null;
              pieceJustificative.nomFichier = null;
            })
          }

        });

        objetToSave.demandeursDto = demandeDto;
        objetToSave.documents = (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) ? [] : listeDemandeToSave;    //Vider le tableau si nous sommes en modification
        objetToSave.document = (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) ? listeDemandeToSave[0] : null;    //En cas de modification envoyer un objet
        objetToSave.source = "Web",
        objetToSave.referenceSource = navigator.userAgent,
        objetToSave.positionGps = this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : '0,0'

        this.saveItem(objetToSave);

      } else {
      }
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busyGet = this.api.post('demandes/' + (this.idDemandeToUpdate ? 'update' : 'create'), request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Demande", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            sessionStorage.removeItem('currentDemandeToUpdate');

            //Verifier si nous sommes en modification et faire le routage sur le dashbord
            if (this.idDemandeToUpdate && this.idDemandeToUpdate > 0) {
              this.router.navigate(['/dashbord']);
            }
            this.cancelSave();

          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Demande", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }

          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  getDaysNaissance() {
    for (var i = 1; i <= 31; i++) {
      let indice = "";
      if (i < 10) {
        indice = "0" + i;
      }
      else {
        indice = i.toString();
      }

      this.ListeDaysNaissance.push({
        day: indice
      })
    }
  }

  getMonthNaissance() {
    for (var i = 1; i <= 12; i++) {
      let indice = "";
      if (i < 10) {
        indice = "0" + i;
      }
      else {
        indice = i.toString();
      }

      this.ListeMonthNaissance.push({
        month: indice
      })
    }
  }

  getDataParametreBase() {
    let montantBaseParam = sessionStorage.getItem(enumKeyCrypto_AES.montantBaseParam);

    console.log("demande gratuite")
    if (this.userHasProfilWithDemandeGratuit && !this.idDemandeToUpdate) {
      // Dans ce cas nous sommes en create le montant est de 0 FCFA
      this.montantPaiementParametre = 0;
    }
    else {
      if (this.idDemandeToUpdate) {
        if (this.ListeDemandes && this.ListeDemandes[0]) {
          this.montantPaiementParametre = this.ListeDemandes[0].montantPaiement;
        }

      }
      else {
        this.montantPaiementParametre = this.utilities.decryptData(montantBaseParam) || 0;
      }

    }

    this.maxLengthContact = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.longueurContactParam)) || 0;

    this.customMaskContact = this.utilities.formatMaskContact(this.maxLengthContact);
  }

  getObjetDemande() {
    this.ListeObjetsDemande = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeObjetDemandes)); //JSON.parse(sessionStorage.getItem("ListeObjetDemandes")) || []; 
    this.getDataToUpdate();
  }

  getNatureDemande() {
    this.ListeNaturesDemandes = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeNaturesDemandes)); //JSON.parse(sessionStorage.getItem("ListeObjetDemandes")) || [];
    this.getDataToUpdate();
  }

  getDepartement() {
    this.ListeDepartements = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.listeDepartements)); 
    console.log(" this.ListeDepartements recuperer ",this.ListeDepartements);
  }

  getPieceJointe() {

    this.ListePiecesJointePossibleAAuthentifier = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeNaturesPiecesForAuthentification));
    console.log("this.ListePiecesJointePossibleAAuthentifier",this.ListePiecesJointePossibleAAuthentifier);
    this.getDataToUpdate();
  }

  getPiecesJustificativesByTypeDemandeur(isMandataire) {
    let ListePiecesJointFromLocalStore = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeNaturesPieces));

    if (!isMandataire) {

      //Retirer tous ceux qui ont isMandataire à true
      this.ListeNaturesPieceWithPhoto = _.filter(ListePiecesJointFromLocalStore, { 'isOnlyMandataire': false });
    }
    else {
      this.ListeNaturesPieceWithPhoto = ListePiecesJointFromLocalStore;
    }

    console.log("this.ListeNaturesPieceWithPhoto a joindre",this.ListeNaturesPieceWithPhoto);
  }

  verifyLengthContact() {
    if (this.itemToSave.contactDemandeurPP || this.itemToSave.contactDemandeurPM) {
      if (this.itemToSave.contactDemandeurPP && this.itemToSave.contactDemandeurPP.length > this.maxLengthContact) {
        this.itemToSave.contactDemandeurPP = this.itemToSave.contactDemandeurPP.substring(0, this.itemToSave.contactDemandeurPP.length - 1);
      }

      if (this.itemToSave.contactDemandeurPM && this.itemToSave.contactDemandeurPM.length > this.maxLengthContact) {
        this.itemToSave.contactDemandeurPM = this.itemToSave.contactDemandeurPM.substring(0, this.itemToSave.contactDemandeurPM.length - 1);
      }

    }
  }

  uploadFile(event: any) {

    let item = {
      fileName: null,
      fileBase64: null
    };
    let reader = new FileReader();

    let file = event.target.files[0];

    let tailleMax = this.user.tailleMaxPieceJointe * 1000000;
    if (file.size > tailleMax) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier la taille du fichier.");
      event.target.value = null;
      return;
    }

    item.fileName = file.name; //.split('.')[0];
    let Tabextension = file.name.split('.');
    let extension = Tabextension[Tabextension.length - 1];

    //verifier si l'extension est accepter
    const listeExtensionImagesOrPdf = this.userService.getExtensionsImageOrPdf();

    if (!_.find(listeExtensionImagesOrPdf, { 'extension': extension.toLowerCase() })) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier l'extension du fichier.");
      event.target.value = null;
      return;
    }

    reader.onload = (readerEvent) => {

      let data = (readerEvent.target as any).result;
      item.fileBase64 = data.split(',')[1];
      this.currentFileJoint = {
        base64PieceJointe: item.fileBase64,
        libelleDocument: this.itemDocumentAdd.natureDocJustifSelected ? this.itemDocumentAdd.natureDocJustifSelected.libelle : null,
        natureDocument: this.itemDocumentAdd.natureDocJustifSelected ? this.itemDocumentAdd.natureDocJustifSelected.id : null,
        extensionPieceJointe: extension,
        allFileBase64: data,
        nomFichier: item.fileName,
        libelleAutreNatureDocument: this.itemDocumentAdd.libelleAutreNatureDocument || null
      };

      if (this.currentLigneDocumentJoint && this.currentLigneDocumentJoint.natureDocument) {

        this.currentLigneDocumentJoint['extensionPieceJointe'] = extension;
        this.currentLigneDocumentJoint['base64PieceJointe'] = item.fileBase64;
        this.currentLigneDocumentJoint['allFileBase64'] = data;
        this.notificationService.showMessageSucces("Mise à jour fichier", "Fichier mise à jour avec succès !");

        if (this.currentLigneDocumentJoint.id) {
          this.currentLigneDocumentJoint.isUpdateLigneDocument = true;
        }
        this.currentFileJoint = {};
        this.currentLigneDocumentJoint = {};
        event.target.value = null;
        return;
      }

      this.fileJoint = event.target.value;

      if (!this.itemDocumentAdd.natureDocJustifSelected || !this.itemDocumentAdd.natureDocJustifSelected.id) {
        this.notificationService.showMessageError("Erreur", "Veuillez sélectionner une nature de document avant de joindre le document !");
        event.target.value = null;
        this.currentFileJoint = {}
        return;
      }
      this.addDocumentJustif();
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  addDocumentJustif() {

    if (this.currentFileJoint && this.currentFileJoint.base64PieceJointe && this.itemDocumentAdd.natureDocJustifSelected && this.itemDocumentAdd.natureDocJustifSelected.id) {

      //Verifier si un document de type photo est deja ajouté
      if (this.itemDocumentAdd.natureDocJustifSelected.libelle == enumLibelleCustom.PhotoIdentiteNaturePiece) {

        let photoIdentiteHasDocumentJoint = _.find(this.ListeDocumentsJustificatif, (o) => { return (o.naturePiecesLibelle == enumLibelleCustom.PhotoIdentiteNaturePiece || o.libelleDocument == enumLibelleCustom.PhotoIdentiteNaturePiece) });
        if (photoIdentiteHasDocumentJoint && photoIdentiteHasDocumentJoint['natureDocument']) {
          this.currentFileJoint = {};
          this.fileJoint = null;
          this.notificationService.showMessageError("Erreur", "Vous avez déjà joint une photo d'identité.");
          return;
        }

      }

      if (this.itemDocumentAdd.natureDocJustifSelected && this.itemDocumentAdd.natureDocJustifSelected.libelle == enumLibelleCustom.LIBELLE_AUTRE) {

        if (!this.itemDocumentAdd.libelleAutreNatureDocument) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner le libelle de pièce.");
          return;
        }

      }
      else {
        this.itemDocumentAdd.libelleAutreNatureDocument = null
      }

      this.currentFileJoint.libelleDocument = this.itemDocumentAdd.natureDocJustifSelected ? this.itemDocumentAdd.natureDocJustifSelected.libelle : null;
      this.currentFileJoint.natureDocument = this.itemDocumentAdd.natureDocJustifSelected ? this.itemDocumentAdd.natureDocJustifSelected.id : null;

      let document = Object.assign({}, this.currentFileJoint);

      if (!this.ListeDocumentsJustificatif) {
        this.ListeDocumentsJustificatif = [];
      }

      this.ListeDocumentsJustificatif.push(document);
      this.fileJoint = null;
      this.currentFileJoint = {};
      this.itemDocumentAdd.natureDocJustifSelected = {};
      this.itemDocumentAdd.libelleAutreNatureDocument = null;
      this.currentLigneDocumentJoint = {}
    }

  }

  removeItemNatureDocument(indice) {

    let ligneDocumentJointASupprimer = this.ListeDocumentsJustificatif[indice];

    if (ligneDocumentJointASupprimer && ligneDocumentJointASupprimer.id) //C'est une demande qui est déjà en base de donnée
    {
      ligneDocumentJointASupprimer.isDeleted = true;
      this.ListePieceJustificativesSupprime.push(ligneDocumentJointASupprimer)
    }

    this.ListeDocumentsJustificatif.splice(indice, 1);
  }

  getPositionGps() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {

        if (position && position.coords) {
          this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
        }

      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }

  updateNatureDocumentJoint(ligneDoc) {

    if (ligneDoc) {
      //Recuperer la nature de document qu'on desire modifier
      if (!ligneDoc.natureDocJustifUpdate) {
        this.notificationService.showMessageError("Erreur", "Veuillez sélectionner une nature !");
        return
      }

      let ligneDocFileJoint = this.ListeDocumentsJustificatif[ligneDoc.indexInListe]; //Recuperer l'ancienne nature

      //Verifier si l'ancien document  et le nouveau document ne sont pas des photos ( dans ce cas on laisse passer)
      //Si l'ancien document n'est pas une photo, et que la nouvelle est une photo alors verifier que le type photo n'est pas deja ajouté

      //ligneDocFileJoint = document deja joint qu'on modifie
      //ligneDoc = nouveau fichier avec la nature 

      //1 - Verifier si on a selectionné une photo
      if (ligneDoc.natureDocJustifUpdate.libelle == enumLibelleCustom.PhotoIdentiteNaturePiece) {
        // 2 - Verifier si l'ancien document n'etait pas une photo
        if (ligneDocFileJoint.naturePiecesLibelle != enumLibelleCustom.PhotoIdentiteNaturePiece && ligneDocFileJoint.libelleDocument != enumLibelleCustom.PhotoIdentiteNaturePiece) {
          //Dans ce cas verifier si une piece de type photo existe deja
          let photoIdentiteHasDocumentJoint = _.find(this.ListeDocumentsJustificatif, (o) => { return (o.naturePiecesLibelle == enumLibelleCustom.PhotoIdentiteNaturePiece || o.libelleDocument == enumLibelleCustom.PhotoIdentiteNaturePiece) });
          if (photoIdentiteHasDocumentJoint && photoIdentiteHasDocumentJoint['natureDocument']) {
            this.notificationService.showMessageError("Erreur", "Vous avez déjà joint une photo d'identité.");
            return;
          }

        }
      }

      if (ligneDoc.natureDocJustifUpdate && ligneDoc.natureDocJustifUpdate.libelle == enumLibelleCustom.LIBELLE_AUTRE) {

        if (!ligneDoc.libelleAutreNatureDocument) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner le libelle de pièce.");
          return;
        }

      }
      else {
        ligneDoc.libelleAutreNatureDocument = null;
      }


      ligneDocFileJoint.natureDocument = ligneDoc.natureDocJustifUpdate.id;

      if (ligneDoc.id) {
        ligneDocFileJoint.naturePiecesLibelle = ligneDoc.natureDocJustifUpdate.libelle;
        ligneDocFileJoint.isUpdateLigneDocument = true;
        ligneDocFileJoint.libelleAutreNatureDocument = ligneDoc.libelleAutreNatureDocument || null
      }
      else {
        ligneDocFileJoint.natureSelectedSearchByNumDoc = {
          libelle: ligneDoc.natureDocJustifUpdate.libelle
        };
        ligneDocFileJoint.libelleDocument = ligneDocFileJoint.natureSelectedSearchByNumDoc.libelle;
        ligneDocFileJoint.libelleAutreNatureDocument = ligneDoc.libelleAutreNatureDocument || null
      }
      this.currentLigneDocumentJoint = {};
      this.itemDocumentAdd.natureDocJustifSelected = null;
      this.modalRef.hide();
    }
  }

  changeNaturePiecePP(naturePieceSelected) {
    if (naturePieceSelected) {
      if (naturePieceSelected == enumLibelleCustom.LIBELLE_NATURE_PIECE_AUCUN) {
        this.itemToSave.numeroPiece = null;
        this.itemToSave.libelleAutreNatureDocument = null;
      }

      if (naturePieceSelected.libelle && naturePieceSelected.libelle != enumLibelleCustom.LIBELLE_AUTRE) {
        this.itemToSave.libelleAutreNatureDocument = null;
      }

    }
  }

  changeNatureDocumentJoint(natureDocJustif) {
    if (natureDocJustif) {
      if (natureDocJustif && natureDocJustif.libelle != enumLibelleCustom.LIBELLE_AUTRE) {
        this.itemDocumentAdd.libelleAutreNatureDocument = null;
      }
    }
  }

  gotoUpdloadPieceJointe(ligneDocumentJoint) {

    //Dans le cas ou nous sommes en ajout
    if (!ligneDocumentJoint) {
      if (!this.itemDocumentAdd.natureDocJustifSelected) {
        this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la nature de document avant de selectionner le fichier.");
        return;
      }
    }

    this.currentLigneDocumentJoint = ligneDocumentJoint ? ligneDocumentJoint : null;
    //Ouvrir la boite de dialogue
    let inputFile = document.getElementById("file-document");
    if (inputFile) {
      inputFile.click();
    }
  }


  changeDemandeurInformation(typeDemandeur) {
    this.itemToSave.natureDemandePPSelected = "";
    if (typeDemandeur) {
      if (typeDemandeur == enumTypeDemandeur.Mandataire) {
        this.ListeNaturesPieceMandataireOrTitulaire = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeNaturesPiecesMandataire));
      }
      else {
        this.ListeNaturesPieceMandataireOrTitulaire = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.ListeNaturesPiecesTitulaire));
      }

      //On retire la photo des pièces jointes
      let currentItemPhoto = _.find(this.ListeNaturesPieceMandataireOrTitulaire, { 'libelle': enumLibelleCustom.PhotoIdentiteNaturePiece });

      if (currentItemPhoto) {
        let indexPhoto = this.ListeNaturesPieceMandataireOrTitulaire.indexOf(currentItemPhoto);
        this.ListeNaturesPieceMandataireOrTitulaire.splice(indexPhoto, 1);
      }

      if (this.idDemandeToUpdate) {
        this.getDataToUpdate();
      }

    }
  }

  ngOnInit() {
    //this.getData();


    this.getObjetDemande();
    this.getPieceJointe();
    this.getNatureDemande();
    this.getDataParametreBase();
    this.getDepartement();
    
    //this.getPositionGps();

    window.scrollTo(0, 0);
  }


}
