import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { enumLibelleCustom } from 'app/shared/utils/enumerator';
import { DaterangePickerComponent, DaterangepickerConfig } from 'ng2-daterangepicker';
const swal = require('sweetalert2');
import * as moment from 'moment';

@Component({
  selector: 'app-main-approvisionnement-commissariat',
  templateUrl: './main-approvisionnement-commissariat.component.html',
  styleUrls: ['./main-approvisionnement-commissariat.component.css']
})
export class MainApprovisionnementCommissariatComponent implements OnInit {

  defaultLocalite : any;
  motifEnum : any = {};
  currentLocalite : any = {};
  results: Array<any> = [];
  daterange : any = {};
  ListeCommissariats :  Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  defaultEndPoint = 'approvisionnements'
  totalFeuilletA5Restant : any;
  montantTotalFeuilletA5 : any;

  config = {
    animated: false,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  
  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService,private daterangepickerOptions: DaterangepickerConfig) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15;
    this.currentItemChecked = {};

    this.itemToSearch = {
      dateDebut: moment(new Date(2022, 3, 1)).format("DD/MM/YYYY"),
      dateFin: moment(new Date()).format("DD/MM/YYYY")
    };

    this.reinitDateSearch();
    this.motifEnum = enumLibelleCustom;
  }

  reinitDateSearch(){

    console.log(" init demande ");

    let tabDateDuJour = this.user.dateDuJour.split(" ")[0].split("/");
    const newDateDuJour = new Date(tabDateDuJour[2],tabDateDuJour[1]-1,tabDateDuJour[0]);

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {
        "Aujourd'hui": moment(newDateDuJour),
        "La journée d'hier": [moment(newDateDuJour).subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment(newDateDuJour).subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment(newDateDuJour).subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment(newDateDuJour).subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment(newDateDuJour).subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment(newDateDuJour).subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment(newDateDuJour).subtract(89, 'day'), moment()],
        'Toutes les demandes': [new Date(2022, 3, 1), moment()]
      }
    };
  }
  
  checkPermission(code1,code2,code3)
  {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;

  }


  selectedDate(value: any, datepicker?: any) {

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.currentPage = 1;
    // or manupulat your own internal property
    this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');

    this.daterange.label = value.label;
    this.oldValueSearch = null;
    this.getData(true);
  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);

      if(this.itemToSave.commissariat) {
        this.itemToSave.commissariatSelected = _.find(this.ListeCommissariats, { 'id': itemToModified.commissariat });
      }
    }
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }



  confirmActiveOrDesactiveItem(obj, isDesactiveActive) {

    swal({
      title: 'Commissariat',
      text: !isDesactiveActive ? 'Voulez-vous activer ce commissariat ?' : 'Voulez-vous suspendre ce commissariat ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f29302",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.desactiveOrActiveitem(obj);
      } 
    })

  }

  desactiveOrActiveitem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/activerDesactiver', request)
      .subscribe(
        res => {
          // console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Commissariat", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.oldValueSearch = null;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
        }
      );
  }


  getCommissariats() {

    var request = {
      user: this.user.proxy,
      data: {
      }
    }

    this.busyGet = this.api.post('commissariats/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeCommissariats = _.orderBy(res['items'], ['libelle'], ['asc']);
          } else {
            this.ListeCommissariats = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );
  }

  getData(isRefresh ?) {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if(!isRefresh) {
      if (search_value && this.oldValueSearch) {
        if (search_value != this.oldValueSearch) {
          this.currentPage = 1;
        }
      }
  
      if (search_value) {
        if (search_value.length > 0 && search_value.length < 3) {
          if (!this.isChangeItermPerPage) {
            return;
          }
        }
        else {
          if (search_value == this.oldValueSearch) {
            return;
          }
          this.oldValueSearch = search_value;
          //this.currentPage = 1;
        }
      }
      else {
        if (this.oldValueSearch == "" && isSearch) {
          return;
        }
  
        this.oldValueSearch = "";
      }
  
    }
   
    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: this.itemToSearch.fullText || null,
        createdAt : null,
        createdAtParam : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.totalFeuilletA5Restant = res['totalFeuilletA5Restant'] ? res['totalFeuilletA5Restant'] : 0;
            this.montantTotalFeuilletA5 = res['montantTotalFeuilletA5'] ? res['montantTotalFeuilletA5'] : 0;
            this.isChangeItermPerPage = false;

          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );

  }


  confirmDeleteItem(obj) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        // obj.id = 1020;
        this.deleteItem(obj);
      }
    })

  }

  cancelSave() {
    this.itemToSave = {
      // commissariatSelected : {
      //   localiteDto : {}
      // }
    };
    this.defaultLocalite = null;
    this.currentLocalite = {};
  }


  confirmSave(item) {

    if (!item || !item.commissariatSelected ) {
      this.notificationService.showMessageError("Information", "Veuillez sélectionner une "+(this.user.commissariatActif ? "agence / commissariat" :"agence"))
      return;
    }

    if (!item.quantite ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les quantités.")
      return;
    }

    if (item.id && !item.motif ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le motif.")
      return;
    }

    swal({
      title: !item.id ? 'Enregistrer un approvisionnement' : 'Modifier un approvisionnement',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        item.commissariat = item.commissariatSelected.id;

        this.saveItem({
          id : item.id,
          commissariat : item.commissariatSelected.id,
          quantite : item.quantite,
          motif : item.id ? item.motif : null
        });

      } 
    })
  }

  localiteSlected($event) {
    this.currentLocalite = {...$event};
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post(this.defaultEndPoint+'/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Approvisionnement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData(true); 
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression",  this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData(true);
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  reinitSeachPeriode() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  ngAfterViewInit() {
    this.reinitSeachPeriode();
  }

  ngOnInit() {
    this.getData();
    this.getCommissariats();
    window.scrollTo(0, 0);
  }


}
