import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';

// Core providers
import { CoreModule} from "./core/core.module";
import { SmartadminLayoutModule} from "./shared/layout/layout.module";
import { SmartadminModule} from "./shared/smartadmin.module";
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { LoginComponent} from "./+auth/+login/login.component";
import { NgDatepickerModule } from 'ng2-datepicker';
import { DaterangepickerModule } from 'angular-2-daterangepicker';
import { LoggedInGuardService} from "./shared/utils/guards/logged-in-guard.service";
import { CaptchaModule } from 'ng-captcha';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpRequestResponseInterceptor } from './shared/utils/httpRequestResponseInterceptor';
// import { FormPaiementCinetpayComponent } from './+components/component-app/form-paiement-cinetpay/form-paiement-cinetpay.component';
// import { MainApproStockComponent } from './+components/component-app/main-appro-stock/main-appro-stock.component';
// import { DetailsDocumentsJointComponent } from './+components/component-app/details-documents-joint/details-documents-joint.component';
// import { StockCommissariatComponent } from './+components/component-app/stock-commissariat/stock-commissariat.component';
// import { MainApprovisionnementCommissariatComponent } from './+components/component-app/main-approvisionnement-commissariat/main-approvisionnement-commissariat.component';
// import { MainParametreLieuResidenceComponent } from './+components/component-app/main-parametre-lieu-residence/main-parametre-lieu-residence.component';
// import { MainHistoriqueConnexionComponent } from './+components/component-app/main-historique-connexion/main-historique-connexion.component';
// import { PreviewPdfComponent } from './+components/component-app/preview-pdf/preview-pdf.component';
// import { SearchInputWithLibelleComponent } from './+components/component-app/search-input-with-libelle/search-input-with-libelle.component';
// import { FormCreateCertificatComponent } from './+components/component-app/form-create-certificat/form-create-certificat.component';
// import { MainParametrageComissariatComponent } from './+components/component-app/main-parametrage-comissariat/main-parametrage-comissariat.component';
// import { SearchAgenceComponent } from './+components/component-app/search-agence/search-agence.component';

 
// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
  LoggedInGuardService
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    LoginComponent,
    // FormPaiementCinetpayComponent,
    // MainApproStockComponent,
    // StockCommissariatComponent,
    // MainApprovisionnementCommissariatComponent,
    // MainHistoriqueConnexionComponent,
    // PreviewPdfComponent,
    // DetailsDocumentsJointComponent,
    // SearchInputWithLibelleComponent,
   
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SmartadminModule,
    CoreModule,
    SmartadminLayoutModule,
    DaterangepickerModule,
    NgDatepickerModule,
    CaptchaModule,
    MatCheckboxModule,
    routing
  ],
  exports: [
    //FormReinitPasswordComponent,
    LoginComponent
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    // UserService,
    APP_PROVIDERS,
    {provide: HTTP_INTERCEPTORS, useClass: httpRequestResponseInterceptor, multi: true}
  ],
 // bootstrap: [FormReinitPasswordComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}


}

