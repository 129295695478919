import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserService } from 'app/shared/user';
import { Api } from 'app/shared/utils/api/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.css']
})
export class PreviewPdfComponent implements OnInit {

  
  @Input() fileUrlFinale: any = {};
  @Input() height: any = {};
  @Input() width: any = {};
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();

  itemToSave: any;
  endPointReport: any;
  currentLink: any;
  isGenererQuitus: boolean = false;
  canGenererQuitus: boolean = false;
  user: any = {}
  busySave: Subscription;
  @Input() fileUrl: any;

  constructor(public sanitizer: DomSanitizer, private api: Api, private userService: UserService) {
    this.user = this.userService.getCurrentUser();
    this.itemToSave = {
      isReady: false,
     };

    setTimeout(() => {
      let link = this.currentLink;

      this.cleanURL(link);
    }, 1000);

  }

  cleanURL(oldURL) {
    console.log(oldURL);

    if (!this.itemToSave.isReady && oldURL) {
      this.itemToSave.isReady = true;
      oldURL = oldURL+"#toolbar=0&navpanes=0";
      this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
      console.log(" final url ",this.itemToSave.fileUrlFinale);
    }

  }

  clickRow(){
    this.selectedItem.emit(true);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('data', changes);
    if (changes['fileUrlFinale'] && changes['fileUrlFinale'].currentValue) {
      //   this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustUrl(changes['fileUrl'].currentValue);
      setTimeout(() => {
        this.cleanURL(changes['fileUrlFinale'].currentValue);
      }, 1000);

    }
  }

}
