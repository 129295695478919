import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
type AOA = any[][];
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { DaterangePickerComponent, DaterangepickerConfig } from 'ng2-daterangepicker';
import { enumCategorieDemandeur, enumParametreBase, enumStatutDemande, enumKeyCrypto_AES, enumModeDeRecherche, enumTypeDemande, enumLibelleCustom } from '../../../shared/utils/enumerator';
import { Router } from '@angular/router';

// import { FormPriseDecisionComponent } from '../form-prise-decision/form-prise-decision.component';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
import { Observable } from 'rxjs';
// import { FormPaiementCinetpayComponent } from '../form-paiement-cinetpay/form-paiement-cinetpay.component';
// import { FormPriseDecisionContentieuxBieComponent } from '../form-prise-decision-contentieux-bie/form-prise-decision-contentieux-bie.component';

@Component({
  selector: 'app-main-dashbord',
  templateUrl: './main-dashbord.component.html',
  styleUrls: ['./main-dashbord.component.css']
})
export class MainDashbordComponent implements OnInit {

  results: Array<any> = [];
  isViewTable: boolean = true;
  ListeRoles: Array<any> = [];
  ListeDemandes: Array<any> = [];
  itemDetails: any = {};
  ListeCommissariats: Array<any> = [];
  setIntervalNotifyAfterPaiement: any;
  inputSearchParameter: any = {};
  bsModalRefCinetPay: BsModalRef;
  isCritiqueTempsEnrolement: boolean = false;
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  itemToSaveHisoriqueImpression: any = {};
  itemToSaveDetails: any = {};
  isOpenPopOver: boolean = false;
  totalItemsHistorique = 0;
  currentPageHistorique = 1;
  ListeHistoriqueDemandes: Array<any> = [];
  ListeObjetsDemande: Array<any> = [];
  ListeNaturesDemandes: Array<any> = [];
  ListeNaturesDemandesForAuthentification: Array<any> = [];
  ListeHistoriqueImpression: Array<any> = [];
  currentPageHistoriqueImpression: number = 1;
  requestRefresh: Subscription;
  selectedFileApk: any;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any = {};
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  loading: boolean;
  categorieDemandeurEnum: any = {};
  itemDecision: any = {};
  currentPositionGps: any = {};
  statutEnumDemande: any = {};
  itemsPerPageHistorique: number = 7;
  daterange: any = {};
  itemStatistique: any = {};
  modeDeRechercheEnum: any = {};
  typeDemandeEnum: any = {};
  libelleCustomEnum: any = {};

  isPrendreDecisionModalDetail: boolean = false;
  priseDecisionModalHasSuccess: boolean = false;
  bsModalRefPrendreDecision: BsModalRef;
  bsModalRefPrendreDecisionBieContentieux: BsModalRef;
  linkPdfFile: String;
  bsModalRefPreview: BsModalRef;
  modalRefHistoImpression: BsModalRef;
  totalItemsHistoriqueImpression: number;
  currentDocumentSelected: any = {};
  enumMotifDemandeLibelle: any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig, public router: Router) {

    this.cancelSave();
    this.user = this.userService.getCurrentUser();

    console.log(" this.user ",this.user);
    
    this.itemsPerPage = 10;
    this.currentItemChecked = {};
    this.categorieDemandeurEnum = enumCategorieDemandeur;
    this.statutEnumDemande = enumStatutDemande;
    this.modeDeRechercheEnum = enumModeDeRecherche;
    this.typeDemandeEnum = enumTypeDemande;
    this.libelleCustomEnum = enumLibelleCustom;



    // console.log(" this.user ",this.user); 

    this.itemStatistique = {
      pourcentageDemandeRejete: 0,
      pourcentageDemandeAutentifiee: 0,
      pourcentageDemandeValidee: 0,
      pourcentageDemandeCloture: 0,
      pourcentageDemandeReintroduite: 0,
      pourcentageDemandeEnregistree: 0
    }

    this.itemToSearch = {
      categorieDemandeSearch: this.user.isAgentBie ? "1" : null,
      statut: '',
      objetSearch: '',
      dateDebut: moment(new Date(2022, 9, 31)).format("DD/MM/YYYY"),
      dateFin: moment(new Date()).format("DD/MM/YYYY")
    };

    this.reinitDateSearch();

  }

  getCommissariats() {
    var request = {
      user: this.user.proxy,
      data: {}
    }

    this.api.post('commissariats/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeCommissariats = res['items'];
          } else {
            this.ListeCommissariats = [];
          }
        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  getMonth(indiceMonth) {
    let libelleMois = '';

    if (indiceMonth == 1) libelleMois = 'Janvier';
    if (indiceMonth == 2) libelleMois = 'Février';
    if (indiceMonth == 3) libelleMois = 'Mars';
    if (indiceMonth == 4) libelleMois = 'Avril';
    if (indiceMonth == 5) libelleMois = 'Mai';
    if (indiceMonth == 6) libelleMois = 'Juin';
    if (indiceMonth == 7) libelleMois = 'Juillet';
    if (indiceMonth == 8) libelleMois = 'Aôut';
    if (indiceMonth == 9) libelleMois = 'Septembre';
    if (indiceMonth == 10) libelleMois = 'Octobre';
    if (indiceMonth == 11) libelleMois = 'Novembre';
    if (indiceMonth == 12) libelleMois = 'Decembre';

    return libelleMois;
  }

  reinitDateSearch() {

    console.log(" init demande ");

    let tabDateDuJour = this.user.dateDuJour.split(" ")[0].split("/");
    const newDateDuJour = new Date(tabDateDuJour[2], tabDateDuJour[1] - 1, tabDateDuJour[0]);

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {
        "Aujourd'hui": moment(newDateDuJour),
        "La journée d'hier": [moment(newDateDuJour).subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment(newDateDuJour).subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment(newDateDuJour).subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment(newDateDuJour).subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment(newDateDuJour).subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment(newDateDuJour).subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment(newDateDuJour).subtract(89, 'day'), moment()],
        'Toutes les demandes': [new Date(2022, 9, 31), moment()]
      }
    };
  }

  checkPermission(code1, code2, code3) {
    let returnValue = null;
    let ListeFonctionnalites = this.user.fonctionnalites;

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2 || element.code == code3));

    if (itemFonctionnalite) {
      returnValue = true;
    }

    return returnValue;
  }

  hideModal() {
    this.modalRef.hide();
  }

  // openModalPrendreDecisionComponent(demande) {

  //   this.isPrendreDecisionModalDetail = true;
  //   this.priseDecisionModalHasSuccess = false;
  //   demande.avisSelected = null;
  //   demande.motif = null;

  //   this.bsModalRefPrendreDecision = this.modalService.show(FormPriseDecisionComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-prendre-une-decision' }));
  //   this.bsModalRefPrendreDecision.content.currentDemande = demande;
  //   this.bsModalRefPrendreDecision.content.isSaveSucces = false;

  //   let callBackModal = this.modalService.onHide.subscribe(r => {
  //     if (this.bsModalRefPrendreDecision.content.isSaveSucces && this.isPrendreDecisionModalDetail) {
  //       this.itemToSave.statut = this.bsModalRefPrendreDecision.content.newStatut;
  //       this.isPrendreDecisionModalDetail = false;
  //       this.priseDecisionModalHasSuccess = true;
  //       this.getData();
  //     }
  //   });

  // }



  // openModalPrendreDecisionBieContentieuxComponent(demande) {
  //   console.log("prise de decision BIE",demande)
  //   this.isPrendreDecisionModalDetail = true;
  //   this.priseDecisionModalHasSuccess = false;
  //   demande.avisSelected = null;
  //   demande.motif = null;

  //   this.bsModalRefPrendreDecisionBieContentieux = this.modalService.show(FormPriseDecisionContentieuxBieComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-prendre-une-decision-bie' }));
  //   this.bsModalRefPrendreDecisionBieContentieux.content.currentDemande = demande;
  //   this.bsModalRefPrendreDecisionBieContentieux.content.isSaveSucces = false;

  //   let callBackModal = this.modalService.onHide.subscribe(r => {
  //     //this.getData();

  //      if (this.bsModalRefPrendreDecisionBieContentieux.content.isSaveSucces) {
  //       this.getData();
  //     }

  //     // if (this.bsModalRefPrendreDecision.content.isSaveSucces && this.isPrendreDecisionModalDetail) {
  //     //   this.itemToSave.statut = this.bsModalRefPrendreDecision.content.newStatut;
  //     //   this.isPrendreDecisionModalDetail = false;
  //     //   this.priseDecisionModalHasSuccess = true;
  //     //   this.getData();
  //     // }
  //   });

  // }



  openFormModalModal(template, itemToModified, isDocumentJoint) {
    this.cancelSave();
    if (itemToModified) {

      this.itemToSave = { ...itemToModified };
      this.itemDetails = { ...itemToModified };
    }

    if (isDocumentJoint) {

      this.itemDetails.piecesJustificatives.forEach(doc => {

        let splitUrlDoc = doc.url.split(".");

        if (splitUrlDoc.length > 0 && splitUrlDoc[splitUrlDoc.length - 1].toLowerCase() == 'pdf') {
          doc.isPdf = true;

        } else {
          doc.isPdf = false;
        }

      });

      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md modal-width-65' }));
    } else {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
    }
    // this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
  }


  // openFormModalHistoriqueImpression(template, itemToModified) {

  //   this.currentPageHistoriqueImpression = 1;
  //   this.ListeHistoriqueImpression = [];

  //   if (itemToModified) {

  //     this.itemToSaveHisoriqueImpression = Object.assign({}, itemToModified);
  //     this.totalItemsHistoriqueImpression = 0;
  //     this.getDataHistoriqueImpression();

  //   }

  //   this.modalRefHistoImpression = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md  modal-custom-prendre-une-decision modal-custom-detail' }));

  // }


  // openFormModalModalDecision(template, itemToModified, isHistorique) {
  //   this.cancelSave();
  //   if (itemToModified) {
  //     this.itemDecision = Object.assign({}, itemToModified);
  //     this.itemDecision.numero = this.itemDecision.numeroPaiement;
  //   }
  //   if (isHistorique) {
  //     this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md modal-custom-detail' }));
  //     this.ListeHistoriqueDemandes = [];
  //     this.getDataHistoriqueDecision();
  //   }
  //   else {
  //     this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
  //   }
  // }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  pageChangedHistorique(event: any): void {
    this.currentPageHistorique = event.page;
    this.getDataHistoriqueDecision();
  }

  pageChangedHistoriqueImpression(event: any): void {
    this.currentPageHistoriqueImpression = event.page;
    this.getDataHistoriqueImpression();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getDataHistoriqueImpression() {

    var request = {
      user: this.user.proxy,
      data: {
        documentAuthentifier: this.itemToSaveHisoriqueImpression.id
      },
      index: (this.currentPageHistoriqueImpression - 1),
      size: this.itemsPerPageHistorique ? this.itemsPerPageHistorique : null
    }

    this.busySave = this.api.post('historiqueImpression/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.ListeHistoriqueImpression = res['items'];
            this.totalItemsHistoriqueImpression = res['count'] ? res['count'] : 0;
          } else {
            this.ListeHistoriqueImpression = [];
          }
        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }


  getDataHistoriqueDecision() {

    var request = {
      user: this.user.proxy,
      data: {
        demande: this.itemDecision.id
      },
      index: (this.currentPageHistorique - 1),
      size: this.itemsPerPageHistorique ? this.itemsPerPageHistorique : null
    }

    this.busySave = this.api.post('workflows/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.ListeHistoriqueDemandes = res['items'];
            this.totalItemsHistorique = res['count'] ? res['count'] : 0;
          } else {
            this.ListeHistoriqueDemandes = [];
          }
        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  confirmDeleteItem(obj) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj);
      }
    })

  }

  confirmNotifOrDelivreDocument(document, isDelivreDemande?) {

    swal({
      title: isDelivreDemande ? 'Délivrer la demande' : 'Notification demandeur',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        const request = {
          user: this.user.proxy,
          data: {
            source: "Web",
            referenceSource: navigator.userAgent,
            positionGps: this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : "0,0",
            "document": { "id": document.id }
          }
        }

        this.busyGet = this.api.post('demandes/' + (isDelivreDemande ? 'delivrerDemande' : 'notifierDemandeur'), request)
          .subscribe(
            res => {
              if (!res['hasError']) {
                this.notificationService.showMessageSucces(isDelivreDemande ? "Délivrer la demande" : "Notification demandeur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

                this.cancelSave();
                this.getData();
              } else {
                if (res['status'] && res['status']['message']) {
                  this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                }
              }
            },
            err => {
              this.notificationService.defaultMessage()
            }
          );

      }
    })

  }

  cancelSave() {
    this.itemToSave = {
      filtreSelected: 'Tout'
    };
  }


  confirmMiseAJourDemande(item) {

    // if (!this.currentPositionGps || !this.currentPositionGps.positionGps) {
    //   this.notificationService.showMessageError("Erreur", "Veuillez autoriser l'application à accéder à votre position  !");
    //   return;
    // }

    if (!item || !item.avisSelected) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.")
      return;
    }

    if (item.avisSelected == 'Rejeté') {
      if (!item.motif) {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires.")
        return;
      }
    }

    let objToSave = {
      source: "Web",
      referenceSource: navigator.userAgent,
      positionGps: this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : "0,0",
      document: {
        id: item.id,
        statut: item.avisSelected,
        motif: item.motif
      }
    }

    swal({
      title: 'Prise de décision',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(objToSave);
      } else {
      }
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('demandes/prendreUneDecision', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Prise de décision", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            //this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  gotoUpdateCertificat(certificat, isRelancerPaiement?: boolean) {

    certificat.relancerPaiement = isRelancerPaiement;
    sessionStorage.setItem(enumKeyCrypto_AES.currentCertificatToUpdate, this.utilities.encryptData(certificat));
    this.router.navigate(['/update-certificat/wQazwPosox1233jjndb']); // Un mock pr tromper
  }

  gotoDemandeProfilDG(demande, isAuthentification) {

    sessionStorage.setItem(enumKeyCrypto_AES.idDocumentEnCoursAuthentification, this.utilities.encryptData(demande.document.id));

    if (!isAuthentification) {
      sessionStorage.setItem(enumKeyCrypto_AES.currentDemandeToSearchAuthentificationDG, this.utilities.encryptData(demande));
      this.router.navigate(['/recherche-demande']);
    }
    else {
      sessionStorage.setItem(enumKeyCrypto_AES.currentCitoyenAAuthentifier, this.utilities.encryptData(demande));
      this.router.navigate(['/authentification-demande', true]);
    }

  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post('parametres/delete', request)
      .subscribe(
        res => {

          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index, isMainPage) {
    let num = index + 1;
    if (isMainPage) {
      if (page > 1) {
        num = ((page - 1) * this.itemsPerPage) + (index + 1);
      }
    }
    else {
      if (page > 1) {
        num = ((page - 1) * this.itemsPerPageHistorique) + (index + 1);
      }
    }

    return num;
  }

  refreshData() {
    this.requestRefresh = Observable.interval(5 * 60 * 1000)
      .subscribe(i => {
        this.getData()
      })
  }

  getData(isRefresh?) {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if (!isRefresh) {
      if (search_value && this.oldValueSearch) {
        if (search_value != this.oldValueSearch) {
          this.currentPage = 1;
        }
      }

      if (search_value) {
        if (search_value.length > 0 && search_value.length < 3) {
          if (!this.isChangeItermPerPage) {
            return;
          }
        }
        else {

          if (search_value == this.oldValueSearch) {
            return;
          }

          this.oldValueSearch = search_value;
        }
      }
      else {
        if (this.oldValueSearch == "" && isSearch) {
          return;
        }
        this.oldValueSearch = "";
      }

    }

    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: this.itemToSearch.fullText || null,
        createdAt: null,
        isPrinted : this.itemToSearch.isPrinted || null,
        isGrandPublic : this.itemToSearch.isGrandPublic || null,
        createdAtParam: null,
        commissariat: this.itemToSearch.commissariatSelected ? this.itemToSearch.commissariatSelected.id : null,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.createdAtParam =
      {
        "operator": "[]",
        "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
        "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
      }
    }

    this.reinitDateSearch();

    this.inputSearchParameter = request;
    this.busyGet = this.api.post('certificatResidences/getByCriteria', request).subscribe(
      res => {
        console.log(" res ", res);

        this.itemStatistique = {
          montantTotalCertificat: res['montantTotalCertificat'] ? res['montantTotalCertificat'] : 0,
          nombreTotalCertificat: res['nombreTotalCertificatDelivre'] ? res['nombreTotalCertificatDelivre'] : 0,
          montantTotalFeuilletA5: res['montantTotalFeuilletA5'] ? res['montantTotalFeuilletA5'] : 0,
          nombreTotalCommissariat: res['nombreTotalCommissariat'] ? res['nombreTotalCommissariat'] : 0,
          totalFeuilletA5Restant: res['totalFeuilletA5Restant'] ? res['totalFeuilletA5Restant'] : 0
        };

        // Afficher le nombre de feuillet
        if (!isRefresh && this.user.commissariatDto && parseInt(this.itemStatistique.totalFeuilletA5Restant) < this.user.seuilAlerteStock) {
          if (!this.itemStatistique.totalFeuilletA5Restant || this.itemStatistique.totalFeuilletA5Restant == 0) {
            this.notificationService.showMessageError("Feuillets A5 restant", "Vous ne disposez plus de feuillet A5 pour imprimer les certificats de résidence");
          } else {
            if (this.itemStatistique.totalFeuilletA5Restant == 1) {
              this.notificationService.showMessageAlertFeuilletRestant("Feuillet A5 restant", "Vous ne disposez plus que d'un seul feuillet A5 pour imprimer les certificats de résidence");
            } else {
              this.notificationService.showMessageAlertFeuilletRestant("Feuillet A5 restant", "Il vous reste " + (this.itemStatistique.totalFeuilletA5Restant + " feuillet" + (this.itemStatistique.totalFeuilletA5Restant > 0 ? "s" : "") + " A5."));
            }
          }
        }

        if (res && res['items']) {
          this.results = res['items'];
          this.totalItems = res['count'] ? res['count'] : 0;
          this.isChangeItermPerPage = false;
        } else {
          this.results = [];
          if (res['hasError'] && res['status'] && res['status']['message']) {
            this.utilities.checkDeconnectionUser(res);
            this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        }
      },
      err => {
        this.notificationService.defaultMessage()
        this.reinitStatistiueValue();
      }
    );
  }

  // getDataRefresh() {

  //   let userLogin = this.userService.getCurrentUser() || null;

  //   if (!userLogin || !userLogin.token) return;

  //   let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
  //   let isSearch = this.itemToSearch.fullText ? true : false;

  //   if (search_value && this.oldValueSearch) {
  //     if (search_value != this.oldValueSearch) {
  //       this.currentPage = 1;
  //     }
  //   }

  //   if (search_value) {
  //     if (search_value.length > 0 && search_value.length < 3) {
  //       if (!this.isChangeItermPerPage) {
  //         return;
  //       }
  //     }
  //     else {

  //       if (search_value == this.oldValueSearch) {
  //         return;
  //       }

  //       this.oldValueSearch = search_value;
  //     }
  //   }
  //   else {
  //     if (this.oldValueSearch == "" && isSearch) {
  //       return;
  //     }

  //     this.oldValueSearch = "";
  //   }

  //   var request = {
  //     user: this.user.proxy,
  //     isRefreshAuto: true,
  //     data: {

  //       searchFullText: this.itemToSearch.fullText || null,
  //       document: {
  //         statut: this.itemToSearch.statut ? this.itemToSearch.statut : null,
  //         demandeursCategorieDemandeur: this.itemToSearch.categorie ? this.itemToSearch.categorie : null,
  //         createdAt: null,
  //         createdAtParam: null,
  //         natureDemande: this.itemToSearch.natureDemandeSearch ? this.itemToSearch.natureDemandeSearch.id : null,
  //         natureDocument: this.itemToSearch.typeDocumentSearch ? this.itemToSearch.typeDocumentSearch.id : null,
  //         objetDemande: (this.itemToSearch.objetSearch && !this.user.isAgentBie) ? this.itemToSearch.objetSearch.id : null,
  //         modeRecherche: this.itemToSearch.modeRechercheSearch ? this.itemToSearch.modeRechercheSearch : null,
  //         isGratuit: this.itemToSearch.categorieDemandeSearch ? ((this.itemToSearch.categorieDemandeSearch == "0") ? false : true) : null,
  //         isDirectionGenerale: (this.itemToSearch.sourcesSearch && this.itemToSearch.sourcesSearch != "BIE") ? ((this.itemToSearch.sourcesSearch == "0") ? false : true) : null,
  //         isDemandeBie: this.itemToSearch.sourcesSearch ? ((this.itemToSearch.sourcesSearch == "BIE") ? true : null) : null,
  //       }
  //     },
  //     index: (this.currentPage - 1),
  //     size: this.itemsPerPage ? this.itemsPerPage : null
  //   }


  //   if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
  //     request.data.document.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
  //     request.data.document.createdAtParam =
  //       {
  //         "operator": "[]",
  //         "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
  //         "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
  //       }
  //   }

  //   this.api.post('demandes/getByCriteria', request)
  //     .subscribe(
  //       res => {

  //         if (res && res['items']) {
  //           this.results = res['items'];
  //           this.totalItems = res['count'] ? res['count'] : 0;

  //           this.itemStatistique = {
  //             totalAuthentifie: res['totalAuthentifie'] ? res['totalAuthentifie'] : 0,
  //             montantTotalPaiement: res['montantTotalPaiement'] ? res['montantTotalPaiement'] : 0,
  //             totalRejete: res['totalRejete'] ? res['totalRejete'] : 0,
  //             totalValide: res['totalValide'] ? res['totalValide'] : 0,
  //             totalEnregistre: res['totalEnregistre'] ? res['totalEnregistre'] : 0,
  //             totalCloture: res['totalCloture'] ? res['totalCloture'] : 0,
  //             totalReintroduit: res['totalReintroduit'] ? res['totalReintroduit'] : 0,
  //             totalContentieux :  res['totalContentieux'] ? res['totalContentieux'] : 0,
  //             totalEnAttenteDelivrance: res['totalEnAttenteDelivrance'] ? res['totalEnAttenteDelivrance'] : 0,
  //             totalRetourContentieux :  res['totalRetourContentieux'] ? res['totalRetourContentieux'] : 0,
  //             totalDelivre :  res['totalDelivre'] ? res['totalDelivre'] : 0,
  //             totalDemande: this.totalItems,
  //             pourcentageDemandeRejete: 0,
  //             pourcentageDemandeAutentifiee: 0,
  //             pourcentageDemandeValidee: 0,
  //             pourcentageDemandeCloture: 0,
  //             pourcentageDemandeReintroduite: 0,
  //             pourcentageDemandeEnregistree: 0,
  //             pourcentageContencieux : 0,
  //             pourcentageRetourContencieux : 0,
  //             pourcentageEnAttenteDeDelivrance : 0,
  //             pourcentageDelivre : 0
  //           };

  //           if (this.totalItems && this.totalItems > 0) {
  //             this.itemStatistique.pourcentageDemandeRejete = parseFloat(((this.itemStatistique.totalRejete * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDemandeAutentifiee = parseFloat(((this.itemStatistique.totalAuthentifie * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDemandeValidee = parseFloat(((this.itemStatistique.totalValide * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDemandeEnregistree = parseFloat(((this.itemStatistique.totalEnregistre * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDemandeCloture = parseFloat(((this.itemStatistique.totalCloture * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDemandeReintroduite = parseFloat(((this.itemStatistique.totalReintroduit * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageContencieux = parseFloat(((this.itemStatistique.totalContentieux * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageRetourContencieux = parseFloat(((this.itemStatistique.totalRetourContentieux * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageEnAttenteDeDelivrance = parseFloat(((this.itemStatistique.totalEnAttenteDelivrance * 100) / this.totalItems).toFixed(2));
  //             this.itemStatistique.pourcentageDelivre = parseFloat(((this.itemStatistique.totalDelivre * 100) / this.totalItems).toFixed(2));
  //           }

  //           this.isChangeItermPerPage = false;
  //         } else {
  //           this.results = [];
  //           this.reinitStatistiueValue();
  //           if (res['hasError'] && res['status'] && res['status']['message']) {
  //             this.utilities.checkDeconnectionUser(res);
  //             this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
  //           }
  //         }
  //       },
  //       err => {
  //         // this.notificationService.defaultMessage()
  //         this.reinitStatistiueValue();
  //       }
  //     );
  // }


  reinitStatistiueValue() {
    this.totalItems = 0;
    this.itemStatistique = {
      montantTotalCertificat: 0,
      montantTotalFeuilletA5: 0,
      nombreTotalCommissariat: 0,
      nombreTotalCertificat: 0,
      totalFeuilletA5Restant: 0
    };
  }

  selectedDate(value: any, datepicker?: any) {

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.currentPage = 1;
    // or manupulat your own internal property
    this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');

    this.daterange.label = value.label;
    this.oldValueSearch = null;
    this.getData();
  }

  gotoSearchResultat(demande, isConfirmResultat) {
    sessionStorage.setItem(enumKeyCrypto_AES.idDocumentEnCoursAuthentification, this.utilities.encryptData(demande.document.id));
    sessionStorage.setItem(enumKeyCrypto_AES.currentCitoyenAAuthentifier, this.utilities.encryptData(demande));

    if (!isConfirmResultat) {
      this.router.navigate(['/resultat-recherche']);
    }
    else {
      this.router.navigate(['/confirmer-resultat-recherche']);
    }

  }


  formatDureeTraitement(duree) {
    if (duree && parseInt(duree) > 0) {
      let formattedPerformance = moment.utc(duree * 1000).format('mm:ss');

      if (parseInt(duree) >= this.user.dureeMoyenneDeclarationCertificat) {
        this.isCritiqueTempsEnrolement = true; // Cette valeur c'est pr savoir si nous devrons mettre la valeur en rouge
      } else {
        this.isCritiqueTempsEnrolement = false;
      }

      if (formattedPerformance && duree > 60) {
        formattedPerformance = formattedPerformance.replace(":", " min ");

        formattedPerformance = formattedPerformance + " sec";
      } else {
        let splitByTowPoint = formattedPerformance.split(":");

        if (splitByTowPoint && splitByTowPoint.length > 0) {
          formattedPerformance = splitByTowPoint[1] + " sec";
        }

      }

      return formattedPerformance;
    } else {
      return '-'
    }

  }

  showPreviewPrintModal(link?) {

    console.log(" this.linkPdfFile ", this.linkPdfFile);

    // window.location.href = link;

    // window.open(link, '_blank');

    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
    this.bsModalRefPreview.content.titleReportDocumentSortie = 'Certificat de résidence';
  }

  generateCertificat(document) {

    this.currentDocumentSelected = Object.assign({}, document);

    var request = {
      user: this.user.proxy,
      data: {
        "id": document.id
      }
    }

    this.busyGet = this.api.post('certificatResidences/imprimerCertificat', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError'] && res['urlFileToDownload']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['urlFileToDownload'] ? res['urlFileToDownload'] : null;
            this.showPreviewPrintModal(this.linkPdfFile);
            this.getData();
            // this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  imprimerRecu(document) {

    this.currentDocumentSelected = Object.assign({}, document);

    var request = {
      user: this.user.proxy,
      data: {
        "id": document.id
      }
    }

    this.busyGet = this.api.post('certificatResidences/ImprimerRecu', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError'] && res['urlFileToDownload']) {
            //Ouverture du modal de preview 
            this.linkPdfFile = res['urlFileToDownload'] ? res['urlFileToDownload'] : null;
            this.showPreviewPrintModal(this.linkPdfFile);
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  // getPositionGps() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {

  //       if (position && position.coords) {
  //         this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
  //       }

  //     });
  //   } else {
  //     alert("Geolocation non supporté par votre navigateur.");
  //   }
  // }

  reinitSeachPeriode() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  ngAfterViewInit() {
    this.reinitSeachPeriode();
  }

  checkNotifyPaiement() {

    console.log(" sessionStorage.getItem(", sessionStorage.getItem("transaction_id"));

    // On clear le set intervall pour ne pas qu'il fasse l'appel
    if (this.setIntervalNotifyAfterPaiement) {
      clearInterval(this.setIntervalNotifyAfterPaiement);
    }

    var request = {
      "cpm_trans_id": sessionStorage.getItem("transaction_id")
    }

    this.api.post('certificatResidences/notify-paiement', request)
      .subscribe(
        (res: any) => {
          console.log("Appel effectué ", res);
        },
        err => { }
      );
  }

  checkNotifyAfterPaiement() {
    this.setIntervalNotifyAfterPaiement = window.setInterval(() => {

      if (sessionStorage.getItem("retour-paiement") && sessionStorage.getItem("retour-paiement") == "ok") {
        this.checkNotifyPaiement();
        clearInterval(this.setIntervalNotifyAfterPaiement);
      }
    }, 1000);
  }

  ngOnInit() {
    this.getData();

    this.getCommissariats();

    sessionStorage.removeItem(enumKeyCrypto_AES.pasDeConnexionInternet);
    window.scrollTo(0, 0);

    this.userService.getStateRoutageAdministration(true); //Swicht du booléan pour le header pr les boutons administration
    this.userService.getStateRoutageMatching(true); // Pour que le bouton matching s'affiche
    this.userService.getStateRoutageDashbordClient(false); // Pour que le bouton tableau de bord client s'affiche

    this.refreshData();
    sessionStorage.removeItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch);
    console.log("le user connected dashbord ", this.user);
    this.checkNotifyAfterPaiement();
  }

  ngOnDestroy() {
    if (this.requestRefresh) this.requestRefresh.unsubscribe();

    if (this.setIntervalNotifyAfterPaiement) {
      clearInterval(this.setIntervalNotifyAfterPaiement);
    }

  }


  
}
