import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../user/user.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormReinitPasswordComponent } from '../../../+components/form-reinit-password/form-reinit-password.component';
import { Api } from '../../utils/api/api';
import { Subscription } from 'rxjs';
import { enumKeyCrypto_AES } from '../../utils/enumerator';
import { UtilitiesService } from '../../utils/utilities.service';
const swal = require('sweetalert2');

declare var $: any;

@Component({
  selector: 'sa-header',
  styleUrls:['./header.css'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  user: any;
  bsModalRef: BsModalRef;
  suscribStateAdministrationRoutes : Subscription;
  suscribStateMatchingRoutes : Subscription;
  suscribStateDashbordClientRoutes : Subscription;
  itemToSave: any = {
    hasAdministrationDashbord : true
  };
  currentLogo: any; 
  imageDisplay: any;
  hasAdminGoDashbord : boolean = false;
  hasDashbordClientPage :  boolean = false;
  currentPositionGps : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private router: Router, private userService: UserService,private activedRoute: ActivatedRoute, private modalService: BsModalService, private api: Api, private utilities: UtilitiesService) {
  }

  ngOnInit() {
    //sessionStorage.setItem("sm-menu-on-top",'false');
    this.user = this.userService.getCurrentUser();    
    // console.log(" user ",this.user);
    
    this.suscribStateAdministrationRoutes = this.userService.currentData.subscribe(currentData => {

        this.itemToSave.hasAdministrationDashbord = currentData;
    
    });

    this.suscribStateMatchingRoutes = this.userService.currentDataCheckingRoutageDashbordPage.subscribe(currentData => {

      this.itemToSave.hasAmatchingPage = currentData;
  
    });

    this.suscribStateDashbordClientRoutes = this.userService.currentDataCheckingRoutageDashbordClientPage.subscribe(currentData => {
      this.itemToSave.hasDashbordClientPage = currentData;
    });

    

  }

  openFormModalReinitMotDePasse(template, itemToModified) {

    if (itemToModified) {
      this.itemToSave = Object.assign({}, this.user);
    }
    this.bsModalRef = this.modalService.show(template);
  }


  truncateValue(item: any, nbre) {
    if (!item) return;

    let regex = /(&nbsp;|<([^>]+)>)/ig;

    //RETIRER LES CARACTERES HTML
    item = item.replace(regex, "");

    if (item.length > nbre) {
      item = item.substring(0, nbre).trim() + "" + " ...";
    }
    return item;
  }

  gotoDashbord(hasDirecteurGoDashbord,hasGotoDashBordViaLogo) {

    if(hasGotoDashBordViaLogo)
    {
      this.router.navigate(['/dashbord']);
      return;
    }

    this.itemToSave.hasDirecteurGoDashbord = hasDirecteurGoDashbord;
    if(hasDirecteurGoDashbord)
    {
      this.itemToSave.hasAdministrationDashbord = true;
      this.userService.getStateRoutageAdministration(this.itemToSave.hasAdministrationDashbord);
      this.router.navigate(['/dashbord']);
    }
    else
    {
      this.router.navigate(['/search-page']);
    }
  }


  checkPermission(code1,code2,code3)
  {
    let returnValue = null;
    let ListeFonctionnalites = this.user.fonctionnalites;

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;
  }
  
  gotoAdminPageOrDashbord(hasAdministrationDashbord) 
  {
 
    this.itemToSave.hasAdministrationDashbord = hasAdministrationDashbord;
    
    this.userService.getStateRoutageAdministration(this.itemToSave.hasAdministrationDashbord);
    if(hasAdministrationDashbord)
    {
      this.router.navigate(['/dashbord']);
    }
    else
    {
      this.router.navigate(['/adminstration/agents']);   
    }

  }

   
  gotoMatchingPageOrDashbord(hasAmatchingPage) 
  {
 
    this.itemToSave.hasAmatchingPage = hasAmatchingPage;
    
    this.userService.getStateRoutageMatching(this.itemToSave.hasAmatchingPage);
    if(hasAmatchingPage)
    {
      this.router.navigate(['/dashbord']);
    }
    else
    {
      this.router.navigate(['/home-matching']);   
    }

  }
 

  gotoClientPageOrDashbord(hasDashbordClientPage) 
  {
    
    this.itemToSave.hasDashbordClientPage = hasDashbordClientPage;
    
    this.userService.getStateRoutageDashbordClient(this.itemToSave.hasDashbordClientPage);
    if(hasDashbordClientPage)
    {
      // this.router.navigate(['/demandes-clients']);
      this.router.navigate(['/dashbord']);  
    }
    else
    {
      
      this.router.navigate(['/demandes-clients']);
    }

  }
 


  getIdentiteUser() {
    return this.truncateValue(this.user.nom + ' ' + this.user.prenoms, 12);
  }

  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;
    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);
  }

  updateProfilInformation() {
    this.router.navigate(['/update-profil-user']);
  }

  openChangePassword() {


    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRef.content.title = 'Changer votre mot de passe';
    /*this.bsModalRef.content.login_default = this.user.Login;
    this.bsModalRef.content.Identite = this.user.Nom +" "+this.user.Prenoms;
    this.bsModalRef.content.currentIdUser = this.user.Id;
    this.bsModalRef.content.currentUser = this.user; */


  }


  logout() {
    swal({
      title: 'Déconnexion',
      text: "<span style='font-size:17px'> Voulez-vous vous déconnecter de l\'application ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deconnexionUser();
        this.utilities.removeItemsLocalStoreGlobal();
        this.router.navigate(['/auth/login']);
      } 
    })
  }

  deconnexionUser()
  {
  
    const request = {
      data:
        {
          token: this.user.token,
          source: "Web",
          referenceSource: navigator.userAgent,
          positionGps: (this.currentPositionGps && this.currentPositionGps.positionGps) ? this.currentPositionGps.positionGps : "0,0"
        } 
    }

    this.api.post('utilisateurs/logout', request)
      .subscribe(
        res => {
          if (!res['hasError'] && res['items']) {

          }
        },
        err => {
        }
      );
  }

  getPositionGps() {
    this.currentPositionGps.positionGps = '0,0';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
       
        if (position && position.coords) {
          this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
        }
        
      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }


}
