import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormReconnectComponent } from '../../../+components/component-app/form-reconnect/form-reconnect.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../user';
import { enumKeyCrypto_AES } from '../../utils/enumerator';
// import {} from ''
// const store = require('store');



const CHECK_INTERVAL = 15000; // in ms
const STORE_KEY = enumKeyCrypto_AES.keyStoreAppAction;

@Injectable()
export class AutoLogoutService { 

    intervalle: any;
    private config: any = {};
    user: any = {};
    MINUTES_UNITL_AUTO_LOGOUT = 5; // in mins

    popUpLoginIsAllReadyLunch: boolean = false;

    public getLastAction() {
        return parseInt(sessionStorage.getItem(STORE_KEY), 10);
    }

    public setLastAction(lastAction: number) {
        sessionStorage.setItem(STORE_KEY, lastAction.toString());
    }

    constructor(private router: Router, private modalService: BsModalService, private userService: UserService) {
        // console.log('object created');
        this.user = this.userService.getCurrentUser();
        this.MINUTES_UNITL_AUTO_LOGOUT = ( (this.user && this.user.deconnectionTimer) ? this.user.deconnectionTimer : this.MINUTES_UNITL_AUTO_LOGOUT);
         
        this.check();
        this.initListener();
        this.initInterval();
        // sessionStorage.setItem(STORE_KEY,Date.now().toString());
    }

    initListener() {
        document.body.addEventListener('click', () => this.reset());
        document.body.addEventListener('mouseover', () => this.reset());
        document.body.addEventListener('mouseout', () => this.reset());
        document.body.addEventListener('keydown', () => this.reset());
        document.body.addEventListener('keyup', () => this.reset());
        document.body.addEventListener('keypress', () => this.reset());
    }

    reset() {
        if (!this.popUpLoginIsAllReadyLunch) {
            this.setLastAction(Date.now()); 
        }
    }

    initInterval() {

        this.intervalle = setInterval(() => {
            this.check();
        }, CHECK_INTERVAL);
    }

    clearInterval() {
        clearInterval(this.intervalle);
    }

    check() {
        const now = Date.now();

        if (!this.getLastAction()) return;

        const timeleft = this.getLastAction() + this.MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout && !sessionStorage.getItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch)) {
           
            sessionStorage.removeItem(enumKeyCrypto_AES.loginUserEncrypt);
            sessionStorage.removeItem(STORE_KEY);
            clearInterval(this.intervalle);

            if (!this.popUpLoginIsAllReadyLunch) {
                this.popUpLoginIsAllReadyLunch = true;
                sessionStorage.setItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch,"true");
                this.openPasswordForm(); 
            }

        }
        else
        {
            if(sessionStorage.getItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch))
            {
                clearInterval(this.intervalle);
            }
        }
    }

    openPasswordForm() {
        this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }))
            .content.currentUser = this.user;
        let refreshTimout = this.modalService.onHide.subscribe(r => {
            console.log("Reinit ok FormReconnectComponent ");
            this.popUpLoginIsAllReadyLunch = false; // On reinitialise la variable qui indique le lancement du pop-up
            sessionStorage.removeItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch)
            this.check();
            this.initListener();
            this.initInterval();  
        });
    }
}
