export enum EtatMissionId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum StatutCandidature {
    PRESELECTIONNER = 1,
    ENTRETIEN_PASSE=2,
    SELECTIONNE=3,
    CONTRAT_SIGNE=4,
    ELIMINE = 5
}

export enum EtatTemoignage {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum EnumCodeParametreBase {
    HeureNormaleTravail = "HrNor",
    CoefficientPrixPanier="CoPrP",
    ReferenceNumber="NumRefSaisi",
}


export enum enumEtatId {
    BROUILLON = 1,
    ENTRANT=2,
    VALIDE=3,
    REJETE=4
}

export enum enumEtatCode {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    REJETE="REJETE"
}

export enum enumEtatUserFo {
    Actif = "Actif",
    Suspendu="Suspendu",
    Fermer="Fermer",
    REJETE="REJETE"
}

export enum enumEtatAnnonce {
    Annule = "Annulé",
}


export enum enumLibelleEtats {
    BROUILLON = "BROUILLON",
    ENTRANT="ENTRANT",
    VALIDE="VALIDE",
    REJETE="REJETE"
}

export enum enumProfilGratuit {
    DEMANDE_GRATUITE = "DEMANDE-NON-FACTUREE-ADD"
}

export enum enumParametreBase {
    montantBase = "MONTANT AUTHENTIFICATION",
    maxLengthContactLibelle = "NOMBRE DE CHIFFRE DANS UN CONTACT TÉLÉPHONIQUE"
}

export enum enumModeDeRecherche { 
    NomPrenoms = "Nom et prénoms",
    refDocument = "Numéro de document"
}

export enum enumTypeDemande { 
    Payante = "Payante",
    Gratuite = "Gratuite"
}

export enum enumTypeDemandeur { 
    PourSoiMeme = "Pour soi-même",
    Mandataire = "Mandataire"
}


export enum enumCategorieDemandeur {
    PersonnePhysique = "Personne physique",
    personneMorale = "Personne morale"
}

export enum enumStatutDemande {
    ENREGISTRE = "Enregistré",
    REJETE = "Rejeté",
    VALIDE = "Validé",
    AUTHENTIFIE = "Authentifié",
    REINTRODUIT = "Reintroduit",
    CLOTURE = "Cloturé",

    CONTENTIEUX = "Contentieux",
    CONTENTIEUSES = "Contentieuses",
    RETOUR_CONTENTIEUX = "Retour contentieux",
    EN_ATTENTE_DELIVRANCE = "En attente de délivrance",
    EN_ATTENTES_DELIVRANCE = "En attentes de délivrance",
    DELIVRE = "Délivré"
}


export enum enumKeyCrypto_AES {
    montantBaseParam = "@12_mxRboqAPox#Paramx1",
    longueurContactParam = "Qaze@#ujOIEIxwyhfa_olidxsssje",
    ListeObjetDemandes = "bgAsdnfdjjfxAut",
    ListeNaturesDemandes = "ghUtilsx00Adhfhf",
    ListeNaturesPieces = "nbvxdAZd_00938hjuio@#&",
    ListeNaturesPiecesForAuthentification = "nbvxdAZd_00938hjuio@#&vxvgfxfhs@11112",
    currentDemandeToUpdate = "bgt###Az21&09OPoozxdyvxs",
    currentCertificatToUpdate = "certAxsdbgt###Az21&09OPoozxdyvxs",
    encryptionKey = 'aLQVIZ!H|lD=@Cj#',
    loginUserEncrypt = "dxDazw001&#xz",
    idDocumentEnCoursAuthentification = "dfxAzer@&010p&#xz",
    currentCitoyenAAuthentifier = "vxvxvxgsa&#iudp",
    ListeNaturesPiecesTitulaire = "uueuehhb@&AZEEààinfnf-01",
    ListeNaturesPiecesMandataire = "azertui&#9EUHbvvpmiis",
    currentDemandeToSearchAuthentificationDG = "zzedvvtyy7!è!affhf",
    deconnectionTimeAuthPage = "dx1&00304azrrt",
    popUpDeconnexionIsLunch = "gdgdggfyu!çà&énnkfj",
    keyStoreAppAction = "vtbh@&lopmqz",
    pasDeConnexionInternet = "vtbh@&vbsgfhrgfzgcvnlopmqz",
    listeDepartements = "vtbh@&bghdhdhda&éçu",
    searchNumberInputValue = "bdgjhgfhcvcfswx§èèbs",
    categoriePieceMatching = "dertàa&jfjbgbgn"
}


export enum enumStatutMatching {
    Matche = "Correspondance",
    NonMatche = "Pas de correspondance",
    MatchingNonEffectue = "Matching non effectué"
}



export enum enumLibelleCustom {
    PhotoIdentiteNaturePiece = "PHOTO D'IDENTITÉ",
    MontantAuthentification = "MONTANT AUTHENTIFICATION",

    DureeInactivite = "durée d'inactivité d'une session",
    DureeValiditeCertificat = "durée de validité d'un certificat",
    PrixCertificatResidence = "prix d'un certificat de résidence",
    PrixFeuilletA5Securise = "prix d'un feuillet a5 sécurisé",
    NombreDataExportable = "nombre total de données exportable",
    dureeModificartionCertificat  =  "durée modification d'un certificat de résidence",
    dureeImpressionCertificat = "durée impression d'un certificat de résidence",
    seuilAlerteFeuillet = "seuil d'alerte stock feuillet a5",
    dureeMoyenCertificat = "Durée traitement moyen d'un certificat",
    DureeValidite = "DURÉE VALIDITÉ CODE CONNEXION",
    PROCURATION_JUSTICE = "PROCURATION DE JUSTICE",
    ACTE_DEHEREDITE = "ACTE D'HEREDITE",
    PRODUCRATION_DE_MARIE = "PROCURATION DE MAIRIE",
    LIBELLE_AUTRE = "AUTRE",
    LIBELLE_NATURE_PIECE_AUCUN= "AUCUN",
    Direction_General_Profil = "Direction Générale",
    ProcedureJudiciaire = "PROCÉDURE JUDICIAIRE",
    TailleMaxPJ = "taille maximale pièce jointe",
    ScoreMinimaleEmpreinteDigitale = "SCORE MINIMAL ENPREINTE DIGITALE",
    ScoreMinimaleCorrespondance = "SCORE MINIMAL CORRESPONDANCE DES PHOTOS IMAGGA",
    ScoreMinimaleCorrespondancePhotoPython  = "SCORE MINIMAL CORRESPONDANCE DES PHOTOS PYTHON",
    PvProcessVerbal  = "PROCES VERBAL",
    PETITE_IDENTITE = "Petite identité",
    GRANDE_IDENTITE = "Grande identité",
    RNPP_INSPECTION = "RNPP – INSPECTION PDNR",
    LIBELLE_CARTE_NATIONALE_IDENTITE = "CARTE NATIONALE D'IDENTITÉ",
    motifRegularisation = "régularisation"
}

export enum enumDefaultNumeroPaiement {
    defaultCaractere = "-"
}


export enum enumMessageDeconnexionCustom {
    SessionExpiree = "Session expirée. Veuillez vous reconnecter !",
    codeNonValide = "Ce code de connexion n'est plus valide. Veuillez vous reconnecter !",
    SessionExpireeDeconnexion = "Désolé votre session à expirée. Veuillez vous reconnecter svp !"
}

export enum enumMessageCustom {
    demandeAttenteValidation = "Désolé cette demande est en attente de validation !"
}

export enum enumProxy {
    proxyBoris = "fKvqeCe4JzV6Kgai"
}





