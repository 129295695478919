import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-parametrage-comissariat',
  templateUrl: './main-parametrage-comissariat.component.html',
  styleUrls: ['./main-parametrage-comissariat.component.css']
})
export class MainParametrageComissariatComponent implements OnInit {

  defaultLocalite : any;
  currentLocalite : any = {};
  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  defaultEndPoint = 'commissariats'

  config = {
    animated: false,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15;
    this.currentItemChecked = {};
    this.itemToSearch = {};
  }

  
  checkPermission(code1,code2,code3)
  {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;

  }


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified,isDetails? : boolean) {
    this.cancelSave();
    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);

      if(this.itemToSave.isAgenceOneci) {
        this.itemToSave.categorie = "true";
      }else {
        this.itemToSave.categorie = "false";
      }

      if(this.itemToSave.localiteDto && this.itemToSave.localiteDto.libelle) {
        this.defaultLocalite = this.itemToSave.localiteDto.libelle;
        this.currentLocalite = this.itemToSave.localiteDto;
      }
    }
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: !isDetails ? 'gray modal-lg' : 'modal-md' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }



  confirmActiveOrDesactiveItem(obj, isDesactiveActive) {

    swal({
      title: 'Commissariat',
      text: !isDesactiveActive ? 'Voulez-vous activer ce commissariat ?' : 'Voulez-vous suspendre ce commissariat ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f29302",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.desactiveOrActiveitem(obj);
      } 
    })

  }

  desactiveOrActiveitem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/activerDesactiver', request)
      .subscribe(
        res => {
          // console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Commissariat", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.oldValueSearch = null;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
        }
      );
  }


  getData(isRefresh ?) {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if(!isRefresh) {
      if (search_value && this.oldValueSearch) {
        if (search_value != this.oldValueSearch) {
          this.currentPage = 1;
        }
      }
  
      if (search_value) {
        if (search_value.length > 0 && search_value.length < 3) {
          if (!this.isChangeItermPerPage) {
            return;
          }
        }
        else {
          if (search_value == this.oldValueSearch) {
            return;
          }
          this.oldValueSearch = search_value;
          //this.currentPage = 1;
        }
      }
      else {
        if (this.oldValueSearch == "" && isSearch) {
          return;
        }
  
        this.oldValueSearch = "";
      }
  
    }
   
    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: this.itemToSearch.fullText || null,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );

  }


  confirmDeleteItem(obj) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        // obj.id = 1020;
        this.deleteItem(obj);
      }
    })

  }

  cancelSave() {
    this.itemToSave = {};
    this.defaultLocalite = null;
    this.currentLocalite = {};
  }


  confirmSave(item) {

    if (!item || !item.libelle ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le libellé "+(this.user.commissariatActif ? "de l'agence / commissariat" :"de l'agence"))
      return;
    }

    if (this.user.commissariatActif && item.categorie == "false" && !item.code ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le code de "+(this.user.commissariatActif ? "de l'agence / commissariat" :"de l'agence"))
      return;
    }

    if (!this.currentLocalite || !this.currentLocalite.id ) {
      this.notificationService.showMessageError("Information", "Veuillez sélectionner une localité.")
      return;
    }

    if (this.user.commissariatActif && item.categorie == "false" && !item.adresse ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner l'adresse "+(this.user.commissariatActif ? "de l'agence / commissariat" :"de l'agence"))
      return;
    }

    if (this.user.commissariatActif && item.categorie == "false" && !item.contact ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le contact "+(this.user.commissariatActif ? "de l'agence / commissariat" :"de l'agence"))
      return;
    }

    if (!item.commissaire ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le nom du responsable.")
      return;
    }

    if (!item.contactCommissaire ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le contact du responsable.")
      return;
    }

    if (item.contactCommissaire.length != 10 ) {
      this.notificationService.showMessageError("Information", "Le contact doit être de 10 chiffres.")
      return;
    }

    if (!item.emailCommissaire ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner l'email du responsable.")
      return;
    }

    // Verifier si l'email est correct
    if (item.emailCommissaire || item.emailCommissaire) {
      let emailCommissaire = item.emailCommissaire || this.itemToSave.emailCommissaire;
      let tabSplitArrobase = emailCommissaire.split("@");
      if (!tabSplitArrobase || tabSplitArrobase.length < 2) {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner un email valide.");
        return;
      }
      else {
        //Verifier si il y a un point apres l'arobase
        let tabSplitPoint = tabSplitArrobase[1].split(".");
        if (!tabSplitPoint || tabSplitPoint.length < 2) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner un email valide.");
          return;
        }

        if (tabSplitPoint[1].length == 0) {
          this.notificationService.showMessageError("Erreur", "Veuillez renseigner un email valide.");
          return;
        }
      }
    }


    swal({
      title: (!item.id ? 'Enregistrer une' : 'Modifier une') +(this.user.commissariatActif ? " agence / commissariat" : " agence"),
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        item.localite = this.currentLocalite.id;
        item.isAgenceOneci = item.categorie == "true" ? true : false;
        this.saveItem(item);
      } 
    })
  }

  localiteSlected($event) {
    this.currentLocalite = {...$event};
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post(this.defaultEndPoint+'/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces((this.user.commissariatActif ? "Agence / commissariat" :"Agence"), this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData(true);
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression",  this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData(true);
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }


}
