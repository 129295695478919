import { Component, OnInit } from '@angular/core';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";
import {config} from "../../smartadmin.config";
import {LayoutService} from "../layout.service";
import {UserService} from "../../user/user.service";
import {ActivatedRoute, Route, Router} from '@angular/router';
import { enumKeyCrypto_AES } from '../../utils/enumerator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormReconnectComponent } from '../../../+components/component-app/form-reconnect/form-reconnect.component';
import { Api } from '../../utils/api/api';
import { Subscription } from 'rxjs';

@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: []
})
export class MainLayoutComponent implements OnInit {


  user : any;
  deconnectionTimer : number = 5;
  bsModalRef : BsModalRef;
  intervalle = 1000;
  countTer = 0;
  activite_detectee : boolean; 
  logoutModalIsLunch : boolean = false;
  lunchFonction : any;
  suscribForDeconnexionTime : Subscription; 

  private config:any = {};

  // config = {
  //   animated: true,
  //   keyboard: false,
  //   backdrop: 'static',
  //   ignoreBackdropClick: true
  // };

  constructor(public layoutService:LayoutService,private activedRoute: ActivatedRoute,private userService: UserService,public router:Router,private api:Api,private modalService: BsModalService) {
   
    this.user = this.userService.getCurrentUser(); 
    
    this.deconnectionTimer = (this.user.deconnectionTimer ? this.user.deconnectionTimer : this.deconnectionTimer) * 60;
    this.initTimoutActivityScreem(); 
  }

  initTimoutActivityScreem()
  {
    this.lunchFonction = setTimeout(() => {
      this.getActiviteInScream()
    }, 500);
  }

  openPasswordForm() {
    this.logoutModalIsLunch = true;
    this.bsModalRef = this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }));
    this.bsModalRef.content.currentUser = this.user;
    let refreshTimout = this.modalService.onHide.subscribe(r=>{
        this.countTer = 0;
        this.logoutModalIsLunch = false;
        this.activite_detectee = true; 
        window.clearTimeout(this.lunchFonction);
        this.initTimoutActivityScreem();
    });
  }

    // On crée la fonction qui teste toutes les x secondes l'activité du visiteur via activite_detectee
    getActiviteInScream() {
     
      if(this.activite_detectee)
      {
            this.activite_detectee = false; 
            this.countTer = 0;
      }
      else
      {
            this.countTer = this.countTer + 1;
            // console.log("le active route",this.countTer); 

            if(this.countTer >= this.deconnectionTimer)
            {
                if(this.logoutModalIsLunch)
                {
                  this.countTer = 0;
                  window.clearTimeout(this.lunchFonction);
                  this.logoutModalIsLunch = false;
                  return;
                }
               
                //Deconnecter l'utilisateur
                //this.openPasswordForm();
                //sessionStorage.removeItem(enumKeyCrypto_AES.loginUserEncrypt);
                //this.deconnection();
               
                // console.log("je me deconnecte")
                window.clearTimeout(this.lunchFonction);
                this.countTer = 0;
                return;
            }
            else
            {
              if(this.logoutModalIsLunch)
              {
                this.countTer = 0;
                window.clearTimeout(this.lunchFonction);
                this.logoutModalIsLunch = false;
                return;
              }
            }
      }
      
      this.lunchFonction = setTimeout(() => {
         this.getActiviteInScream()
      }, 1000);
 
  }
  
  ngOnInit() {
   
    this.suscribForDeconnexionTime = this.userService.currentDataSubscribeOpenModal.subscribe(currentData => {
      
      if(currentData)
      {
        //Arreter le counter
        window.clearTimeout(this.lunchFonction);
      }
      else
      {
        //Relancer le counter
        window.clearTimeout(this.lunchFonction);
        this.initTimoutActivityScreem();
      }
  
  });
  
  }

  ngOnDestroy(){
    window.clearTimeout(this.lunchFonction);
    
    if(this.suscribForDeconnexionTime)
    {
      this.suscribForDeconnexionTime.unsubscribe();
    }
  }

  //Ecouter si on a ouvert le modal et desactiver le counter
  

}
