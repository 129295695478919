import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/utils/notification.service';
import { Api } from '../../../shared/utils/api/api';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { BsModalRef } from 'ngx-bootstrap';
import { UserService } from '../../../shared/user';
import { Subscription } from 'rxjs';
const swal = require('sweetalert2');
import { Observable } from 'rxjs';
import { enumKeyCrypto_AES, enumMessageDeconnexionCustom } from '../../../shared/utils/enumerator';

@Component({
  selector: 'app-form-reconnect',
  templateUrl: './form-reconnect.component.html',
  styleUrls: ['./form-reconnect.component.css']
})
export class FormReconnectComponent implements OnInit {

  busyConnecte: Subscription;
  user: any = {};
  itemToSave: any = {};
  currentUser: any = {};
  currentPositionGps: any = {};
  requestRefresh: Subscription;
  itemNewOtp: any = {};
  itemLogin: any = {};
  code : any;

  intervalId : number;
  seconds : number = 120;

  constructor(public router: Router, private notificationService: NotificationService, private api: Api, private utilities: UtilitiesService, public bsModalRef: BsModalRef, private userService: UserService) {
    setTimeout(() => {
      this.user = Object.assign({}, this.currentUser);
    }, 300);
  }

  //Generate capchar 
  onGenerateCode(code) {
    this.code = code
  }


  login(item) {

    item.showLoader = true;
    item.login = this.user.login;

    if (!item || !item.password) {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur", "Veuillez saisir le mot de passe.");
      return;
    }

    var request = {
      "data":
        {
          login: item.login,
          password: item.password,
          action: "Connexion",
          source: "Web",
          referenceSource: navigator.userAgent,
          positionGps: this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : '0,0'
        }
    }

    this.busyConnecte = this.api.post('utilisateurs/login', request)
      .subscribe(
        res => {

          item.showLoader = false;
          if (!res['hasError'] && res['items']) {
            var currentUser = res['items'][0];
            this.seconds = currentUser.deconnectionTimer || 120;
            currentUser.Password = null;
            sessionStorage.setItem(enumKeyCrypto_AES.loginUserEncrypt, this.utilities.encryptData(currentUser));
            this.bsModalRef.hide();
            
          }
          else {
            this.notificationService.showMessageError("Connexion", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
          item.showLoader = false;
          this.notificationService.defaultMessage()
        }
      );

  }

  transformUppercase()
  {
    this.itemLogin.codeSmsEmail = this.itemLogin.codeSmsEmail.toUpperCase();
  }

  // verifyCode(item) {

  //   item.showLoaderValidate = true;

  //   // if (!this.currentPositionGps || !this.currentPositionGps.positionGps) {
  //   //   item.showLoaderValidate = false;
  //   //   this.notificationService.showMessageError("Erreur", "Veuillez autoriser l'application à accéder à votre position  !");
  //   //   return;
  //   // }

  //   if (!item.codeSmsEmail) {
  //     this.notificationService.showMessageError("Erreur", "Veuillez saisir le code reçu par mail et sms.");
  //     return;
  //   }

  //   var request = {
  //     user: this.currentUser.proxy,
  //     "data":
  //       {
  //         proxy: this.currentUser.proxy,
  //         codeSmsEmail: item.codeSmsEmail,
  //         source: "Web",
  //         referenceSource: navigator.userAgent,
  //         positionGps: this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : '0,0'
  //       }
  //   }

  //   this.busyConnecte = this.api.post('user/validerCodeConnexion', request)
  //     .subscribe(
  //       res => {
          
  //         item.showLoaderValidate = false;
  //         if (!res['hasError'] && res['items']) {

  //           var currentUser = res['items'][0];
  //           currentUser.Password = null;

  //           sessionStorage.setItem(enumKeyCrypto_AES.loginUserEncrypt, this.utilities.encryptData(currentUser));
  //           this.bsModalRef.hide();
  //           // this.router.navigate(['/dashbord']); 
  //         }
  //         else {
  //           item.showLoaderValidate = false;

              
  //             let messageError = this.utilities.mainSelfFormatMsgServeur(res['status']['message']).trim();
  //             this.notificationService.showMessageError("Code de vérification", messageError);
  //             if( messageError == enumMessageDeconnexionCustom.codeNonValide || messageError == enumMessageDeconnexionCustom.SessionExpiree)
  //             {
  //               this.itemToSave = {};
  //             }

  //          }
  //       },
  //       err => {
          
  //         item.showLoaderValidate = false;
  //         this.notificationService.defaultMessage()
  //       }
  //     );
  // }

  closeSession() {

    swal({
      title: 'Fermerture de session',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
      sessionStorage.removeItem(enumKeyCrypto_AES.loginUserEncrypt);
       document.location.reload(true);
      } 
    })

  }

  getPositionGps() {

    this.currentPositionGps.positionGps = '0,0';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {

        if (position && position.coords) {
          this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
        }

      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }

  //Reinitialiser sa position à chaque 5minutes car il peut se deplacer
  // refreshDataPositionGps() {
   
  //   this.requestRefresh = Observable.interval(this.utilities.getTimeRefreshPositionGps())
  //     .subscribe(i => {
  //       this.getPositionGps()
  //     })
  // }

  ngOnInit() {
    //this.getPositionGps();
  }

  ngOnDestroy() {
    if (this.requestRefresh) this.requestRefresh.unsubscribe();
    if (this.intervalId) clearInterval(this.intervalId);
  }

  // lunchCounter(): void {

  //   this.clearTimer();

  //   this.intervalId = window.setInterval(() => {
  //     this.seconds -= 1;
  //     if (this.seconds === 0) {
  //       this.clearTimer(); // Supprime le set intervalle
  //       this.itemNewOtp.showLoader = false;
  //       this.itemToSave.showLoader = false;
  //       this.itemLogin.showLoaderValidate = false;
  //       this.itemToSave.isValideLogin = false;
  //       this.itemLogin.codeSmsEmail = "";
  //     } else {
  //       if (this.seconds < 0) { this.seconds = 119; } // Reinitialiser le counter
  //     }
  //   }, 1000);

  // }

  // clearTimer()
  // {
  //   clearInterval(this.intervalId);
  // }

}
