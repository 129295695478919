import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Api } from 'app/shared/utils/api/api';
import { NotificationService } from 'app/shared/utils/notification.service';
import { UtilitiesService } from 'app/shared/utils/utilities.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-search-input-with-libelle',
  templateUrl: './search-input-with-libelle.component.html',
  styleUrls: ['./search-input-with-libelle.component.css']
})
export class SearchInputWithLibelleComponent implements OnInit {

  itemToSave : any = {};
  oldValueSearchInput : String;
  currentPageSearch : number = 1;
  isPaginationChange : boolean = false;
  user : any = {};
  itemsPerPageSearch : number = 7;
  ListeSearchInput : Array<any> = [];
  totalItemsSearch : number;
  itemToSearch : any = {};
  currentSelected : any = {};
  busyGet: Subscription;

  constructor(private api: Api,private utilities: UtilitiesService,private notificationService: NotificationService) { }

  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  @Input() defaultItem : any;
  @Input() title : any;
  @Input() isNotRequired : any;
  @Input() endPoint : any;
  @Input() disabled : boolean;
  
  // SEARCH INPUT FONCTION
  getDataSearch() {

    let search_value = this.itemToSave.searchInputSelected || null;

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        return;
      }

      if (this.oldValueSearchInput != search_value) {
        this.currentPageSearch = 1;
      }

      if (this.oldValueSearchInput == search_value && !this.isPaginationChange) {
        return
      }
      this.oldValueSearchInput = search_value;
    }

    var request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSave.searchInputSelected || null,
      },
      index: (this.currentPageSearch - 1),
      size: this.itemsPerPageSearch ? this.itemsPerPageSearch : null
    }

    console.log(" endPoint ",this.endPoint);

    this.busyGet = this.api.post(this.endPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(res);
          this.selectedValue({});
          if (res && res['items']) {
            this.ListeSearchInput = res['items'];
            this.totalItemsSearch = res['count'];
            this.itemToSearch.isDisplayTable = true;
            this.isPaginationChange = false;
          } else {
            this.ListeSearchInput = [];
            this.itemToSearch.isDisplayTable = true;
            this.totalItemsSearch = 0;

            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
        }
      );

  }

  pageChangedSearch(event: any): void {
    this.currentPageSearch = event.page;
    this.isPaginationChange = true;
    this.getDataSearch();
  }

  selectedItemSearch(item) {
    if (item) {
      this.currentSelected = Object.assign({}, item);
      this.itemToSave.searchInputSelected = this.currentSelected.libelle;
      this.itemToSearch.isDisplayTable = false;
      this.selectedValue(item);
    }
  }

  selectedValue(item) {
    console.log(" voici la selection qu'on emet",item);
    this.itemToSearch.isDisplayTable = false;
    if (item && item.id) {
      this.selectedItem.emit(item);
    }else{
      this.selectedItem.emit({});
    }

  }

  ngOnInit() {
    console.log(" le endPoint ",this.endPoint);
  }



  ngOnChanges(changes: SimpleChanges) {

    if ((changes['defaultItem'] && changes['defaultItem'].currentValue)) {
      this.itemToSave.searchInputSelected = this.defaultItem;
    }
  }

}
