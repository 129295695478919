import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import { Router } from '@angular/router';
const swal = require('sweetalert2');


@Component({
    selector: 'app-main-profil',
    templateUrl: './main-profil.component.html',
    styleUrls: ['./main-profil.component.css']
})
export class MainProfilComponent implements OnInit {


    results: Array<any> = [];
    ListeRoles: Array<any> = [];
    ListeFonctionnalites: Array<any> = [];
    ListeFonctionnalitesTest: Array<any> = [];
    ListeFonctionnalitesOldRole: Array<any> = [];
    ListFonctionnaliteToSave: Array<any> = [];
    ListeProvisoirProfil: Array<any> = [];
    ListeCategorie: Array<any> = [];

    busyGet: Subscription;
    busySave: Subscription;
    busyFct: Subscription;
    itemToSave: any;
    totalItems = 0;
    currentPage = 1;
    user: any;
    itemsPerPage: any;
    currentItemChecked: any;
    statutCotisation: any;
    itemToSearch: any;
    modalRef: BsModalRef;
    currentItemImage: any;
    imageDisplay: any;


    config = {
        animated: true,
        keyboard: false,
        backdrop: 'static',
        ignoreBackdropClick: true
    };

    ListeProfilFctForUpdate = [];


    constructor(private api: Api, public router: Router, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
        this.cancelSave();
        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 10;
        this.currentItemChecked = {};
        this.itemToSearch = {};

    }


    checkPermission(code1,code2,code3)
    {
      // console.log(" code1 ",code1); 
  
      let returnValue = null;
  
      let ListeFonctionnalites = this.user.fonctionnalites;
  
      // console.log(" ListeFonctionnalites ",ListeFonctionnalites);
  
      let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));
  
      if(itemFonctionnalite) 
      {
        returnValue = true;
      }
      
      return returnValue;
  
    }

    
    openFormModalModal(template, itemToModified) {
        this.cancelSave();
        if (itemToModified) {
            this.itemToSave = Object.assign({}, itemToModified);
            this.getDataFonctionnaliteByRole(itemToModified);
            // this.ListeFonctionnalites = this.itemToSave.datasFunctionalityHierarchicalyFormatted;
            // this.setDefaultFct();
            // this.getDataFonctionnaliteByRole(this.itemToSave);
        }

        //this.modalRef = this.modalService.show(template,Object.assign({},this.config, { class: 'gray modal-lg modal-custom-preview' }));
    }




    pageChanged(event: any): void {
        this.currentPage = event.page;
        this.getData();
    }

    changeItermPerPage() {
        this.currentPage = 1;
        this.getData();
    }

    searchByCriteria(text) {
        
        if (text) {
            text = text.trim();
            text = text.toLowerCase();
            this.results = _.filter(this.ListeProvisoirProfil, function (o) { return (_.includes(o.searchValue, text)) });
        }
        else {
            this.results = this.ListeProvisoirProfil;
        }

    }

    getData() {

        if (this.itemToSearch.profilLibelle && this.itemToSearch.profilLibelle.length < 3) {
            return;
        }

        var request = {
            user: this.user.proxy,
            data: {
                libelle: this.itemToSearch.profilLibelle ? this.itemToSearch.profilLibelle : null
            },
            index: (this.currentPage - 1),
            size: this.itemsPerPage ? this.itemsPerPage : null
        }

        this.busySave = this.api.post('profils/getByCriteria', request)
            .subscribe(
                res => {
                    // console.log('profils s in profils',res);   
                    if (res && res['items']) {
                        this.results = res['items'];

                        _.forEach(this.results, function (value, key) {
                            value.searchValue = value.libelle.toLowerCase();
                        });

                        this.ListeProvisoirProfil = this.results;
                        this.totalItems = res['count'] ? res['count'] : 0;
                    } else {
                        this.results = [];
                        if (res['hasError'] && res['status'] && res['status']['message']) {
                            this.utilities.checkDeconnectionUser(res);
                            this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        }
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.defaultMessage()
                    // this.not
                }
            );

    }

    setCheckedItemCategorie(checked) {
        if (checked) {
            _.forEach(this.ListeCategorie, function (value, key) {
                value.checkedItem = true;
            });
        }
        else {
            _.forEach(this.ListeCategorie, function (value, key) {
                value.checkedItem = false;
            });
        }

    }


    getDataFonctionnalite() {
        var request = {
            user: this.user.proxy,
            data: {}
        }

        this.busySave = this.api.post('fonctionnalites/getByCriteria', request)
            .subscribe(
                res => {
                    // console.log('les fonctionnalites', res);
                    if (res && res['items']) {
                        this.ListeFonctionnalites = res['items'];
                        //this.totalItems = res['count'] ? res['count'] : 0;
                    }
                },
                err => {
                    // console.log("Error occured", err);
                    //   this.notificationService.showMessageError("Connexion",this.notificationService.defaultMessage());
                    // this.not
                }
            );
    }

    getDataFonctionnaliteByRole(item) {
        var request = {
            user: this.user.proxy,
            data: {
                id: item.id
            }
        }

        this.busyFct = this.api.post('fonctionnalites/getFonctionnaliteByProfil', request)
            .subscribe(
                res => {
                    // console.log('les fonctionnalites by role', res);
                    if (res && res['items']) {
                        this.ListeProfilFctForUpdate = [];
                        this.ListeFonctionnalitesOldRole = res['items'];


                        for (var i = 0; i < this.ListeFonctionnalitesOldRole.length; i++) {
                            let currentVal = this.ListeFonctionnalitesOldRole[i];
                            _.forEach(currentVal.datasChildren, (valueChildSecond, keyChild) => {

                                if (valueChildSecond.privilege == "1") {
                                    this.ListeProfilFctForUpdate.push(currentVal);
                                    this.ListeProfilFctForUpdate.push(valueChildSecond);
                                }
                            });
                        };

                        this.setDefaultFct();
                    }
                },
                err => {
                    // console.log("Error occured", err);
                }
            );
    }

    setDefaultFct() {
        //console.log("iififif",this.ListeFonctionnalites);
        _.forEach(this.ListeFonctionnalites, (valueFct, key) => {
            // console.log("la valeur",value);
            // console.log("la clé",key);

            //Verifier si 
            let currentProfil = _.find(this.ListeProfilFctForUpdate, { 'id': valueFct.id });

            if (currentProfil && currentProfil.id) {
                valueFct.checkedItem = true;
                valueFct.isOpen = true;
            }


            _.forEach(valueFct.datasChildren, (valueChildSecond, keyChild) => {

                let currentProfilSecond = _.find(this.ListeProfilFctForUpdate, { 'id': valueChildSecond.id });

                if (currentProfilSecond && currentProfilSecond.id) {
                    valueChildSecond.checkedItem = true;
                }
            });


            // if(value.datasChildren)
            // {
            //     _.forEach(value.datasChildren, function(valueChild, keyChild) {

            //         if(valueChild.privilege=="1")
            //         {
            //             //Recuperer la fonctionnalité dans la liste des fct
            //             let currentProfil = _.find(this.ListeFonctionnalites, { 'id':valueChild.id });

            //             currentProfil.checkedItem = true;
            //             value.checkedItem = true;
            //         }

            //         // _.forEach(valueChild.datasChildren, function(valueChildSecond, keyChild) {
            //         //     if(valueChildSecond.privilege=="1")
            //         //     {
            //         //         valueChildSecond.checkedItem = true;
            //         //         valueChild.checkedItem = true;
            //         //     }
            //         //   });
            //       });
            // }


        });


    }

    confirmDeleteItem(obj) {
        //this.lgModal.show();

        swal({
            title: 'Suppression',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: "#f29302",
            cancelButtonColor: "#333333",
        }).then((result) => {
            if (result) {
                this.deleteItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }



    confirmSaveItem(obj) {

        swal({
            title: !obj.id ? 'Enregistrement de profil' : 'Modification de profil',
            text: 'Désirez-vous poursuivre cette action ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: "#f29302",
            cancelButtonColor: "#333333",
        }).then((result) => {
            if (result) {
                this.saveItem(obj);
            } else {
                console.log("bnjr");
            }
        })

    }

    checkCurrentItem(item) {
        if (item) {
            console.log("ellement coché", item);
            this.currentItemChecked = item;
        }
    }

    cancelSave() {
        this.itemToSave = {};
        this.setCheckedItem(false, 'checkall', '');
        //this.setCheckedItemCategorie(false);
    }

    saveItem(item) {

        if (!item) {
            this.notificationService.showMessageInfo("Attention", "Veuillez renseigner les informations sur le profil");
            return;
        };


        if (!item || !item.libelle) {
            this.notificationService.showMessageInfo("Information", "Veuillez renseigner les champs obligatoires.")
            return;
        }

        var listeFonctionnaliteToSave = [];

        /* LISTE DES FONCTIONNALITES */
        _.forEach(this.ListeFonctionnalites, function (value, key) {
            // console.log("la valeur",value);
            // console.log("la clé",key);
            if (value.checkedItem) {
                listeFonctionnaliteToSave.push({ id: value.id });
            }

            if (value.datasChildren) {
                _.forEach(value.datasChildren, function (valueChild, keyChild) {

                    if (valueChild.checkedItem) {
                        listeFonctionnaliteToSave.push({ id: value.id });
                        listeFonctionnaliteToSave.push({ id: valueChild.id });
                    }

                    _.forEach(valueChild.datasChildren, function (valueChildSecond, keyChild) {
                        if (valueChildSecond.checkedItem) {
                            listeFonctionnaliteToSave.push({ id: valueChildSecond.id });
                            listeFonctionnaliteToSave.push({ id: valueChild.id });
                        }
                    });

                });
            }
        });

        if (listeFonctionnaliteToSave.length == 0) {
            this.notificationService.showMessageInfo("Information", "Veuillez sélectionner les fonctionnalités.")
            return;
        }

        item.fonctionnalites = _.uniqBy(listeFonctionnaliteToSave, 'id');

        var request = {
            user: this.user.proxy,
            datas: [
                item
            ]
        }

        //   console.log("le request",request);

        this.busySave = this.api.post('profils/' + (item.id ? 'update' : 'create'), request)
            .subscribe(
                res => {
                    //   console.log("resul",res);
                    if (!res['hasError']) {
                        this.notificationService.showMessageSucces("Profil", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        this.currentPage = 1;
                        this.cancelSave();
                        this.getData();
                    } else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        }
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
                }
            );
    }


    deleteItem(obj) {

        var request = {
            user: this.user.proxy,
            datas: [{
                id: obj.id
            }]
        }

        this.busyGet = this.api.post('profils/delete', request)
            .subscribe(
                res => {
                    //console.log(res); 
                    if (!res['hasError']) {
                        this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        this.currentPage = 1;
                        this.cancelSave();
                        this.getData();
                    }
                    else {
                        if (res['status'] && res['status']['message']) {
                            this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        }
                    }
                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.defaultMessage()
                }
            );
    }


    getExactlyNumberRow(page, index) {
        let num = index + 1;
        if (page > 1) {
            num = ((page - 1) * 10) + (index + 1);
        }
        return num;
    }

    displayDetails(item) {
        item.isOpen = !item.isOpen;
    }

    setCheckedItem(valeur, type, listFct) {
        // console.log(valeur);
        // console.log('le type',type);
        if (type == "checkall") {
            _.forEach(this.ListeFonctionnalites, function (value, key) {

                if (!valeur) {
                    value.isOpen = valeur;
                }

                value.checkedItem = valeur;

                if (value.datasChildren) {
                    _.forEach(value.datasChildren, function (valueChild, keyChild) {
                        valueChild.checkedItem = valeur;

                        _.forEach(valueChild.datasChildren, function (valueChildSecond, keyChild) {
                            valueChildSecond.checkedItem = valeur;
                        });

                    });
                }


            });
        }

        if (type == "parent") {
            _.forEach(listFct, function (value, key) {
                // console.log("la valeur",value);
                // console.log("la clé",key);

                value.checkedItem = valeur;

                if (value.datasChildren) {
                    _.forEach(value.datasChildren, function (valueChild, keyChild) {
                        valueChild.checkedItem = valeur;

                        _.forEach(valueChild.datasChildren, function (valueChildSecond, keyChild) {
                            valueChildSecond.checkedItem = valeur;
                        });

                    });
                }


            });
        }

    }

    cancelSearch() {
        this.itemToSearch = {};
        this.changeItermPerPage();
    }

    ngOnInit() {
        this.getData();
        this.getDataFonctionnalite();
        window.scrollTo(0, 0);
    }


}
