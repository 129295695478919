import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-search-localite',
  templateUrl: './search-localite.component.html',
  styleUrls: ['./search-localite.component.css']
})
export class SearchLocaliteComponent implements OnInit {

  itemToSave : any = {};
  oldValueSearchInput : String;
  currentPageSearch : number = 1;
  isPaginationChange : boolean = false;
  user : any = {};
  itemsPerPageSearch : number = 7;
  ListeSearchInput : Array<any> = [];
  totalItemsSearch : number;
  itemToSearch : any = {};
  currentSelected : any = {};
  busyGet: Subscription;

  constructor(private api: Api) { }

  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  @Input() defaultLocalite : any;
  @Input() title : any;
  @Input() isNotRequired : any;
  @Input() isClassLibelleWizard: any;
  @Input() disabled: boolean;
  
  // SEARCH INPUT FONCTION
  getDataSearch() {

    let search_value = this.itemToSave.searchInputSelected || null;

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        return;
      }

      if (this.oldValueSearchInput != search_value) {
        this.currentPageSearch = 1;
      }

      if (this.oldValueSearchInput == search_value && !this.isPaginationChange) {
        return
      }
      this.oldValueSearchInput = search_value;
    }

    var request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSave.searchInputSelected || null,
      },
      index: (this.currentPageSearch - 1),
      size: this.itemsPerPageSearch ? this.itemsPerPageSearch : null
    }
    this.selectedItem.emit(null);
    
    this.busyGet = this.api.post('localites/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(res);
          this.selectedValue({});
          if (res && res['items']) {
            this.ListeSearchInput = res['items'];
            this.totalItemsSearch = res['count'];
            this.itemToSearch.isDisplayTable = true;
            this.isPaginationChange = false;
          } else {
            this.ListeSearchInput = [];
            this.itemToSearch.isDisplayTable = false;
            this.totalItemsSearch = 0;
          }

        },
        err => {
        }
      );

  }

  pageChangedSearch(event: any): void {
    this.currentPageSearch = event.page;
    this.isPaginationChange = true;
    this.getDataSearch();
  }

  selectedItemSearch(item) {
    if (item) {
      this.currentSelected = Object.assign({}, item);
      this.itemToSave.searchInputSelected = this.currentSelected.libelle;
      this.itemToSearch.isDisplayTable = false;
      this.selectedValue(item);
    }
  }

  selectedValue(item) {

    this.itemToSearch.isDisplayTable = false;
    if (item && item.id) {
      this.selectedItem.emit(item);
    }else{
      this.selectedItem.emit({});
    }

  }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges) {

    if ((changes['defaultLocalite'] && changes['defaultLocalite'].currentValue)) {
      this.itemToSave.searchInputSelected = this.defaultLocalite;
    }
  }

}
