import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-main-parametre-motif-demande',
  templateUrl: './main-parametre-motif-demande.component.html',
  styleUrls: ['./main-parametre-motif-demande.component.css']
})
export class MainParametreMotifDemandeComponent implements OnInit {

 
  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  defaultEndPoint = 'motifDemandes'

  config = {
    animated: false,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15;
    this.currentItemChecked = {};
    this.itemToSearch = {};
  }

  
  checkPermission(code1,code2,code3)
  {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;

  }


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);

    }
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));

  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData(isRefresh ?) {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if(!isRefresh) {
      if (search_value && this.oldValueSearch) {
        if (search_value != this.oldValueSearch) {
          this.currentPage = 1;
        }
      }
  
      if (search_value) {
        if (search_value.length > 0 && search_value.length < 3) {
          if (!this.isChangeItermPerPage) {
            return;
          }
        }
        else {
          if (search_value == this.oldValueSearch) {
            return;
          }
          this.oldValueSearch = search_value;
          //this.currentPage = 1;
        }
      }
      else {
        if (this.oldValueSearch == "" && isSearch) {
          return;
        }
  
        this.oldValueSearch = "";
      }
  
    }
   
    var request = {
      user: this.user.proxy,
      data: {
        libelle: this.itemToSearch.fullText || null,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );

  }


  confirmDeleteItem(obj) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        // obj.id = 1020;
        this.deleteItem(obj);
      }
    })

  }

  cancelSave() {
    this.itemToSave = {};
  }


  confirmSave(item) {
    if (!item || !item.libelle ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les champs obligatoires.")
      return;
    }

    swal({
      title: !item.id ? 'Enregistrer un motif' : 'Modifier un motif',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post(this.defaultEndPoint+'/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Motif", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData(true);
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/delete', request)
      .subscribe(
        res => {
          console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression",  this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData(true);
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }


}
