import { Component, OnInit, ViewChild, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { ModelService } from '../../../shared/utils/model.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
import * as moment from 'moment';
const swal = require('sweetalert2');
import { ActivatedRoute } from '@angular/router';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';

@Component({
  selector: 'app-main-historique-connexion',
  templateUrl: './main-historique-connexion.component.html',
  styleUrls: ['./main-historique-connexion.component.css']
})
export class MainHistoriqueConnexionComponent implements OnInit {

 
  results: Array<any> = [];
    ListeFichierImporterToSave: Array<any> = [];
    busyGet: Subscription;
    refreshValueIndice: number = 1;
    itemPerPagePagination: number = 10;
    itemImport: any = {};
    busySave: Subscription;
    itemToSave: any = {};
    totalItems = 0;
    currentPage = 1;
    user: any;
    itemsPerPage: any;
    itemToSearch: any;
    oldValueSearch: any;
    isChangeItermPerPage: boolean;
    daterange : any = {};

    config = {
        animated: true,
        keyboard: false,
        backdrop: 'static',
        ignoreBackdropClick: true
    };

    options: any = {
        locale: { format: 'DD/MM/YYYY' },
        alwaysShowCalendars: false,
        applyLabel: "Valider"
    };

    @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

    constructor(private api: Api, private route: ActivatedRoute, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig) {

        this.user = this.userService.getCurrentUser();
        this.itemsPerPage = 15;
        this.itemToSearch = {
            dateDebut: moment(new Date(2021, 11, 27)).format("DD/MM/YYYY"),
            dateFin: moment(new Date()).format("DD/MM/YYYY")
        };
        this.getData();
        this.reinitDateSearch();
    }

    reinitDateSearch() {

        let tabDateDuJour = this.user.dateDuJour.split(" ")[0].split("/");
        const newDateDuJour = new Date(tabDateDuJour[2], tabDateDuJour[1] - 1, tabDateDuJour[0]);

        this.daterangepickerOptions.settings = {
            locale: {
                format: 'DD-MM-YYYY',
                cancelLabel: 'Annuler',
                applyLabel: 'Rechercher',

                "customRangeLabel": "Personnaliser",
                "daysOfWeek": [
                    "Dim",
                    "Lun",
                    "Mar",
                    "Mer",
                    "Jeu",
                    "Ven",
                    "Sam"
                ],
                "monthNames": [
                    "Janvier",
                    "Fevrier",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Aout",
                    "Septembre",
                    "Octobre",
                    "Novembre",
                    "Decembre"
                ],
            },
            alwaysShowCalendars: false,
            ranges: {
                "Aujourd'hui": moment(newDateDuJour),
                "La journée d'hier": [moment(newDateDuJour).subtract(1, 'day'), moment().subtract(1, 'day')],
                'Les 03 derniers jours': [moment(newDateDuJour).subtract(2, 'day'), moment()],
                'Les 07 derniers jours': [moment(newDateDuJour).subtract(6, 'day'), moment()],
                'Les 15 derniers jours': [moment(newDateDuJour).subtract(14, 'day'), moment()],
                'Les 30 derniers jours': [moment(newDateDuJour).subtract(29, 'day'), moment()],
                'Les 60 derniers jours': [moment(newDateDuJour).subtract(59, 'day'), moment()],
                'Les 90 derniers jours': [moment(newDateDuJour).subtract(89, 'day'), moment()],
                'Les 06 derniers mois': [moment(newDateDuJour).subtract(6, 'month'), moment()],
                'Les 12 derniers mois': [moment(newDateDuJour).subtract(12, 'month'), moment()],
                "Toutes les connexions": [new Date(2021, 11, 27), moment(newDateDuJour)],
            }
        };

    }

   
    selectedDate(value: any, datepicker?: any) {

        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        this.currentPage = 1;
        // or manupulat your own internal property
        this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
        this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');

        this.daterange.label = value.label;
        this.oldValueSearch = null;
        this.itemToSearch.lunchGetData = true;

        if (this.daterange.label == 'Toutes les connexions') {
            this.itemToSearch.dateDebut = null;
            this.itemToSearch.dateFin = null;
        }

        this.getData();
    }


    pageChanged(event: any): void {
        this.currentPage = event.page;
        this.oldValueSearch = "";
        this.isChangeItermPerPage = true;
        this.getData();
    }


    getData() {

        let search_value = this.itemToSearch.fullText;
        let isSearch = this.itemToSearch.fullText ? true : false;

        if (search_value && this.oldValueSearch) {
            if (search_value != this.oldValueSearch) {
                this.currentPage = 1;
            }
        }

        if (search_value) {
            search_value = search_value.trim();

            if (search_value.length > 0 && search_value.length < 3 && !this.itemToSearch.lunchGetData) {
                if (!this.isChangeItermPerPage) {
                    return;
                }
            }
            else {
                if (search_value == this.oldValueSearch) {
                    return;
                }
                this.oldValueSearch = search_value;
                //this.currentPage = 1;
            }
        }
        else {
            if (this.oldValueSearch == "" && isSearch) {
                return;
            }
            this.oldValueSearch = "";
        }

        var request = {
            user: this.user.proxy,
            data: {
                searchFullText: this.itemToSearch.fullText || null,
                createdAt: null,
                createdAtParam: null
            },
            index: (this.currentPage - 1),
            size: this.itemsPerPage ? this.itemsPerPage : null
        }

        if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
            request.data.createdAt = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
            request.data.createdAtParam =
                {
                    "operator": "[]",
                    "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
                    "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
                }
        }

        this.reinitDateSearch();

        this.busyGet = this.api.post('historiqueConnexions/getByCriteria', request)
            .subscribe(
                res => {
                    console.log("historique de connexion", res);
                    this.itemToSearch.lunchGetData = false;

                    if (!res['hasError']) {
                        if (res && res['items']) {
                            this.isChangeItermPerPage = false;

                            this.results = res['items'];
                            this.totalItems = res['count'] ? res['count'] : 0;
                        } else {
                            this.results = [];
                            this.totalItems = 0;
                        }
                    }
                    else {
                        if (res['status'] && res['status']['message']) {
                            this.utilities.checkDeconnectionUser(res);
                            this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                        }
                    }


                },
                err => {
                    console.log("Error occured", err);
                    this.notificationService.showMessageError("Connexion", this.notificationService.defaultMessage());
                    // this.not
                }
            );

    }


    getExactlyNumberRow(page, index) {
        let num = index + 1;
        if (page > 1) {
            num = ((page - 1) * this.itemsPerPage) + (index + 1);
        }
        return num;
    }

    resetDate() {
        this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
        this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
    }

    ngAfterViewInit() {
        this.resetDate();
    }

    ngOnInit() {
        this.getData();
        window.scrollTo(0, 0);
    }

}
