import {Component, ViewContainerRef, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormReconnectComponent } from './+components/component-app/form-reconnect/form-reconnect.component';
import { UserService } from './shared/user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { enumKeyCrypto_AES } from './shared/utils/enumerator';
import { UtilitiesService } from './shared/utils/utilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls : []
  // template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit{
  public title = 'app works!';
  activite_detectee : boolean; 
  nombreSecondes :  number;
  intervalId : number;
  tempsInactivite :  number = 1*60;
  popUpHasLunch : boolean = false;
  pageActive : string;
  user : any = {};
  bsModalRef : BsModalRef;
  private config:any = {};
  
  constructor(private viewContainerRef: ViewContainerRef,private route: ActivatedRoute,router: Router,private userService: UserService,private modalService: BsModalService, private utilities: UtilitiesService) {
    // console.log("title",this.title);
    this.nombreSecondes = this.tempsInactivite;
    this.user = this.userService.getCurrentUser(); 
  }

  // getActivityCurseur()
  // {
  //   // console.log("activité decteté");
  //   this.nombreSecondes = this.tempsInactivite;

  //   if(!this.popUpHasLunch)
  //   {
  //     this.popUpHasLunch = false;
  //     setTimeout(() => {
  //       this.counterDeconnection();
  //     }, 1000);
  //   }
  //   else
  //   {
  //     console.log("Le popup est deja lancé ") 
  //   }
  
  //   //On reinitialise la variable de counter
  // }


  // counterDeconnection(): void {

  //   this.clearTimer();

  //   this.intervalId = window.setInterval(() => {
  //     this.nombreSecondes -= 1;
  //     // console.log("this.nombreSecondes ",this.nombreSecondes);
  //     if (this.nombreSecondes === 0) {

  //       this.popUpHasLunch = true;
  //       this.clearTimer(); // Supprime le set intervalle

  //       //On lance le pop-up et on vide les variable de session
  //       console.log("on lance le popup et on vide les variables de sessions ");
  //     } 
  //   }, 1000);

  // }

  // clearTimer()
  // {
  //   if(this.intervalId)
  //   {
  //     clearInterval(this.intervalId);
  //   }
    
  // }

  // openPasswordForm() {
  //   this.popUpHasLunch = true;
  //   this.bsModalRef = this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }));
  //   this.bsModalRef.content.currentUser = this.user;
  //   let refreshTimout = this.modalService.onHide.subscribe(r=>{
  //       this.nombreSecondes = this.tempsInactivite;
  //       this.popUpHasLunch = false;
  //       this.counterDeconnection(); 
  //   });
  // }


  ngOnInit() {
    // console.log('activite_detectee',this.activite_detectee);

    // this.pageActive = this.route.snapshot;
    // console.log("this.pageActive",this.route.snapshot);
    // let isAuthPage = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.deconnectionTimeAuthPage));

    // if(!isAuthPage) 
    // {
    //   //this.counterDeconnection();
    // }
   
    window.scrollTo(0, 0);
  }

}
