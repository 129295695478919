import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../shared/utils/notification.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { enumKeyCrypto_AES } from '../../shared/utils/enumerator';

import { ActivatedRoute, Router } from '@angular/router';
import {  BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormReinitPasswordComponent } from '../../+components/form-reinit-password/form-reinit-password.component';
import { AutoLogoutService } from '../../shared/layout/auto-logout/auto-logout.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls : ['./login.css'],
  providers: [AutoLogoutService]
})
export class LoginComponent implements OnInit {

  currentPositionGps: any = {};
  itemNewOtp : any = {};
  itemLogin : any = {};
  itemToSave: any = {};
  currentUser :  any = {};
  bsModalRef: BsModalRef;
  busyConnecte: Subscription; 
  code : any;
  intervalId : number;
  seconds : number = 120;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  isConnected = true;  
  noInternetConnection: boolean; 

  constructor(private api: Api, public router: Router, private route: ActivatedRoute, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private _localeService: BsLocaleService, private autoLogoutService : AutoLogoutService) {

    this.itemToSave = { 
      canValidateSms : false 
    };

    sessionStorage.removeItem(enumKeyCrypto_AES.pasDeConnexionInternet); 
  }

  ngOnInit() {

    //Setter une variable de session qui montre que nous sommes sur la page de login
    sessionStorage.setItem(enumKeyCrypto_AES.deconnectionTimeAuthPage, this.utilities.encryptData("true"));
    sessionStorage.removeItem(enumKeyCrypto_AES.keyStoreAppAction); //Variable mouseover


    this.autoLogoutService.clearInterval();
  }

  openChangePassword(user) {
    const initialState = {
      title: "Changer votre mot de passe.",
      config: this.config
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRef.content.title = 'Changer votre mot de passe';
    this.bsModalRef.content.currentUser = user;
  } 


  login(item)
  {

    if(!item.login) {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le login !");
      return;
    };

    if(!item.password) {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le mot de passe !");
      return;
    };

    this.itemToSave.showLoader = true;

    var request = {
      user: "1",
      "data":
        {
          login: item.login,
          password: item.password,
          source: "Web",
          referenceSource: navigator.userAgent,
          positionGps: (this.currentPositionGps && this.currentPositionGps.positionGps) ? this.currentPositionGps.positionGps : "0,0"
        } 
    }

    this.busyConnecte = this.api.post('utilisateurs/login', request)
      .subscribe(
        res => {
          item.showLoader = false;
          if (!res['hasError'] && res['items']) {

            let currentUser = res['items'][0];
            sessionStorage.setItem(enumKeyCrypto_AES.loginUserEncrypt, this.utilities.encryptData(currentUser));

            if (currentUser.resetPassword) {
              this.itemToSave = {};
              currentUser.isFromLogin = true;
              this.openChangePassword(currentUser);
              return;
            }

            this.router.navigate(['/dashbord']);
          }
          else {
            this.notificationService.showMessageError("Connexion", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.itemToSave.showLoader = false;
          }
        },
        err => {
          this.notificationService.defaultMessage();
          this.itemToSave.showLoader = false;
        }
      );
  }
  

  motDePassOublie(item) {

    item.showLoader = true;
    if (!item || !item.libellePassForget) {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner votre login, email ou contact !");
      return;
    }

    var request = {
      "datas": [
        {
          "login": item.libellePassForget
        }
      ]
    }

    this.busyConnecte = this.api.post('utilisateurs/motDePasseOublie', request)
      .subscribe(
        res => {
          item.showLoader = false;
          if (!res['hasError'] && res['items']) {
            this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
            this.notificationService.showMessageSucces("Mot de passe oublié", this.itemToSave.message);

            setTimeout(() => {
              this.updatePassWord();
            }, 10000);
          }
          else {
            this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
            this.notificationService.showMessageError("Connexion", res['status'].message);
          }
        },
        err => {
          item.showLoader = false;
          this.notificationService.defaultMessage()
        }
      );
  }


  updatePassWord() {
    this.itemToSave.isPasswordForget = !this.itemToSave.isPasswordForget;
  }
 
  getPositionGps() {
    this.currentPositionGps.positionGps = '0,0';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
       
        if (position && position.coords) {
          this.currentPositionGps.positionGps = position.coords.latitude + "," + position.coords.longitude;
        }
        
      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }


  ngOnDestroy()
  {
    this.autoLogoutService.clearInterval();
    //Supprimer la variable de ssession qui indique que nous sommes sur la page de connexion
    sessionStorage.removeItem(enumKeyCrypto_AES.deconnectionTimeAuthPage);
  }



}
