import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');

@Component({
  selector: 'app-stock-commissariat',
  templateUrl: './stock-commissariat.component.html',
  styleUrls: ['./stock-commissariat.component.css']
})
export class StockCommissariatComponent implements OnInit {

  defaultLocalite : any;
  isSearchStockCritique : boolean = false;
  totalFeuilletA5Restant : any;
  currentLocalite : any = {};
  itemApprovisionnement : any = {};
  results: Array<any> = [];
  ListeCommissariats: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  defaultEndPoint = 'commissariats'

  config = {
    animated: false,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15;
    this.currentItemChecked = {};
    this.itemToSearch = {};
  }

  
  checkPermission(code1,code2,code3)
  {
    let returnValue = null;
    let ListeFonctionnalites = this.user.fonctionnalites;
    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    return returnValue;
  }


  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {
      this.itemToSave = Object.assign({}, itemToModified);
      this.itemApprovisionnement.commissariatSelected = {...itemToModified};
    }
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData(isRefresh ?) {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if(!isRefresh) {
      if (search_value && this.oldValueSearch) {
        if (search_value != this.oldValueSearch) {
          this.currentPage = 1;
        }
      }
  
      if (search_value) {
        if (search_value.length > 0 && search_value.length < 3) {
          if (!this.isChangeItermPerPage) {
            return;
          }
        }
        else {
          if (search_value == this.oldValueSearch) {
            return;
          }
          this.oldValueSearch = search_value;
        }
      }
      else {
        if (this.oldValueSearch == "" && isSearch) {
          return;
        }
        this.oldValueSearch = "";
      }
  
    }

    let isStockCritique = null;
    
    if(this.itemToSearch.stockCritique == 'false') isStockCritique = false;
    if(this.itemToSearch.stockCritique == 'true') isStockCritique = true;

    this.isSearchStockCritique = isStockCritique;
    
    console.log(" this.isSearchStockCritique ",this.isSearchStockCritique);
    
    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: this.itemToSearch.fullText || null,
        stockCritique : isStockCritique
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post(this.defaultEndPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.totalFeuilletA5Restant =  res['totalFeuilletA5Restant'] ? res['totalFeuilletA5Restant'] : 0;
            this.isChangeItermPerPage = false;
          } else { 
            this.results = [];
            this.totalFeuilletA5Restant = 0;
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );
  }

  cancelSave() {
    this.itemToSave = {};
    this.defaultLocalite = null;
    this.currentLocalite = {};
    this.itemApprovisionnement = {
      commissariatSelected : {
        localiteDto : {}
      }
    };
  }

  confirmSave(item) {

    if (!item || !item.commissariatSelected ) {
      this.notificationService.showMessageError("Information", "Veuillez sélectionner un commissariat.")
      return;
    }

    if (!item.quantite ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les quantités.")
      return;
    }

    if (item.id && !item.motif ) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner le motif.")
      return;
    }

    swal({
      title: !item.id ? 'Enregistrer un approvisionnement' : 'Modifier un approvisionnement',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        item.commissariat = item.commissariatSelected.id;

        this.saveItem({
          id : item.id,
          commissariat : item.commissariatSelected.id,
          quantite : item.quantite
        });

      } 
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('approvisionnements/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Approvisionnement", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData(true); 
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }
}
