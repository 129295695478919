import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'app-main-parametres',
  templateUrl: './main-parametres.component.html',
  styleUrls: ['./main-parametres.component.css']
})
export class MainParametresComponent implements OnInit {
  
  tabActive : number = 0;
  user : any = {};
  constructor(public router:Router,private modalService: BsModalService,private userService: UserService) { 
    this.user = this.userService.getCurrentUser();
  }


  checkPermission(code1,code2,code3)
  {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;

  }

  ngOnInit() {
    this.userService.getStateRoutageAdministration(false); 
  }

}
