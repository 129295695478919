import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { Api } from '../../../shared/utils/api/api';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../shared/user';

@Component({
  selector: 'app-form-paiement-cinetpay',
  templateUrl: './form-paiement-cinetpay.component.html',
  styleUrls: ['./form-paiement-cinetpay.component.css']
})
export class FormPaiementCinetpayComponent implements OnInit {

 
  @Input() urlPaiementCinetPay: any;
  urlPaiementHasReady : boolean = false;

  finalLink : any;

  constructor(public sanitizer: DomSanitizer, private api: Api, public bsModalRefCinetPay: BsModalRef, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
      // this.user = this.userService.getCurrentUser();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.urlPaiementCinetPay = localStorage.getItem("current_payment_url");
      this.finalLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPaiementCinetPay);

      console.log( " this.urlPaiementCinetPay ",this.urlPaiementCinetPay);
      
      this.urlPaiementHasReady = true;
    }, 100);
  }


  checkNotifyPaiement() {

    console.log(" sessionStorage.getItem(", sessionStorage.getItem("transaction_id"));

    var request = {
      "cpm_trans_id": sessionStorage.getItem("transaction_id")
    }

    this.api.post('certificatResidences/notify-paiement', request)
      .subscribe(
        (res: any) => {
          console.log("Appel effectué ", res);
          this.bsModalRefCinetPay.hide();
          this.refreshApp();
        },
        err => { 
          this.bsModalRefCinetPay.hide();
          this.refreshApp();
        }
      );
  }

  refreshApp() {
    window.location.reload();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.urlPaiementCinetPay = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPaiementCinetPay);
    //   this.urlPaiementHasReady = true;
    // }, 10);
  }

}
