import { Component, OnInit } from '@angular/core';
import { FormReconnectComponent } from '../../../../+components/component-app/form-reconnect/form-reconnect.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UserService } from '../../../user';
import { Api } from '../../../utils/api/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { enumKeyCrypto_AES, enumProxy } from '../../../utils/enumerator';

@Component({
  selector: 'app-main-layout-sidebar',
  templateUrl: './main-layout-sidebar.component.html',
  styleUrls: ['./main-layout-sidebar.component.css']
})
export class MainLayoutSidebarComponent implements OnInit {

  user: any;
  currentUrlActive: any;
  deconnectionTimer: number;
  bsModalRef: BsModalRef;
  intervalle = 1;
  countTer = 0;
  activite_detectee: boolean;
  logoutModalIsLunch: boolean = false;
  lunchFonction: any;
  routeMenuHover: string;
  isAgentLink: boolean;
  enumLibelleProxy: any = {};
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private activedRoute: ActivatedRoute, private userService: UserService, public router: Router, private api: Api, private modalService: BsModalService) {

    this.user = this.userService.getCurrentUser();
    this.enumLibelleProxy = enumProxy;
  }


  checkPermission(code1, code2, code3, code4) {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2 || element.code == code3 || element.code == code4));

    if (itemFonctionnalite) {
      returnValue = true;
    }

    return returnValue;

  }

  getRouterActive(isAgentLink) {
    this.isAgentLink = isAgentLink;
  }



  // openPasswordForm() {
  //   this.logoutModalIsLunch = true;
  //   this.bsModalRef = this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }));
  //   this.bsModalRef.content.currentUser = this.user;
  //   let refreshTimout = this.modalService.onHide.subscribe(r=>{
  //       this.countTer = 0;
  //       this.logoutModalIsLunch = false;
  //       this.activite_detectee = true; 
  //       window.clearTimeout(this.lunchFonction);
  //       this.initTimoutActivityScreem();
  //   });
  // }

  //   // On crée la fonction qui teste toutes les x secondes l'activité du visiteur via activite_detectee
  //   getActiviteInScream() {

  //     if(this.activite_detectee)
  //     {
  //           this.activite_detectee = false; 
  //           this.countTer = 0;
  //     }
  //     else
  //     {
  //           this.countTer = this.countTer + this.intervalle;

  //           if(this.countTer == this.deconnectionTimer)
  //           {
  //               if(this.logoutModalIsLunch)
  //               {
  //                 this.countTer = 0;
  //                 window.clearTimeout(this.lunchFonction);
  //                 this.logoutModalIsLunch = false;
  //                 return;
  //               }

  //               // Deconnecter l'utilisateur
  //               //this.openPasswordForm();
  //               // sessionStorage.removeItem("yz-backoffice-app-oneci");
  //               //sessionStorage.removeItem(enumKeyCrypto_AES.loginUserEncrypt);
  //               //this.deconnection();

  //               window.clearTimeout(this.lunchFonction);
  //               this.countTer = 0;
  //               return;
  //           }
  //           else
  //           {
  //             if(this.logoutModalIsLunch)
  //             {
  //               this.countTer = 0;
  //               window.clearTimeout(this.lunchFonction);
  //               this.logoutModalIsLunch = false;
  //               return;
  //             }
  //           }
  //     }

  //     this.lunchFonction = setTimeout(() => {
  //        this.getActiviteInScream()
  //     }, 1000);

  // }

  getRouteActive() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log(" event.url ", event.url);
        this.currentUrlActive = event.url;
        console.log(" currentUrlActive ", this.currentUrlActive);
      }
    });
  }


  ngOnInit() {
    let router: any = this.activedRoute.snapshot;
    this.currentUrlActive = router['_routerState']['url'];
    this.getRouteActive();
  }

  ngOnDestroy() {
    window.clearTimeout(this.lunchFonction);
  }

}
