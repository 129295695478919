import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserService} from "../../shared/user/user.service";
import {Api} from '../../shared/utils/api/api';
import {NotificationService} from '../../shared/utils/notification.service';
import { Subscription } from 'rxjs';
import {Router} from "@angular/router";
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { enumKeyCrypto_AES } from '../../shared/utils/enumerator';

@Component({
  selector: 'app-form-reinit-password',
  templateUrl: './form-reinit-password.component.html',
  styleUrls: ['./form-reinit-password.component.css']
})
export class FormReinitPasswordComponent implements OnInit {

  itemToSave : any;
  user : any;
  busySave : Subscription;
  currentUser : any = {};

  constructor(public bsModalRef: BsModalRef,private api:Api,private notificationService : NotificationService,private userService: UserService,private router: Router,private utilities : UtilitiesService) {
    this.itemToSave = {};
    this.user = this.userService.getCurrentUser();
   }


   saveItem(item)
   {
       this.itemToSave.showLoaderUpdatePassword = true;

       if(!item || !item.password || !item.newpassword)
       {
           this.itemToSave.showLoaderUpdatePassword = false;
           this.notificationService.showMessageError("Information","Veuillez renseigner l'ancien et le nouveau mot de passe.")
           return;
       }
   
       if(item.ConfirmNewPassword != item.newpassword)
       {
         this.itemToSave.showLoaderUpdatePassword = false;
         this.notificationService.showMessageError("Information","Veuillez confirmer votre mot de passe.")
         return;
       }
   
       item.proxy = this.itemToSave.proxy  || this.user.proxy;
      
       var request = {
         user : this.itemToSave.proxy || this.user.proxy,
         "datas" : [item]
       }
   
       this.busySave = this.api.post('utilisateurs/changePassword', request)
           .subscribe(
               res => {
                   this.itemToSave.showLoaderUpdatePassword = false;
                   if(!res['hasError'])
                   {
                       this.notificationService.showMessageSucces("Mot de passe",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                     
                       this.bsModalRef.hide();
                       var currentUser = res['items'][0];
                      
                       if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                       {
                         currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                       }

                      // sessionStorage.setItem(enumKeyCrypto_AES.loginUserEncrypt, this.utilities.encryptData(currentUser));
           
                       if(this.currentUser.isFromLogin)
                       {
                        this.router.navigate(['/dashbord']);
                       }
   
                   }else{
                     if(res['status'] && res['status']['message'])
                     {
                       this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                     }
                   }
               },
               err => {
                  this.itemToSave.showLoaderUpdatePassword = false;
                  this.notificationService.defaultMessage()
               }
           );
   }
   

  ngOnInit() {
  }

}
