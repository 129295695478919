import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/user';
import { Api } from 'app/shared/utils/api/api';
import { NotificationService } from 'app/shared/utils/notification.service';
import { UtilitiesService } from 'app/shared/utils/utilities.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import * as _ from "lodash";
import { ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
const swal = require('sweetalert2');
import * as moment from 'moment';
import { enumKeyCrypto_AES } from 'app/shared/utils/enumerator';
import { listLocales, defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale } from 'ngx-bootstrap/locale';
import { FormPreviewPrintComponent } from '../form-preview-print/form-preview-print.component';
import { FormPaiementCinetpayComponent } from '../form-paiement-cinetpay/form-paiement-cinetpay.component';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-form-create-certificat',
  templateUrl: './form-create-certificat.component.html',
  styleUrls: ['./form-create-certificat.component.css']
})
export class FormCreateCertificatComponent implements OnInit {

  itemToSave: any = {};
  itemUpdateDocuement: any = {};
  itemDureeTraitement: any = {};
  setIntervalPerformance: any;
  performanceutilisateur: any = 0;
  currentFileJoint: any = {};
  currentFacture: any = {};
  currentFactureJoint: any = {};
  modalRefModal: BsModalRef;
  currentPieceIdentite: any = {};
  currentPieceIdentiteJoint: any = {};
  bsModalRefPreview: BsModalRef;
  linkPdfFile: String;
  stepWizard: any = 1;
  ListeMotifsDemandes: Array<any> = [];
  ListeDocumentATransmettreAuLecteur: Array<any> = [];
  ListeTypeDocuments: Array<any> = [];
  ListeDocumentsPieceIdentiteJoint: Array<any> = [];
  ListeDocumentsJointRecap: Array<any> = [];
  ListeJours: Array<any> = [];
  listFileDelete: Array<any> = [];
  bsModalRefCinetPay: BsModalRef;
  ListeDocumentFactures: Array<any> = [];
  ListeDocumentPiecesIdentite: Array<any> = [];
  ListeDocumentFacturesToSave : Array<any> = [];
  currentLocalite: any = {};
  currentMetier: any = {};
  currentNationalite: any = {};
  user: any = {};
  busyGet: Subscription;
  documentSelected: any;
  defaultLocalite: any;
  locale = 'fr';

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService, private localeService: BsLocaleService) {
    this.user = this.userService.getCurrentUser();

    // Renseigner la valeur par défaut de domicile
    if (this.user.commissariatDto && this.user.commissariatDto.localiteDto && this.user.commissariatDto.localiteDto.libelle) {
      this.itemToSave.domicile = this.user.commissariatDto.localiteDto.libelle + " ";
      this.itemToSave.domicilePrefixDomicie = this.user.commissariatDto.localiteDto.libelle;
    }

    this.localeService.use(this.locale);
    // On va recuperer le fichier a modifier
    this.route.params.subscribe(params => {

      if (params['isUpdateCertificat']) {
        // Il s'agit d'une modification du certificat
        // On recupere le localstorage et on crypte

        this.itemToSave = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.currentCertificatToUpdate));

        //Formater sa date de naissance
        if (this.itemToSave.dateDeNaissance) {
          let tabDateSplit = this.itemToSave.dateDeNaissance.split("/");

          this.itemToSave.dateDeNaissance = new Date(tabDateSplit[2], tabDateSplit[1] - 1, tabDateSplit[0]);
        }


        //Preselectionner le motif
        if (this.itemToSave.motif) {
          this.itemToSave.motifDemandeSelected = _.find(this.ListeMotifsDemandes, (o) => { return o.id == this.itemToSave.motif });;
        }

        if (this.itemToSave.piecesJustificatives) {

          // On separe la facture des autres documents
          // this.currentFactureJoint = _.find(this.itemToSave.piecesJustificatives, (o) => { return o.isFacture }) || {};

          this.ListeDocumentFacturesToSave = _.filter(this.itemToSave.piecesJustificatives, (o) => { return o.isFacture }) || [];
          this.ListeDocumentsPieceIdentiteJoint = _.filter(this.itemToSave.piecesJustificatives, (o) => { return !o.isFacture }) || [];
        }

        if (this.itemToSave.lieuResidence) {
          this.defaultLocalite = this.itemToSave.localitesLibelle;

          this.currentLocalite = {
            id: this.itemToSave.lieuResidence,
            libelle: this.itemToSave.localitesLibelle,
          }
        }

        if (this.itemToSave.nationalite) {
          this.currentNationalite = {
            id: this.itemToSave.nationalite,
            code: this.itemToSave.nationalitesCode,
            libelle: this.itemToSave.nationalitesLibelle,
          }
        }

        if (this.itemToSave.nationalite) {
          this.currentMetier = {
            id: this.itemToSave.profession,
            libelle: this.itemToSave.metiersLibelle
          }
        }

        if (this.itemToSave.moisResidence) {
          if (parseInt(this.itemToSave.moisResidence) < 10) {
            this.itemToSave.moisResidence = "0" + this.itemToSave.moisResidence;
          }
        }

        // Performance utilisateur et relancer paiement
        if (!this.itemToSave.relancerPaiement && !this.itemToSave.isRelancerPaiement) {
          this.performanceutilisateur = parseInt(this.itemToSave.performanceUtilisateur);
        } else {
          this.gotoNexStep(3, false);
        }

      }
    });

  }

  maxDate = new Date();

  getPerformanceUtilisateur() {

    this.setIntervalPerformance = window.setInterval(() => {
      this.performanceutilisateur = this.performanceutilisateur + 1;

      if (this.performanceutilisateur >= this.user.dureeMoyenneDeclarationCertificat) {
        this.itemDureeTraitement.isCritique = true; // Cette valeur c'est pr savoir si nous devrons mettre la valeur en rouge
      } else {
        this.itemDureeTraitement.isCritique = false;
      }

      let formattedPerformance = moment.utc(this.performanceutilisateur * 1000).format('mm:ss');

      if (formattedPerformance && this.performanceutilisateur > 60) {
        formattedPerformance = formattedPerformance.replace(":", " min ");

        formattedPerformance = formattedPerformance + " sec";
      } else {
        let splitByTowPoint = formattedPerformance.split(":");

        if (splitByTowPoint && splitByTowPoint.length > 0) {
          formattedPerformance = splitByTowPoint[1] + " sec";
        }
      }

      this.itemDureeTraitement.valeurAffiches = formattedPerformance;
    }, 1000);

  }

  getJour() {
    for (let index = 1; index < 32; index++) {

      let jour = index > 9 ? index : 0 + index;
      this.ListeJours.push(jour);
    }
  }

  localiteSlected($event) {

    if ($event && $event.id) {
      this.currentLocalite = { ...$event };
      this.itemToSave.domicile = this.currentLocalite.libelle + " ";
      this.itemToSave.domicilePrefixDomicie = this.currentLocalite.libelle;
      this.itemToSave.lieuResidence = this.currentLocalite.id;
      this.defaultLocalite = this.currentLocalite.libelle;
    } else {
      this.currentLocalite = {};
      this.itemToSave.domicile = "";
      this.itemToSave.domicilePrefixDomicie = "";
      this.itemToSave.lieuResidence = "";
      this.defaultLocalite = ""
    }

  }

  getDataMotifDemande() {

    var request = {
      user: this.user.proxy,
      data: {}
    }

    this.busyGet = this.api.post('motifDemandes/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(res);
          if (res && res['items']) {
            this.ListeMotifsDemandes = res['items'];
            //Preselectionner le motif si nous somes en modification
            if (this.itemToSave.motif) {
              this.itemToSave.motifDemandeSelected = _.find(this.ListeMotifsDemandes, (o) => { return o.id == this.itemToSave.motif });;
            }
          } else {
            this.ListeMotifsDemandes = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );

  }

  getDataTypeDocument() {

    var request = {
      user: this.user.proxy,
      data: {}
    }

    this.busyGet = this.api.post('categoriePieceJointes/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.ListeTypeDocuments = res['items'];

            this.ListeDocumentFactures = _.filter(res['items'], (o) => { return o.isFacture; });
            this.ListeDocumentPiecesIdentite = _.filter(res['items'], (o) => { return !o.isFacture; });

          } else {
            this.ListeTypeDocuments = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );
  }

  gotoNexStep(indice, isGotoPrecedentWizard) {

    if (indice) {

      if (indice == 2) {

        if (!isGotoPrecedentWizard) //On fait un retour à l'etape precedente
        {
          // On fais les differents contrôl
          if (!this.itemToSave.civilite) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner une civilité !");
            return
          }

          if (!this.itemToSave.nom) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le nom !");
            return
          }

          if (!this.itemToSave.prenoms) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le prénoms !");
            return
          }

          if (!this.itemToSave.dateDeNaissance) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner la date de naissance !");
            return
          }

          if (!this.itemToSave.lieuDeNaissance) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le lieu de naissance !");
            return
          }

          if (this.user.commissariatDto && this.user.commissariatDto.isAgenceOneci) {
            if (!this.currentLocalite || !this.currentLocalite.id) {
              this.notificationService.showMessageError("Erreur", "Veuillez sélectionner la commune de résidence !");
              return
            }

          }

          if (!this.itemToSave.domicile) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner le domicile !");
            return
          } else {
            // On verifie si ce n'est pas uniquement le domicile qui est selectionné
            if (this.itemToSave.domicile) {

              let localiteInDomicile: any = {};

              if (this.user.commissariatDto && this.user.commissariatDto.isAgenceOneci) {
                localiteInDomicile = _.includes(this.itemToSave.domicile, this.currentLocalite.libelle);
              } else {
                localiteInDomicile = _.includes(this.itemToSave.domicile, this.user.commissariatDto.localiteDto.libelle);
              }

              if (!localiteInDomicile) {
                this.notificationService.showMessageError("Erreur", "Veuillez préceder l'intitulé du domicile par " + (!this.user.commissariatDto.isAgenceOneci ? this.user.commissariatDto.localiteDto.libelle : this.currentLocalite.libelle));
                return
              }

              // On verifie si ce n'est pas la localité seulement qui est renseigné dans domicile
              let domicileReplaceLocalite: any = {};

              if (this.user.commissariatDto && this.user.commissariatDto.isAgenceOneci) {
                domicileReplaceLocalite = this.itemToSave.domicile.replace(this.currentLocalite.libelle, "");
              } else {
                domicileReplaceLocalite = this.itemToSave.domicile.replace(this.user.commissariatDto.localiteDto.libelle, "");
              }

              if (!domicileReplaceLocalite || !domicileReplaceLocalite.trim()) {
                this.notificationService.showMessageError("Erreur", "Le domicile du client ne doit pas contenir uniquement " + (!this.user.commissariatDto.isAgenceOneci ? this.user.commissariatDto.localiteDto.libelle : this.currentLocalite.libelle));
                return
              }
            }
          }

          if ((!this.currentMetier || !this.currentMetier.id) && !this.user.commissariatDto.isAgenceOneci) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectiooner la profession !");
            return
          }

          if (!this.currentNationalite || !this.currentNationalite.id) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner une nationalité !");
            return
          }


          if (this.itemToSave.jourResidence && !this.itemToSave.moisResidence) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner le mois de résidence !");
            return
          }

          if (!this.itemToSave.anneeResidence) {
            this.notificationService.showMessageError("Erreur", "Veuillez renseigner l'année !");
            return
          } else {

            // Verifier si l'année de residence n'est pas superieur à la date du jour
            if (this.user.dateDuJour && this.user.dateDuJour.split("/")[2]) {
              let yearNowDate = this.user.dateDuJour.split("/")[2];

              if (parseFloat(yearNowDate) < parseFloat(this.itemToSave.anneeResidence)) {
                this.notificationService.showMessageError("Erreur", "Veuillez vérifier l'année de résidence ! <br>Elle ne doit pas être supérieure à la date du jour !");
                return
              }
            }

            // Verifier si l'année de residence n'est pas superieur à l'année de naissance
            // Nous avons deja fais le control de la date de naissance plus haut

            let dateFormatOperation = moment(this.itemToSave.dateDeNaissance).format("DD/MM/YYYY");
            if (dateFormatOperation == "Invalid date") {
              dateFormatOperation = this.itemToSave.dateDeNaissance;
            }
            let tabDate = dateFormatOperation.split("/");

            if (parseFloat(tabDate[2]) > parseFloat(this.itemToSave.anneeResidence)) {
              this.notificationService.showMessageError("Erreur", "L'année de résidence ne doit pas être inférieur à la date de naissance !");
              return
            } else {

              // Dans le cas ou l'année de residence est egale a l'année de naissance
              // Alors on compare les mois

              if (this.itemToSave.moisResidence && parseFloat(tabDate[2]) == this.itemToSave.anneeResidence) {

                if (parseFloat(tabDate[1]) > parseFloat(this.itemToSave.moisResidence)) {
                  this.notificationService.showMessageError("Erreur", "Veuillez renseigner un mois de residence valide !");
                  return
                } else {
                  // On verfie dans le cas ou le mois de residence est egal au mois de naissance
                  // Alors on verifie les jours

                  if (this.itemToSave.jourResidence && parseFloat(tabDate[1]) == parseFloat(this.itemToSave.moisResidence)) {

                    if (parseFloat(tabDate[0]) > parseFloat(this.itemToSave.jourResidence)) {
                      this.notificationService.showMessageError("Erreur", "Veuillez renseigner un jour de résidence valide !");
                      return
                    }
                  }
                }
              }
            }
          }


          if (!this.itemToSave.motifDemandeSelected) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectionner un motif de demande !");
            return
          }

          if (this.itemToSave.motifDemandeSelected && this.itemToSave.motifDemandeSelected.libelle == 'AUTRE') {
            if (!this.itemToSave.autreMotifDemande || !this.itemToSave.autreMotifDemande.trim()) {
              this.notificationService.showMessageError("Erreur", "Veuillez renseigner le motif de la demande !");
              return
            }
          }

          if ((!this.currentMetier || !this.currentMetier.id) && this.user.commissariatDto.isAgenceOneci) {
            this.notificationService.showMessageError("Erreur", "Veuillez sélectiooner la profession !");
            return
          }

          if (!this.itemToSave.contact) {
            this.notificationService.showMessageError("Erreur", "Veuillez préciser le contact !");
            return
          }

          if (this.itemToSave.contact.length < 10) {
            this.notificationService.showMessageError("Erreur", "Le contact doit être de 10 chiffres !");
            return
          }

        }
      }


      if (indice == 3 && !isGotoPrecedentWizard) {


        if (!this.ListeDocumentsPieceIdentiteJoint || !this.ListeDocumentsPieceIdentiteJoint.length) {
          this.notificationService.showMessageError("Erreur", "Veuillez joindre la pièce d'identité");
          return;
        }

        if(!this.ListeDocumentFacturesToSave || this.ListeDocumentFacturesToSave.length == 0) {
          this.notificationService.showMessageError("Erreur", "Veuillez joindre le justificatif de domicile !");
          return;
        }

        // Si aucune facture n'est ratachée
        // console.log(" this.currentFactureJoint ",this.currentFactureJoint);
        
        // if (!this.currentFactureJoint.id && (!this.currentFactureJoint || !this.currentFactureJoint.numeroDocument || !this.currentFactureJoint.base64PieceJointe)) {
        //   this.notificationService.showMessageError("Erreur", "Veuillez joindre le justificatif de domicile !");
        //   return;
        // }

        // this.ListeDocumentsJointRecap = Object.assign([], this.ListeDocumentsPieceIdentiteJoint);

        // if (this.currentFactureJoint && this.currentFactureJoint.numeroDocument) {
        //   this.ListeDocumentsJointRecap.push(this.currentFactureJoint);
        // }

        this.ListeDocumentsJointRecap = this.ListeDocumentsPieceIdentiteJoint.concat(this.ListeDocumentFacturesToSave);
        console.log("  this.ListeDocumentsJointRecap ", this.ListeDocumentsJointRecap);

      }

      this.stepWizard = indice;
    }
  }

  selectedMetier($event) {
    this.currentMetier = { ...$event };
  }

  selectedNationalite($event) {
    this.currentNationalite = { ...$event };
  }

  updateNaturePiece(isJustificationDomicile) {

    if (!this.itemUpdateDocuement.natureDocJustifUpdate || !this.itemUpdateDocuement.referenceDocUpdate) {
      this.notificationService.showMessageError("Erreur", "Veuillez renseigner les champs obligatoires !");
      return
    }

    if (!isJustificationDomicile) {
      let oldDocuementToUpdate = this.ListeDocumentsPieceIdentiteJoint[this.itemUpdateDocuement.indexOf];
      oldDocuementToUpdate.numeroDocument = this.itemUpdateDocuement.referenceDocUpdate;
      oldDocuementToUpdate.idCategoriePieceJointe = this.itemUpdateDocuement.natureDocJustifUpdate.id;
      oldDocuementToUpdate.categoriePieceJointesLibelle = this.itemUpdateDocuement.natureDocJustifUpdate.libelle;
      oldDocuementToUpdate.libelleDocument = this.itemUpdateDocuement.natureDocJustifUpdate.libelle;
    } else {

      let oldDocumentFacture = this.ListeDocumentFacturesToSave[this.itemUpdateDocuement.indexOf];
      oldDocumentFacture.numeroDocument = this.itemUpdateDocuement.referenceDocUpdate;
      oldDocumentFacture.idCategoriePieceJointe = this.itemUpdateDocuement.natureDocJustifUpdate.id;
      oldDocumentFacture.categoriePieceJointesLibelle = this.itemUpdateDocuement.natureDocJustifUpdate.libelle;
      oldDocumentFacture.libelleDocument = this.itemUpdateDocuement.natureDocJustifUpdate.libelle;
    }

    this.itemUpdateDocuement = {};
    this.modalRefModal.hide();
  }

  openFormModalUpdateTypePiece(template, documentSelected, isJustificationDomicile) {

    this.itemUpdateDocuement = { ...documentSelected };

    this.itemUpdateDocuement.indexOf = this.ListeDocumentsPieceIdentiteJoint.indexOf(documentSelected);
   

    // Preselectionner le document selectionné
    if (this.itemUpdateDocuement.idCategoriePieceJointe) {

      if (!isJustificationDomicile) {
        this.itemUpdateDocuement.natureDocJustifUpdate = _.find(this.ListeDocumentPiecesIdentite, (o) => { return o.id == this.itemUpdateDocuement.idCategoriePieceJointe });
      } else {
        this.itemUpdateDocuement.natureDocJustifUpdate = _.find(this.ListeDocumentFactures, (o) => { return o.id == this.itemUpdateDocuement.idCategoriePieceJointe });
      }

      this.itemUpdateDocuement.referenceDocUpdate = this.itemUpdateDocuement.numeroDocument;
    }

    this.itemUpdateDocuement.isJustificationDomicile = isJustificationDomicile;
    this.modalRefModal = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-sm' }));
  }

  openFormModal(template, documentSelected, isRecapFile) {

    this.documentSelected = documentSelected;

    if (isRecapFile) {
      this.ListeDocumentATransmettreAuLecteur = this.ListeDocumentsPieceIdentiteJoint;
    } else {
      this.ListeDocumentATransmettreAuLecteur = this.ListeDocumentsJointRecap;
    }

    this.ListeDocumentATransmettreAuLecteur.forEach(doc => {

      if (doc && doc.url && !doc.allFileBase64) {
        let splitUrlDoc = doc.url.split(".");

        if (splitUrlDoc.length > 0 && splitUrlDoc[splitUrlDoc.length - 1].toLowerCase() == 'pdf') {
          doc.isPdf = true;

        } else {
          doc.isPdf = false;
        }
      } else {
        if (doc && doc.allFileBase64) {
          if (doc.extensionPieceJointe.toLowerCase() == 'pdf') doc.isPdf = true;

          doc.url = doc.allFileBase64;
        }
      }

    });

    console.log(" this.ListeDocumentATransmettreAuLecteur ", this.ListeDocumentATransmettreAuLecteur);


    this.modalRefModal = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md  modal-width-65' }));
  }

  openFormPreviewFactureModal(template,indice) {

    this.ListeDocumentATransmettreAuLecteur = [];
    this.ListeDocumentATransmettreAuLecteur = this.ListeDocumentFacturesToSave; //.push(this.currentFactureJoint);

    this.documentSelected = this.ListeDocumentFacturesToSave[indice];

    this.ListeDocumentATransmettreAuLecteur.forEach(doc => {
      if (doc && doc.url && !doc.allFileBase64) {
        let splitUrlDoc = doc.url.split(".");

        if (splitUrlDoc.length > 0 && splitUrlDoc[splitUrlDoc.length - 1].toLowerCase() == 'pdf') {
          doc.isPdf = true;

        } else {
          doc.isPdf = false;
        }
      } else {
        if (doc && doc.allFileBase64) {
          if (doc.extensionPieceJointe.toLowerCase() == 'pdf') {
            doc.isPdf = true;
          }
          doc.url = doc.allFileBase64;
        }
      }
    });

    this.modalRefModal = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md  modal-width-65' }));
  }

  //********************************* */
  // Gestion des fichiers             //
  //********************************* */

  gotoUpdloadFacture() {

    //Dans le cas ou nous sommes en ajout
    if (!this.currentFacture.typeDocumentSelected) {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner le type de document.");
      return;
    }


    //Ouvrir la boite de dialogue
    let inputFile = document.getElementById("file-document");
    if (inputFile) {
      inputFile.click();
    }

  }

  gotoUpdloadPieceIdentite() {

    //Dans le cas ou nous sommes en ajout
    if (!this.currentPieceIdentiteJoint.typeDocumentSelected) {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner le type de document.");
      return;
    }

    // this.currentPieceIdentiteJoint = {};

    //Ouvrir la boite de dialogue
    let inputFile = document.getElementById("file-document-document");
    if (inputFile) {
      inputFile.click();
    }

  }

  uploadFacture(event: any) {

    if (!this.currentFacture.numeroDocument || !this.currentFacture.typeDocumentSelected) {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner et renseigner la reference du justificatif de domicile !");
      return;
    }

    let item = {
      fileName: null,
      fileBase64: null
    };
    let reader = new FileReader();

    let file = event.target.files[0];

    let tailleMax = this.user.tailleMaxPieceJointe * 1000000;
    if (file.size > tailleMax) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier la taille du fichier.");
      event.target.value = null;
      return;
    }

    item.fileName = file.name; //.split('.')[0];
    let Tabextension = file.name.split('.');
    let extension = Tabextension[Tabextension.length - 1];

    //verifier si l'extension est accepter
    const listeExtensionImagesOrPdf = this.userService.getExtensionsImageOrPdf();

    if (!_.find(listeExtensionImagesOrPdf, { 'extension': extension.toLowerCase() })) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier l'extension du fichier.");
      event.target.value = null;
      return;
    }

    reader.onload = (readerEvent) => {

      let data = (readerEvent.target as any).result;
      item.fileBase64 = data.split(',')[1];
      this.currentFactureJoint = {
        base64PieceJointe: item.fileBase64,
        numeroDocument: this.currentFacture.numeroDocument.toUpperCase(),
        idCategoriePieceJointe: this.currentFacture.typeDocumentSelected ? this.currentFacture.typeDocumentSelected.id : null,
        extensionPieceJointe: extension,
        allFileBase64: data,
        nomFichier: item.fileName,
        libelleDocument: this.currentFacture.typeDocumentSelected.libelle
        // libelleAutreDocument: this.itemDocumentAdd.libelleAutreNatureDocument || null
      };

      this.currentFacture = {};

      this.ListeDocumentFacturesToSave.push(this.currentFactureJoint);

    };

    reader.readAsDataURL(event.target.files[0]);

    event.target.value = null;
  }


  uploadFilePieceIdentite(event: any) {

    if (!this.currentPieceIdentiteJoint.numeroDocument || !this.currentPieceIdentiteJoint.typeDocumentSelected) {
      this.notificationService.showMessageError("Erreur", "Veuillez sélectionner et renseigner la réference du fichier !");
      return;
    }

    // Verifier si le type de fichier n'est pas encore ajouté
    // let isExist = _.find(this.ListeDocumentsPieceIdentiteJoint, (o) => { return o.idCategoriePieceJointe == this.currentPieceIdentiteJoint.typeDocumentSelected.id });
    // if (isExist) {
    //   this.notificationService.showMessageError("Erreur", "Ce type de fichier existe déjà dans la liste !");
    //   return;
    // }

    let item = {
      fileName: null,
      fileBase64: null
    };
    let reader = new FileReader();

    let file = event.target.files[0];

    let tailleMax = this.user.tailleMaxPieceJointe * 1000000;
    if (file.size > tailleMax) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier la taille du fichier.");
      event.target.value = null;
      return;
    }

    item.fileName = file.name; //.split('.')[0];
    let Tabextension = file.name.split('.');
    let extension = Tabextension[Tabextension.length - 1];

    //verifier si l'extension est accepter
    const listeExtensionImagesOrPdf = this.userService.getExtensionsImageOrPdf();

    if (!_.find(listeExtensionImagesOrPdf, { 'extension': extension.toLowerCase() })) {
      this.notificationService.showMessageError("Fichier", "Veuillez vérifier l'extension du fichier.");
      event.target.value = null;
      return;
    }

    reader.onload = (readerEvent) => {

      let data = (readerEvent.target as any).result;
      item.fileBase64 = data.split(',')[1];
      this.currentPieceIdentite = {
        base64PieceJointe: item.fileBase64,
        numeroDocument: this.currentPieceIdentiteJoint.numeroDocument.toUpperCase(),
        idCategoriePieceJointe: this.currentPieceIdentiteJoint.typeDocumentSelected ? this.currentPieceIdentiteJoint.typeDocumentSelected.id : null,
        extensionPieceJointe: extension,
        libelleDocument: this.currentPieceIdentiteJoint.typeDocumentSelected.libelle,
        allFileBase64: data,
        nomFichier: item.fileName,
        libelleAutreDocument: this.currentPieceIdentiteJoint.libelleAutreDocument || null
      };

      this.addDocumentJustificatif(event);

      event.target.value = null;
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  addDocumentJustificatif(event: any) {

    this.ListeDocumentsPieceIdentiteJoint.push(this.currentPieceIdentite);

    event.target.value = null;

    this.currentPieceIdentiteJoint = {
      typeDocumentSelected: {}
    };

    console.log(" this.ListeDocumentsPieceIdentiteJoint ", this.ListeDocumentsPieceIdentiteJoint);

  }

  confirmDeleteFacture(indexRow) {
    swal({
      title: 'Suppression justificatif de domicile',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

         // Dans le cas ou c'est un document enregistré en base de donnée on marque le isDeleted = true;
         if (this.ListeDocumentFacturesToSave[indexRow] && this.ListeDocumentFacturesToSave[indexRow].id) {
          this.ListeDocumentFacturesToSave[indexRow].isDeleted = true;
          this.listFileDelete.push(this.ListeDocumentFacturesToSave[indexRow]);
        }
        this.ListeDocumentFacturesToSave.splice(indexRow, 1);

        // if (this.currentFactureJoint && this.currentFactureJoint.id) {
        //   let oldFacture = { ...this.currentFactureJoint };
        //   oldFacture.isDeleted = true;

        //   this.listFileDelete.push(oldFacture);
        // }
        // this.currentFactureJoint = {};
      }
    })
  }

  confirmDeleteDocument(indexRow) {

    swal({
      title: "Suppression pièces d'identité",
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        // Dans le cas ou c'est un document enregistré en base de donnée on marque le isDeleted = true;
        if (this.ListeDocumentsPieceIdentiteJoint[indexRow] && this.ListeDocumentsPieceIdentiteJoint[indexRow].id) {
          this.ListeDocumentsPieceIdentiteJoint[indexRow].isDeleted = true;
          this.listFileDelete.push(this.ListeDocumentsPieceIdentiteJoint[indexRow]);
        }
        this.ListeDocumentsPieceIdentiteJoint.splice(indexRow, 1);
      }
    })
  }

  //** End gestion de fichier */

  //*** ENREGISTREMENT CERTIFICAT PART */
  confirmSaveCertificat() {

    if (!this.ListeDocumentsPieceIdentiteJoint) {
      this.notificationService.showMessageError("Erreur", "Veuillez joindre les documents d'identité !");
      return;
    }

    // Constitution du request
    let listPiecesJutificatives = [];

    this.ListeDocumentsJointRecap.forEach(doc => {
      listPiecesJutificatives.push({
        idCategoriePieceJointe: doc.idCategoriePieceJointe,
        libelleAutreDocument: doc.libelleAutreDocument,
        numeroDocument: doc.numeroDocument,
        extensionPieceJointe: doc.extensionPieceJointe,
        base64PieceJointe: doc.base64PieceJointe,
        id: doc.id || null
      });
    });

    // On ratache maintenant la facture joint
    // if (this.currentFactureJoint && this.currentFactureJoint.numeroDocument) {
    //   listPiecesJutificatives.push({
    //     idCategoriePieceJointe: this.currentFactureJoint.idCategoriePieceJointe,
    //     numeroDocument: this.currentFactureJoint.numeroDocument,
    //     extensionPieceJointe: this.currentFactureJoint.extensionPieceJointe,
    //     base64PieceJointe: this.currentFactureJoint.base64PieceJointe,
    //     id: this.currentFactureJoint.id || null
    //   });
    // } else if (!this.itemToSave.id) {
    //   this.notificationService.showMessageError(" Erreur ", "Veuillez joindre un document !");
    //   return;
    // }

    // listPiecesJutificatives = _.uniqBy(listPiecesJutificatives, 'idCategoriePieceJointe');

    // On rajoute les fichiers supprimer au cas ou il y a eu des suppressions
    this.listFileDelete.forEach(file => {
      listPiecesJutificatives.push(file);
    });

    swal({
      title: !this.itemToSave.id ? 'Enregistrement de certificat de residence' : 'Mise à jour de certificat de residence',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {

        let itemAEnregistrer = { ...this.itemToSave }

        itemAEnregistrer.motif = this.itemToSave.motifDemandeSelected.id;
        itemAEnregistrer.motifDemandeSelected = null;
        itemAEnregistrer.piecesJustificatives = listPiecesJutificatives;
        itemAEnregistrer.relancerPaiement = this.itemToSave.relancerPaiement;
        itemAEnregistrer.profession = this.currentMetier.id;
        itemAEnregistrer.nationalite = this.currentNationalite.id;
        itemAEnregistrer.commissariatDto = null;
        itemAEnregistrer.performanceUtilisateur = (!this.itemToSave.relancerPaiement && !this.itemToSave.isGrandPublic) ? this.performanceutilisateur : null;

        // On format la date
        let dateFormatOperation = moment(this.itemToSave.dateDeNaissance).format("DD/MM/YYYY");
        if (dateFormatOperation == "Invalid date") {
          dateFormatOperation = this.itemToSave.dateDeNaissance;
        }

        itemAEnregistrer.dateDeNaissance = dateFormatOperation;

        this.saveItem(itemAEnregistrer)
      }
    })
  }
  //*** END ENREGISTRMENT CERTIFICAT */


  saveItem(item?) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busyGet = this.api.post('certificatResidences/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {

          if (!res['hasError'] && res['items']) {

            if((this.itemToSave.id && !this.itemToSave.relancerPaiement) || !this.itemToSave.id) {
              this.notificationService.showMessageSucces("Certificat de résidence", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
           
            sessionStorage.removeItem("retour-paiement");
            let itemSaved = res['items'][0];

            let montantTotalAPayer = itemSaved.montant;

            console.log(" this.itemToSave.id ", this.itemToSave);

            if (!this.itemToSave.id || (this.itemToSave.id && this.itemToSave.relancerPaiement)) {
              if (montantTotalAPayer && montantTotalAPayer > 0) {
                // sessionStorage.setItem("metaDataPaiement", numeroPaiementFormater?.toString());
                sessionStorage.setItem("montantPaiement", montantTotalAPayer.toString());

                if (itemSaved['transactionId']) {
                  sessionStorage.setItem("transaction_id", itemSaved['transactionId'].toString());
                } else {
                  sessionStorage.setItem("transaction_id", "1200394");
                }

                this.gotoCinetPayPaiement();

                // this.router.navigate(['/dashbord']);

              }
            }
            else {
              // this.notificationService.showMessageSucces('Paiement', this.messageSuccess);
              this.router.navigate(['/dashbord']);
            }

            // if (res['urlFileToDownload']) {
            //   this.linkPdfFile = res['urlFileToDownload'] ? res['urlFileToDownload'] : null;
            //   this.showPreviewPrintModal();
            //   this.router.navigate(['/dashbord']);
            // } else {
            //   // C'est que nous sommes en modification
            //   this.router.navigate(['/dashbord']);
            // }
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }

  showPreviewPrintModal(link?) {
    this.bsModalRefPreview = this.modalService.show(FormPreviewPrintComponent, Object.assign({}, this.config, { class: 'gray modal-lg modal-custom-preview' }));
    this.bsModalRefPreview.content.currentLink = this.linkPdfFile;
    this.bsModalRefPreview.content.isPrintAfterSaveCertificat = true;
    this.bsModalRefPreview.content.titleReportDocumentSortie = 'Certificat de résidence';
    // link = this.linkPdfFile;
    // window.open(link, '_blank');
  }

  monthChange(mois) {
    if (mois && mois == "02") {
      if (parseFloat(this.itemToSave.jourResidence) > 29) {
        this.notificationService.showMessageError("Erreur", "Veuillez renseigner un jour valide !");
        this.itemToSave.jourResidence = null;
        return
      }
    }
  }

  /** PAIEMENT CINETPAY */
  // openModalPaiement() {
  //   // On appel la fonction pour l'ouverture du modal de paiement
  //   // if (document.getElementById("btn-to-pay-create-certificat")) {
  //   //   document.getElementById("btn-to-pay-create-certificat").click();
  //   // }

  //   this.gotoCinetPayPaiement();
  // }
  /** FIN PAIEMENT CINETPAY */

  // Test check paiement
  gotoCinetPayPaiement() {
    var data = JSON.stringify({
      "transaction_id": sessionStorage.getItem("transaction_id"), //"130043",
      "apikey": "179990205162cc71d73e8804.22209231",
      "site_id": "298374",
      // "site_id": "670679",
      // "amount": 100,
      "amount" : sessionStorage.getItem("montantPaiement"),
      "currency": "XOF",
      "description": " Paiement de certificat ",
      "notify_url": "https://certificat-residence.oneci.ci/api/certificatResidence/certificatResidences/notify-paiement/",
      "return_url": "https://certificat-residence.oneci.ci/api/certificatResidence/certificatResidences/notify-paiement/",
      "channels": "MOBILE_MONEY",
      "metadata": sessionStorage.getItem("metaDataPaiement"),
      "lang": "FR",
    });

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {

      if (this.readyState === 4) {
        let retourApiPaiement = JSON.parse(this.responseText);
        console.log("retourApiPaiement ", retourApiPaiement);

        if (retourApiPaiement && retourApiPaiement.data) {
          document.getElementById("btn-to-pay-create-certificat").click();

          localStorage.setItem("current_payment_url", retourApiPaiement.data.payment_url);
        }
      }
    });

    xhr.open("POST", "https://api-checkout-oneci.cinetpay.com/v2/payment");
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(data);
  }

  openCinetPayPaiementForm() {
    this.bsModalRefCinetPay = this.modalService.show(FormPaiementCinetpayComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRefCinetPay.content.urlPaiementCinetPay = localStorage.getItem("current_payment_url");

    setTimeout(() => {
      this.router.navigate(['/dashbord']);
    }, 1000);
  }

  
  ngOnInit() {
    this.getDataMotifDemande();
    this.getDataTypeDocument();
    this.getJour();
    this.getPerformanceUtilisateur();
  }

}
