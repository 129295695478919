import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { enumKeyCrypto_AES } from './enumerator';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable()
export class UtilitiesService {

    constructor(private router: Router) {
    }
 
    setReportParametre(parametreReport: any) {
        sessionStorage.setItem("xyz-report-token", parametreReport);
    }

    getReportParametre() {
        let item = sessionStorage.getItem("xyz-report-token");
        return item;
    }

    getTimeRefreshPositionGps() {
        return 60000 * 5; //Equivaux a 1min * par le nombre
    }

    //Format millier
    getFormatMillierMontant(montant) {

        if (!montant || !parseFloat(montant)) return;

        try {
            montant = montant.replace(/ /g, '');
            var nombre = montant;
            nombre += '';
            var sep = ' ';
            var reg = /(\d+)(\d{3})/;

            while (reg.test(nombre)) {
                nombre = nombre.replace(reg, '$1' + sep + '$2');
            }
        } catch (error) {

        }

        return nombre;
    }

    formatMaskContact(longueurContact : number)
    {
        try {
            
            let formatMask = ""

            if(longueurContact && longueurContact > 0)
            {
                for (let i = 1; i <= longueurContact; i++) {
                   
                    if( i%2 == 0 )  //Nous sommes sur le deuxieme 0
                    {
                        formatMask = formatMask +"0 ";
                    }
                    else
                    {
                        formatMask = formatMask +"0";
                    }
                    
                }
            }

            return formatMask.trim();
            
        } catch (error) {
            
        }

    }


    utilsFormatMillier(input) {
        if (!input) return;

        var nombre = input;
        nombre += '';
        var sep = ' ';
        var reg = /(\d+)(\d{3})/;

        while (reg.test(nombre)) {
            nombre = nombre.replace(reg, '$1' + sep + '$2');
        }

        return nombre;
    }

    checkDeconnectionUser(resultat) {
        if (resultat && resultat.deconnecterSessionUser) {
            ///this.removeItemsLocalStoreGlobal();
            // this.router.navigate(['/auth/login']);

            let currentUser = this.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.loginUserEncrypt));

            currentUser.token = null;
            
            sessionStorage.setItem(enumKeyCrypto_AES.loginUserEncrypt, this.encryptData(currentUser));
            
        }
    }

    removeItemsLocalStoreGlobal() {
        // sessionStorage.removeItem(enumKeyCrypto_AES.loginUserEncrypt);
        sessionStorage.removeItem(enumKeyCrypto_AES.montantBaseParam);
        sessionStorage.removeItem(enumKeyCrypto_AES.longueurContactParam);
        sessionStorage.removeItem(enumKeyCrypto_AES.ListeNaturesDemandes);
        sessionStorage.removeItem(enumKeyCrypto_AES.ListeNaturesPieces);
        sessionStorage.removeItem(enumKeyCrypto_AES.ListeObjetDemandes);
        sessionStorage.removeItem(enumKeyCrypto_AES.currentDemandeToUpdate);

    }

    deleteHtmlCaracter(item: any, nombre: number) {
        let regex = /(&nbsp;|<([^>]+)>)/ig;

        //RETIRER LES CARACTERES HTML
        item = item.replace(regex, "");

        if (item.length > nombre) {
            item = item.substring(0, nombre).trim() + "" + " ...";
        }

        //this.htmlEntities(item);

        return item;
        //return this.escapeCaractereSpeciaux(item); //this.htmlEntities(item);
    }

    messageInterpretor(err) {
        let messageError = '';
        let messageContains = '';

        let error = err;
        if (error && error.error && error.error.text) {
            let defError = error.error.text;
            messageContains = "Integrity constraint violation";
            if (defError.indexOf(messageContains)) {
                let currentColonne = '';
                let tabsColonne = defError.split('for key');
                if (tabsColonne && tabsColonne.length > 1) {
                    let newTab = tabsColonne[1].split('in');

                    if (newTab && newTab.length > 0) {
                        currentColonne = newTab[0];
                    }

                }
                messageError = "Tentative de duplication de donnée (" + currentColonne + ")";
            }
            else {
                messageContains = "Unknown column";
                if (defError.indexOf(messageContains)) {
                    let currentColonne = '';
                    let tabsColonne = defError.split('Unknown column');
                    if (tabsColonne && tabsColonne.length > 1) {
                        let newTab = tabsColonne[1].split('in');

                        if (newTab && newTab.length > 0) {
                            currentColonne = newTab[0];
                        }

                    }
                    messageError = "Champs non renseigné (" + currentColonne + ")";
                }

            }

        }
        else {
            messageError = "Connexion momentanément interrompue";
        }

        return messageError;
    }


    mainSelfFormatMsgServeur(msg) {
       
        var messageError = msg;
        if (msg) {

            if (msg.trim() == "Operation effectuee avec succes:") {
                messageError = messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès.");
            }
            else {
                messageError = messageError.replace("Operation effectuee avec succes:", "");
            }

            messageError = messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès");
            messageError = messageError.replace("Impossible de se connecter a la base de donnees:", "");
            messageError = messageError.replace("La base de donnees est indisponible:", "");
            messageError = messageError.replace("Permission refusee par la base de donnees:", "");
            messageError = messageError.replace("Le serveur de base de donnees a refuse la requete:", "");
            messageError = messageError.replace("Authentification echouee:", "");
            messageError = messageError.replace("Donnee existante:", "");
            messageError = messageError.replace("Liste vide : il n'y a pas de donnees respectant ce critere:", "");
            messageError = messageError.replace("il n'y a pas de donnees respectant ce critere", "");
            messageError = messageError.replace("Champ non renseigne:", "");
            messageError = messageError.replace("Utilisateur deja connecte:", "");
            messageError = messageError.replace("la requete attendue n'est pas celle fournie:", "");
            messageError = messageError.replace("Le type est incorrect:", "");
            messageError = messageError.replace("Le format de la date est incorrect:", "");
            messageError = messageError.replace("le serveur a signale un format invalide:", "");
            messageError = messageError.replace("le code de la langue n'est pas valide:", "");
            messageError = messageError.replace("La periode de date n'est pas valide", "");
            messageError = messageError.replace("une erreur est survenue lors de l'enregistrement:", "");
            messageError = messageError.replace("le name de l'entite n'est pas valide:", "");
            messageError = messageError.replace("Veuillez renseigner une seule valeur pour cette donnee:", "");
            messageError = messageError.replace("La somme des pourcentages ne doit exceder 100:", "");
            messageError = messageError.replace("Erreur de generation de fichier:", "");
            messageError = messageError.replace("login et/ou mot de passe incorrect(s):", "");
            messageError = messageError.replace("Operation interdite/refusee:", "");
            messageError = messageError.replace("Ccette donnees ne peut etre supprimee car elle est utilisee:", "");
            messageError = messageError.replace("cette donnees est trop superieure:", "");
            messageError = messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.", "");
            messageError = messageError.replace("Donnee inexistante:", "");
            messageError = messageError.replace("Erreur interne:", "");
            messageError = messageError.replace("Le serveur de base de donnees a refuse la requete:", "");
            messageError = messageError.replace("cette donnees ne peut etre supprimee car elle est utilisee:", "");
            messageError = messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.", "");


        }
        return messageError;
    };

    getLibelleSourceRecherche(libelle) {
        let libelleFormat = "";

        if (libelle) {
            switch (libelle.trim().toUpperCase()) {
                case "CARTE DE RESIDENT":
                    libelleFormat = "Carte de Résident";
                    break;
                case "ATTESTATION D'IDENTITE":
                    libelleFormat = "Attestation d'Identité";
                    break;
                case "PERMIS DE CONDUIRE":
                    libelleFormat = "Permis de Conduire";
                    break;
                default:
                    libelleFormat = "Carte Nationale d'Identité";
            }
        }

        return libelleFormat;
    }


    transformNumberMonthInLibelleMonth(numero_mois: number) {
        if (numero_mois) {
            let tabMonth = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"];

            return tabMonth[numero_mois - 1]
        }
    }

    formatJourNaissance(number_jour: number) {
        if (number_jour && number_jour < 10) {
            return "0" + number_jour;
        }
        else {
            return number_jour;
        }
    }

    transformUppercase(item)
    {
      return item.toUpperCase();
    }

    encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), enumKeyCrypto_AES.encryptionKey).toString();
        } catch (e) { /* console.log(e); */ }
    }

    decryptData(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, enumKeyCrypto_AES.encryptionKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) { }
    }

    //Format libelle genre
    getLibelleGenre(genre) {
        let libelleGenre = 'MASCULIN';

        if (genre && genre == "F") libelleGenre = "FEMININ";

        return libelleGenre;
    }

}
