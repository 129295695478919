import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer ,SafeUrl } from '@angular/platform-browser';

@Pipe({name: 'preview'})
export class Preview implements PipeTransform {
    transform(input: any, count?: number) {
        if(!input) return;

        if(count && input.length > count){
            return input.substring(0, count)+'...';
        }
        else if(input.length > 230){
            return input.substring(0, 230)+'...';
        }
        else {
            return input;
        }
    }
}

@Pipe({name: 'formatmillier'})
export class FormatMiller implements PipeTransform {
    transform(input: any, count?: number) {
        if(!input && input != 0) return;

        var nombre=input;
        nombre += '';
        var sep = ' ';
        var reg = /(\d+)(\d{3})/;

        while (reg.test(nombre)) {
            nombre = nombre.replace(reg, '$1' + sep + '$2');
        }

        return nombre;
    }
}

@Pipe({ name: 'safeUrlDom'})
export class SafeUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    // console.log(this.sanitized.bypassSecurityTrustHtml(value))
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Pipe({name: 'jsondate'})
export class JsonDate implements PipeTransform {
    transform(input: any, format?: number) {
        if(!input) return;

        var dateNew = parseInt(input.replace('/Date(', ''));
        //return (parseInt(input.substr(6)), format);
        return new Date(dateNew).toLocaleString().split(' ')[0]; //new Date();

    }
}