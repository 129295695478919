import {Injectable} from '@angular/core';


import {JsonApiService} from "../../core/api/json-api.service";
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from "lodash";
import { UtilitiesService } from '../utils/utilities.service';
import { enumKeyCrypto_AES } from '../utils/enumerator';

@Injectable()
export class UserService {

  public user: Subject<any>;
  private data = new BehaviorSubject('true');
  currentData = this.data.asObservable();

  private checkingRoutageDashbordPage = new BehaviorSubject('true');
  currentDataCheckingRoutageDashbordPage = this.checkingRoutageDashbordPage.asObservable();


  private checkingRoutageDashbordClientPage = new BehaviorSubject('true');
  currentDataCheckingRoutageDashbordClientPage = this.checkingRoutageDashbordClientPage.asObservable();


  private dataOpenModal = new BehaviorSubject('');
  currentDataSubscribeOpenModal = this.dataOpenModal.asObservable();

  public userInfo = {
    username: 'Guest'
  };

  constructor(private utilities: UtilitiesService) {
    this.user = new Subject();
  } 

  isLoggedIn():boolean {
    let user = this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.loginUserEncrypt));
    return !!user; 
  }

  // getLoginInfo():Observable<any> {
  //   return  this.jsonApiService.fetch('/user/login-info.json')
  //     .do((user)=>{
  //       this.userInfo = user;
  //     this.user.next(user)
  //   })
  // }

  getStateRoutageAdministration(item: any) {
    this.data.next(item);
  }


  getStateRoutageMatching(item: any) {
    this.checkingRoutageDashbordPage.next(item);
  }


  getStateRoutageDashbordClient(item: any) {
    this.checkingRoutageDashbordClientPage.next(item);
  }



  openModalSearchForDeconnexionTime(item: any) {
    this.dataOpenModal.next(item);
  }

  getExtensionsImageOrPdf()
  {
    return [
      {extension:'png'},
      {extension:'jpeg'},
      {extension:'jpg'},
      {extension:'pdf'}
    ];
  }

  getExtensionsImage()
  {
    return [
      {extension:'png'},
      {extension:'jpeg'},
      {extension:'jpg'}
    ];
  }


  getExtensionsXlsx()
  {
    return [
      {extension:'xlsx'},
      {extension:'xls'}
    ];
  }
  
  getPermission(code, code1 ? : String)
  {
    code = code.trim()
    var returnValue = null;
    var ListeFonctionnalites = []; //this.getCurrentUser().fonctionnalites; // (this.getCurrentUser() && this.getCurrentUser().fonctionnalites) ? this.getCurrentUser().fonctionnalites : [];
    
    // console.log("ListeFonctionnalites ",ListeFonctionnalites); 

    // if(ListeFonctionnalites.length > 0)
    // {
    //   _.forEach(ListeFonctionnalites, function(valueChildSecond, keyChild) {
    //     if(valueChildSecond.code == code || (code1 && valueChildSecond.code == code1))
    //     {
    //          returnValue = true;
    //     }
    //   });
    // }

    return true; // returnValue;
  }

  getCurrentUser()
  {
    return this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.loginUserEncrypt)); //JSON.parse(sessionStorage.getItem("yz-backoffice-app-oneci-auth"));
  }

}
