import {Injectable} from '@angular/core';
import { UtilitiesService } from './utilities.service';
import { enumKeyCrypto_AES } from './enumerator';

declare var $: any;

@Injectable()
export class NotificationService {

  constructor(private utilities: UtilitiesService) {
  }

 showMessageInfo(title,message)
  {
      message = this.utilities.mainSelfFormatMsgServeur(message);
      this.smallBox({
          title: title,
          content: message,
          color: "#159",
          iconSmall: "fa fa-info fa-2x fadeInRight animated",
          timeout: 4000
      })
  }


 showMessageWarning(title,message)
 {
     message = this.utilities.mainSelfFormatMsgServeur(message);
     this.smallBox({
         title: title,
         content: message,
         color: "#66b5e4", 
         iconSmall: "fa fa-info fa-2x fadeInRight animated",
         timeout: 4000
     })
 }


 showMessageAlertFeuilletRestant(title,message)
 {
     message = this.utilities.mainSelfFormatMsgServeur(message);
     this.smallBox({
         title: title,
         content: message,
         color: "#f79400", 
         iconSmall: "fa fa-info fa-2x fadeInRight animated",
         timeout: 4000
     })
 }


    showMessageError(title,message)
    {
        message = this.utilities.mainSelfFormatMsgServeur(message);
        this.smallBox({
            title: title,
            content: message,
            color: "#ec5252",
            iconSmall: "fa fa-times-circle fa-2x fadeInRight animated",
            timeout: 4000
        })
    }

    showMessageSucces(title,message)
    {
        message = this.utilities.mainSelfFormatMsgServeur(message);
        this.smallBox({
            title: title,
            content: message,
            color: "#659265",
            iconSmall: "fa fa-check fa-2x fadeInRight animated",
            timeout: 4000
        })
    }

    defaultMessage()
    {
      //Verifier si c'est pas une perte de connexion
      //Dans le cas ou c'est une perte de connexion on ne fait rien car l'intercepteur à deja envoyé la notification
      if(!sessionStorage.getItem(enumKeyCrypto_AES.pasDeConnexionInternet))
      {
        let text_default = "Le service tarde à repondre, veuillez réessayer ultérieurement.";
        this.showMessageError("Connexion",text_default);
      }
    
    }

    defaultMessageError()
    {
      let text_default = "Une erreur c'est produite lors de l'enregistrement";
      return text_default;
    }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }

}
