import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import {ActivatedRoute, Route, Router} from '@angular/router';
import { FormSearchReferenceSaisieCorrectionComponent } from '../../../+components/form-search-reference-saisie-correction/form-search-reference-saisie-correction.component';
import { FormPrintBulletinComponent } from '../../../+components//form-print-bulletin/form-print-bulletin.component';
import { FormPrintRecapitulatifPaieComponent } from '../../../+components/form-print-recapitulatif-paie/form-print-recapitulatif-paie.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserService} from "../../user/user.service";
import { Location } from "@angular/common";


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  user:any;

  bsModalRef: BsModalRef;
  itemToSave : any;
  itemMenuHover : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  route: string;
  routeMenuHover : string;

  constructor(public router:Router,private modalService: BsModalService,private userService: UserService,location: Location) {

    // router.events.subscribe(val => {
    //   if (location.path() != "") {
    //     this.route = location.path();
    //   } else {
    //     this.route = "Home";
    //   }
    // });

  }
  
  
  checkPermission(code1,code2,code3)
  {
    let returnValue = null;
    let ListeFonctionnalites = this.user.fonctionnalites;

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    if(itemFonctionnalite) 
    {
      returnValue = true;
    }
    
    return returnValue;
  }
  
  

  ngOnInit() {
    this.user = this.userService.getCurrentUser(); 
  }

}
