import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/user';
import { Api } from 'app/shared/utils/api/api';
import { NotificationService } from 'app/shared/utils/notification.service';
import { UtilitiesService } from 'app/shared/utils/utilities.service';
var L = require('leaflet');
import * as _ from "lodash";
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;


@Component({
  selector: 'app-main-vue-map-dashbord',
  templateUrl: './main-vue-map-dashbord.component.html',
  styleUrls: ['./main-vue-map-dashbord.component.css']
})
export class MainVueMapDashbordComponent implements OnInit {

  layers: Array<any> = [];
  options: any = {};
  itemLabel : any = {};
  map: any = {};
  isLoading: boolean = false;
  positionToGo: any;
  itemStatistique: any = {};
  busyGetDataMap: Subscription;
  resultDemandes: Array<any> = [];
  resultDemandesByVille: Array<any> = [];
  listLatLngCoordForResize :  Array<any> = [];
  modalRef: BsModalRef;
  user : any = {};
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService, private daterangepickerOptions: DaterangepickerConfig, public router: Router) {
    this.user = this.userService.getCurrentUser();
  }

  @ViewChild('templateDetailsDemandeByVille') templateDetailsDemandeByVille: ElementRef;

  @Input() searchParameter: any;
  @Input() refreshMap: any;

  ngAfterViewInit(): void {
    this.initMap();
  }

  // openFormModalModal(template) {

  //   this.resultDemandesByVille = [];

  //   let btnHiddenForAction = document.getElementById("button-open-modal");

  //   if (btnHiddenForAction['value'] && btnHiddenForAction['value'] != "") {
  //     // En ce moment on appel la fonction pour charger les communes

  //     this.itemLabel = "region du "+btnHiddenForAction['value'];

  //     // Verifier pour voir si cette ville remonte des valeurs
  //     let hasValueInItemsRegion = _.find(this.resultDemandes, { 'villeCommune': btnHiddenForAction['value'] });
      
  //     if(hasValueInItemsRegion){
  //       this.itemLabel = "ville de "+btnHiddenForAction['value'];
  //       // Ajouter le parametre ville à la requette
  //       this.searchParameter.data.ville = btnHiddenForAction['value'];

  //       this.getDataCertificatByRegion(true);
  //     }

  //   }

  //   this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-lg' }));

  // }

  recentrerMap(){
    try {
      if(this.map){
        window.scrollTo(0, 275);
        this.map.setView(new L.LatLng(7.52, -5.71), 7); 
      }
    } catch (error) {
      console.log(" error ",error);
    }
  }


  initMap(isDepartement ?): void {

    window.scrollTo(0, 275);

    this.map = L.map('map', {
      zoom: 7,
      minZoom: 7,
      center: L.latLng({ lat: 7.52, lng: -5.71 })
    });

    // Chargement de la cartographie
    if(!isDepartement) {
      this.loadDelimitationRegion();
    }else {
      this.loadDelimitationDepartement();
    }
    

    L.control.scale({ metric: true }).addTo(this.map);

    // setTimeout(() => {
    //   this.map.flyTo([5.343449, -4.004070], 13, {
    //     animate: true,
    //     duration: 1.5
    //   });
    // }, 1500)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);


  }

  /*** TRAITEMENT REGION  */

  loadDelimitationRegion() {

    this.layers = [];
    //Chargement delimitation des region
    $.getJSON('assets/json/ci-all-region-json.json').then((geojson) => {

      this.layers.push(L.geoJSON(geojson, {
        style: (feature) => {

          // console.log(" features ",feature);

          let returnColor: any;
          returnColor = { color: '#b5b4b4', fillOpacity: 0.4, fillColor: "#cfeacf", weight: 0.3 };

          let hasValueInItemsRegion = _.find(this.resultDemandes, { 'region': feature.properties.name.toUpperCase() });
          if (hasValueInItemsRegion) {
            returnColor = {
              color: "#f8ab37",
              fillOpacity: 0.8, fillColor: "#f8ab37",
              weight: 0.5
            }
          }

          return returnColor;
        },
        onEachFeature: this.handleFeatureRegion,

      }).addTo(this.map))
    },
      (err) => {
      });

  }

  // Fonction de formatage des données html
  handleFeatureRegion = (feature, layer) => {
    var mapValue : any;

    mapValue = this.map;

    if(this.resultDemandes && this.resultDemandes.length > 0) {
    
      let hasValueInItemsRegion = _.find(this.resultDemandes, { 'region': feature.properties.name.toUpperCase() });
      
      if (hasValueInItemsRegion) {
  
        hasValueInItemsRegion.libelleZone = hasValueInItemsRegion.region;
        feature.properties.value = hasValueInItemsRegion;
        var maker = L.marker([layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
          icon: L.divIcon({
            html: '<b style="color:#000;background:#fff;opacity:1;padding:4px;border-radius:5px" (click)="clickLable()">' + feature.properties.name + '<sup style="color: #fff;border-radius: 50%;padding: 2px 5px 2px 5px;border: 2px solid #fff;background: #ea7504;font-size: 10px;">' + hasValueInItemsRegion.nombreCertificat + '</sup></b>', //feature.properties.NAME,
          
            className: 'label',
          })
        }).addTo(mapValue);
  
        maker.bindTooltip(this.formatHTMLvalue(feature.properties.value));
        // maker.on('click', cliquefunction);
        this.layers.push(maker)
  
      };
    }

    layer.on({
      mouseover: mouseoverfunctionRegion,
      mouseout: mouseoutfunctionRegion,
      // click: cliquefunction
    });


    function mouseoverfunctionRegion(e, layer) {

      if(e){

        var properties = e.target.feature.properties;
        var htmlFormatValue;

        htmlFormatValue = formatHTMLvalue(properties.value);

        if(htmlFormatValue){
          L.tooltip([e.target.getBounds().getCenter().lat, e.target.getBounds().getCenter().lng])
          .setLatLng(e.latlng)
          .setContent(htmlFormatValue).addTo(mapValue)
        }
      }
    }

    function mouseoutfunctionRegion(e, layer) {

      mapValue.eachLayer(function(layer) {
        if(layer.options.pane === "tooltipPane") layer.removeFrom(mapValue);
      });

    }

    function formatHTMLvalue(value) {

      var htmlFormatValue;
  
      if (value && value.libelleZone) {
        htmlFormatValue = "<table> <tbody>" +
        "<tr> <td colspan='2' style='text-align:center;padding-bottom:10px;padding-top:10px'> <b>" + (value.libelleZone) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre de certificat :</td> <td> <b>" + (value.nombreCertificat || 0) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre de commissariat :</td> <td> <b> " + (value.nombreCommissariat || 0) + " </b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Montant total :</td> <td><b> " + ( (value.montantCertificatFormat +" FCFA") || 0) + " </b></td>  </tr>" +
        "</tbody> </table>";
      }
  
      return htmlFormatValue;
  
    }
  }

  /******* FIN TRAITEMENT REGION */


   /*** TRAITEMENT DEPARTEMENT  */
  
   loadDelimitationDepartement() {

    this.layers = [];
    //Chargement delimitation des region
    $.getJSON('assets/json/ci-all-departement.json').then((geojson) => {

      this.layers.push(L.geoJSON(geojson, {
        style: (feature) => {

          // console.log(" features ",feature);

          let returnColor: any;
          returnColor = { color: '#b5b4b4', fillOpacity: 0.4, fillColor: "#cfeacf", weight: 0.3 };

          let hasValueInItemsDepartement = _.find(this.resultDemandes, { 'departement': feature.properties.name.toUpperCase() });
          if (hasValueInItemsDepartement) {
            returnColor = {
              color: "#f8ab37",
              fillOpacity: 0.8, fillColor: "#f8ab37",
              weight: 0.5
            }
          }

          return returnColor;
        },
        onEachFeature: this.handleFeatureDepartement,

      }).addTo(this.map))
    },
      (err) => {
      });

  }

  // Fonction de formatage des données html
  handleFeatureDepartement = (feature, layer) => {
    var mapValue : any;

    mapValue = this.map;

    if(this.resultDemandes && this.resultDemandes.length > 0) {
    
      let hasValueInItemsDepartement = _.find(this.resultDemandes, { 'departement': feature.properties.name.toUpperCase() });
      
      if (hasValueInItemsDepartement) {
  
        hasValueInItemsDepartement.libelleZone = hasValueInItemsDepartement.departement;
        feature.properties.value = hasValueInItemsDepartement;
        var maker = L.marker([layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
          icon: L.divIcon({
            html: '<b style="color:#000;background:#fff;opacity:1;padding:4px;border-radius:5px" (click)="clickLable()">' + feature.properties.name + '<sup style="color: #fff;border-radius: 50%;padding: 2px 5px 2px 5px;border: 2px solid #fff;background: #ea7504;font-size: 10px;">' + hasValueInItemsDepartement.nombreCertificat + '</sup></b>', //feature.properties.NAME,
          
            className: 'label',
          })
        }).addTo(mapValue);
  
        maker.bindTooltip(this.formatHTMLvalue(feature.properties.value));
        // maker.on('click', cliquefunction);
        this.layers.push(maker)
  
      };
    }

    layer.on({
      mouseover: mouseoverfunctionDepartement,
      mouseout: mouseoutfunctionDepartement,
      // click: cliquefunction
    });


    function mouseoverfunctionDepartement(e, layer) {

      if(e){

        var properties = e.target.feature.properties;
        var htmlFormatValue;

        htmlFormatValue = formatHTMLvalue(properties.value);

        if(htmlFormatValue){
          L.tooltip([e.target.getBounds().getCenter().lat, e.target.getBounds().getCenter().lng])
          .setLatLng(e.latlng)
          .setContent(htmlFormatValue).addTo(mapValue)
        }
      }
    }

    function mouseoutfunctionDepartement(e, layer) {

      mapValue.eachLayer(function(layer) {
        if(layer.options.pane === "tooltipPane") layer.removeFrom(mapValue);
      });

    }

    function formatHTMLvalue(value) {

      var htmlFormatValue;
  
      if (value && value.libelleZone) {
        htmlFormatValue = "<table> <tbody>" +
        "<tr> <td colspan='2' style='text-align:center;padding-bottom:10px;padding-top:10px'> <b>" + (value.libelleZone) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre de certificat :</td> <td> <b>" + (value.nombreCertificat || 0) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre de commissariat :</td> <td> <b> " + (value.nombreCommissariat || 0) + " </b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Montant total :</td> <td><b> " + ( (value.montantCertificatFormat +" FCFA") || 0) + " </b></td>  </tr>" +
        "</tbody> </table>";
      }
  
      return htmlFormatValue;
  
    }
  }

  /******* FIN TRAITEMENT DEPARTEMENT */


  formatHTMLvalue(value) {

    var htmlFormatValue;

    if (value && value.libelleZone) {
      htmlFormatValue = "<table> <tbody>" +
        "<tr> <td colspan='2' style='text-align:center;padding-bottom:10px;padding-top:10px'> <b>" + (value.libelleZone) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre de certificat :</td> <td> <b>" + (value.nombreCertificat || 0) + "</b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Nombre "+ (this.user.commissariatActif ? "d'agence / commissariat" : "d'agence")+" :</td> <td> <b> " + (value.nombreCommissariat || 0) + " </b> </td>  </tr>" +
        "<tr> <td style='padding-bottom:5px;padding-right:10px'> Montant total :</td> <td><b> " + (( (value.montantCertificatFormat) +" FCFA") || 0) + " </b></td>  </tr>" +
        "</tbody> </table>";
    }

    return htmlFormatValue;

  }

  ngOnInit() {

  }

  changeMode(isDepartement) {

    if (this.map) {
      this.map.remove();
      this.initMap(isDepartement); 
    }

    if(isDepartement) {
      this.getDataCertificatByDepartement();
    }else {
      this.getDataCertificatByRegion();
    }
  }

  getDataCertificatByRegion() {

    this.busyGetDataMap = this.api.post('certificatResidences/dashboardByRegion', this.searchParameter)
      .subscribe(
        res => {

          if (res && res['items']) {
              this.resultDemandes = res['items'];

              this.resultDemandes.forEach(item => {
                item.montantCertificatFormat = this.utilities.utilsFormatMillier(item.montantCertificat);
              });
              this.loadDelimitationRegion();
          } else {

            this.resultDemandes = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }

          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );
  }


  getDataCertificatByDepartement() {

    this.busyGetDataMap = this.api.post('certificatResidences/dashboardByDepartement', this.searchParameter)
      .subscribe(
        res => {
          console.log(" resultat ",res);
          
          if (res && res['items']) {
              this.resultDemandes = res['items'];

              this.resultDemandes.forEach(item => {
                item.montantCertificatFormat = this.utilities.utilsFormatMillier(item.montantCertificat);
              });
              this.loadDelimitationDepartement();
          } else {

            this.resultDemandes = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }

          }
        },
        err => {
          this.notificationService.defaultMessage();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

    if ((changes['searchParameter'] && changes['searchParameter'].currentValue) || (changes['refreshMap'] && changes['refreshMap'].currentValue) ) {

      console.log(" je dois rafraichir la map ")
      try {
        if (this.map) {
          this.map.remove();
          this.initMap(); 
        }
      } catch (error) {

      }

      // Annuler l'index et le size si elle existe
      this.searchParameter.index = null;
      this.searchParameter.size = null;
      this.searchParameter.data.forMap = true;
      this.resultDemandes = [];

      this.getDataCertificatByDepartement();
    }
  }

  ngOnDestroy() {

    if (this.busyGetDataMap) {
      this.busyGetDataMap.unsubscribe();
    }

  }

}
