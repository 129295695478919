/**
 * Created by griga on 7/11/16.
 */


import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";
import {LoginComponent} from "./+auth/+login/login.component";
import {ModuleWithProviders} from "@angular/core";

import {LoggedInGuardService} from "./shared/utils/guards/logged-in-guard.service";
// import { MainSearchFirstPageComponent } from './+components/component-app/main-search-first-page/main-search-first-page.component';
// import { MainSearchSecondPageComponent } from './+components/component-app/main-search-second-page/main-search-second-page.component';
import { MainDashbordComponent } from './+components/component-app/main-dashbord/main-dashbord.component';
// import { MainResultatRechercheComponent } from './+components/component-app/main-resultat-recherche/main-resultat-recherche.component';
import { FormCreateDemandeComponent } from './+components/component-app/form-create-demande/form-create-demande.component';
import { MainLayoutSidebarComponent } from './shared/layout/app-layouts/main-layout-sidebar/main-layout-sidebar.component';
import { MainParametresComponent } from './+components/component-app/main-parametres/main-parametres.component';
// import { ConfirmerResultatAuthentificationComponent } from './+components/component-app/confirmer-resultat-authentification/confirmer-resultat-authentification.component';
// import { FormRechercheDemandeDgComponent } from './+components/component-app/form-recherche-demande-dg/form-recherche-demande-dg.component';
import { MainAdministrationComponent } from './+components/component-app/main-administration/main-administration.component';
// import { MainMatchingSearchComponent } from './+components/component-app/main-matching-search/main-matching-search.component';
// import { MainDemandeClientsComponent } from './+components/component-app/main-demande-clients/main-demande-clients.component';
import { MainParametrageComissariatComponent } from './+components/component-app/main-parametrage-comissariat/main-parametrage-comissariat.component';
import { FormCreateCertificatComponent } from './+components/component-app/form-create-certificat/form-create-certificat.component';
import { MainHistoriqueConnexionComponent } from './+components/component-app/main-historique-connexion/main-historique-connexion.component';
import { MainApprovisionnementCommissariatComponent } from './+components/component-app/main-approvisionnement-commissariat/main-approvisionnement-commissariat.component';
import { MainApproStockComponent } from './+components/component-app/main-appro-stock/main-appro-stock.component';

export const routes: Routes = [
  {
    path: '', redirectTo: 'auth', pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {pageTitle: 'Accueil'},
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full',

      },
      /** DESKTOP */

 
     {path: 'dashbord',  component: MainDashbordComponent,data: { pageTitle: 'Tableau de bord'}, canActivate: [LoggedInGuardService]},
     {path: 'create-demande',  component: FormCreateDemandeComponent,data: { pageTitle: 'Création de demande'}, canActivate: [LoggedInGuardService]},
     {path: 'create-certificat',  component: FormCreateCertificatComponent,data: { pageTitle: 'Création de certificat'}, canActivate: [LoggedInGuardService]},
     {path: 'update-certificat/:isUpdateCertificat',  component: FormCreateCertificatComponent,data: { pageTitle: 'Création de certificat'}, canActivate: [LoggedInGuardService]},
    //  {path: 'update-demande/:isUpdateDemande',  component: FormCreateDemandeComponent,data: { pageTitle: 'Création de demande'}, canActivate: [LoggedInGuardService]},
      // {path: 'confirmer-resultat-recherche',  component: ConfirmerResultatAuthentificationComponent,data: { pageTitle: 'Confirmer le resultat de la recherche'}, canActivate: [LoggedInGuardService]},
      // {path: 'recherche-demande',  component: FormRechercheDemandeDgComponent,data: { pageTitle: 'Recherche de citoyen'}, canActivate: [LoggedInGuardService]},
      // {path: 'authentification-demande/:isForAuthentification',  component: FormRechercheDemandeDgComponent,data: { pageTitle: 'Authentification de citoyen'}, canActivate: [LoggedInGuardService]},
      // {path: 'demandes-clients',  component: MainDemandeClientsComponent,data: { pageTitle: 'Demande client'}, canActivate: [LoggedInGuardService]},
     
    ]
  },
 
  {
    path: 'adminstration',
    component: MainLayoutSidebarComponent,
    children: [
      {path: 'parametres', component: MainParametresComponent,data:{pageTitle: 'Paramètres'}, canActivate: [LoggedInGuardService]},
      {path: 'agents', component: MainAdministrationComponent,data:{pageTitle: 'Administration'}, canActivate: [LoggedInGuardService]},
      {path: 'commissariats', component: MainParametrageComissariatComponent,data:{pageTitle: 'Agence / commissariat'}, canActivate: [LoggedInGuardService]},
      {path: 'historique-connexion', component: MainHistoriqueConnexionComponent,data:{pageTitle: 'Historique connexion'}, canActivate: [LoggedInGuardService]},
      {path: 'approvisionnement',  component: MainApproStockComponent,data: { pageTitle: 'Appro et stock'}, canActivate: [LoggedInGuardService]},
   
    ]
  }, 
  
  
  {path: 'auth', component: AuthLayoutComponent,// loadChildren: 'app/+auth/auth.module#AuthModule',
  children: [
    {
      path: '', redirectTo: 'login', pathMatch: 'full',

    },
    {path: 'login', component: LoginComponent,data:{pageTitle: 'Connexion'}}
    ]
  },

  {path: '**', redirectTo: 'miscellaneous/error404'} 

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});
