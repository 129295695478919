import { Component, OnInit, OnDestroy } from '@angular/core';
import { AutoLogoutService } from './auto-logout.service';

@Component({
  selector: 'app-auto-logout',
  template: '',
  styleUrls: ['./auto-logout.component.scss'],
  providers: [AutoLogoutService]
})
export class AutoLogoutComponent implements OnInit, OnDestroy {

  constructor(private autoLogoutService: AutoLogoutService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.autoLogoutService.clearInterval();
  }

}
