import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../../user/user.service";

@Injectable()
export class LoggedInGuardService implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable <boolean>|Promise<boolean>|boolean {
    const loggedIn = this.userService.isLoggedIn(); 
    console.log("login",loggedIn);
    if (!loggedIn) {
      this.router.navigate(['/auth/login']); 
    }
    return loggedIn; 
  }
}
