import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { UserService } from 'app/shared/user/user.service';
import { enumKeyCrypto_AES } from './enumerator';
import { FormReconnectComponent } from '../../+components/component-app/form-reconnect/form-reconnect.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from './notification.service';
// import { RestClientService } from './core/restclient/restclient.service';

@Injectable()
export class httpRequestResponseInterceptor implements HttpInterceptor {
    private config: any = {};

    cfg = {
        base64Key: '',
        base64KeyFunc: function () {
            return ''
        },
        key: () => 'khqWPuvI+431q/0Ev1wVhzG3Po8Z0UIBlvM/fm6uGW0=',
        pattern: '_enc',
        logging: false,
        plugin: this.CryptoJSCipher(CryptoJS.mode.ECB, CryptoJS.pad.Pkcs7, CryptoJS.AES),
        contentHeaderCheck: this.ContentHeaderCheck(['application/json', 'application/json_enc']),
        responseWithQueryParams: true,
    }

    CryptoJSCipher(mode: any, padding: any, Cipher: any) {
        var mode = mode;
        var padding = padding;
        var Cipher = Cipher;
        return {
            encode: function (plainValue, base64Key) {
                if (!plainValue) { return plainValue; }
                if (base64Key.length <= 0) return plainValue;

                const key = CryptoJS.enc.Base64.parse(base64Key);
                // this is the decrypted data as a sequence of bytes
                const encryprtedData = Cipher.encrypt(plainValue, key, {
                    mode: mode,
                    padding: padding
                });
                // var encryprtedValue = encryprtedData.toString( CryptoJS.enc.Base64);
                return encryprtedData.toString();
            },
            decode: function (encryptedValue, base64Key) {
                if (base64Key.length <= 0) return encryptedValue;
                const key = CryptoJS.enc.Base64.parse(base64Key);
                // this is the decrypted data as a sequence of bytes
                const decryptedData = Cipher.decrypt(encryptedValue, key, {
                    mode: mode,
                    padding: padding
                });
                return decryptedData.toString(CryptoJS.enc.Utf8);
            }
        }
    }

    ContentHeaderCheck(headerToCrypt: any) {
        // const headerToCrypt = headerToCrypt;
        return {
            check: function (contentType) {
                for (let index = 0; index < headerToCrypt.length; index++) {
                    const result = contentType.startsWith(headerToCrypt[index]);
                    if (result) {
                        return true
                    };
                }
                return false;
            }
        }
    }

    user: any = {};

    constructor(private router: Router, private modalService: BsModalService, private userService : UserService, private notificationService: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //Verifier la connexion internet
        let statusConnexion = navigator.onLine;
        if(!statusConnexion)
        { 
            if(!sessionStorage.getItem(enumKeyCrypto_AES.pasDeConnexionInternet)) //Cette fenetre ne s'affiche qu'une seul fois
            {
                //Dans le cas ou c'est un refresh automatique ne pas afficher de message de connexion, ni serveur tarde a repondre mais faire un return
                if(request.body.isRefreshAuto)
                {
                    return;
                }

                this.notificationService.showMessageError("Connexion internet","Veuillez vérifier votre connexion internet.");
                
                sessionStorage.setItem(enumKeyCrypto_AES.pasDeConnexionInternet,"vfRezçà&éùjgdggvvcvwx21&");
               
                //Attendre 3 secondes et supprimer la valeur du sessionStorage afin que si il y a une actualisation manuel on afiche le message
                setTimeout(() => {
                    sessionStorage.removeItem(enumKeyCrypto_AES.pasDeConnexionInternet);
                }, 2000);
                return;
            }
           
        }
        else
        {
            // console.log("il y a internet",statusConnexion);
            sessionStorage.removeItem(enumKeyCrypto_AES.pasDeConnexionInternet);
            // this.notificationService.showMessageError("Connexion internet","Vous n'avez pas accès à internet.")
        }

        //  console.log('resquest before ecode', request);
        //  let headers: HttpHeaders = new HttpHeaders();

        //  this.user = this.userService.getCurrentUser(); 
        //  console.log("user connected",this.user);
        //  if(this.user && this.user.token) 
        //  {
        //     headers = headers.append('token', this.user.token);
        //  }

        //  request = request.clone({
        //                 headers: headers,
        //                 method: request.method,
        //                 body: request.body
        //             });

        //----------------------------------------------
        // Partie de crypto
        //----------------------------------------------

        // const toEncrypt = request.headers.get('To-Encrypte');
        // let isConnexionWs: boolean;
        // let headers: HttpHeaders = new HttpHeaders();
        // let tabUrl = request.url.split('/');
        // if (request.method === 'POST' && tabUrl[tabUrl.length - 1] !='motDePasseOublie') { 
        //     const token = JSON.parse(localStorage.getItem('yz-dashbord-app-bbg')) ? JSON.parse(localStorage.getItem('yz-dashbord-app-bbg')).token : null;
        //     // const token = this.restApi.getCurrentUserDatas() != null ? this.restApi.getCurrentUserDatas().token : undefined;
        //     isConnexionWs = request.body.connexionWs;
        //     if (isConnexionWs === true || toEncrypt === 'none') {
        //         console.log('isconnexion');
        //         headers = headers.append('Content-Type', 'application/json');
        //         headers = toEncrypt === 'none' ? headers.append('To-Encrypte', 'none') : headers;
        //         let req = {
        //             user: request.body.user,
        //             isSimpleLoading: false,
        //             data: request.body.data
        //         }

        //         request = request.clone({
        //             headers: headers,
        //             method: request.method,
        //             body: req
        //         });
        //     } else {
        //         headers = headers.append('Content-Type', 'application/json');
        //         headers = headers.append('sessionUser', token);
        //         request = request.clone({
        //             headers: headers,
        //             method: request.method,
        //             body: { data: this.cfg.plugin.encode(JSON.stringify(request.body), this.cfg.key()) }
        //         });
        //     }
        // }

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            // console.log('response data', event);
            if (event instanceof HttpResponse) {

                //---------------------------------
                //Effectuer la redirection
                //---------------------------------
                if (event && event.body) {
                    if (event.body.deconnecterSessionUser && !sessionStorage.getItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch)) {

                        console.log(" On affiche le popup de deconnexion ");
                        
                        //On affihce le pop-up mais bien avant on sette le sessionStorage pour ne pas
                        // que le deconnexion fait
                        sessionStorage.setItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch, "true");

                        sessionStorage.removeItem(enumKeyCrypto_AES.keyStoreAppAction);

                        this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray modal-sm  modal-login-reconnect' }))
                            .content.currentUser = this.userService.getCurrentUser(); 
                        let refreshTimout = this.modalService.onHide.subscribe(r => {
                            sessionStorage.removeItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch)
                        });

                    }
                }


                //---------------------------------
                // CRYPTO
                //---------------------------------

                if (request.method === 'POST' ||  request.method === 'GET') {
                    // if (toEncrypt === 'none') {

                    //         if(event.body.status && event.body.status.code == 925)
                    //         {
                    //             localStorage.removeItem("yz-dashbord-app-bbg");
                    //             this.router.navigate(['/auth/login']);
                    //         }
                    //         return event;
                    // } else  {
                    //     const data = event.body.item;
                    //     if (data === undefined) return event;
                    //      console.log('url', event.url, event.body);
                    //     event = event.clone({
                    //         body: JSON.parse(this.cfg.plugin.decode(data, this.cfg.key()))
                    //     });
                    //     // console.log('body retour', event.body);
                    // }
                }
            }
            return event;
        }));
    }
}

function decrypt(data, cfg, pattern) {
    if (typeof (data) !== 'undefined' && data !== null) {
        crypt(data, pattern, cfg.plugin.decode, cfg.key())
    } else {
        // console.log('data for decryption was null!')
    }
}

function encrypt(data, cfg, pattern) {
    // console.log('data to crypt', data);
    if (typeof (data) !== 'undefined' && data !== null) {
        crypt(data, pattern, cfg.plugin.encode, cfg.key());
        // console.log('data crypt', data);
    } else {
        // console.log('data for encryption was null!')
    }
}

function crypt(events, pattern, callback, base64Key) {
    if (events === "undefined" || events === null)
        return;
    var keys = Object.keys(events);
    for (var i in keys) {
        if (pattern !== undefined) {
            if (keys[i].endsWith(pattern))
                events[keys[i]] = callback(events[keys[i]], base64Key);
        }
        if (typeof events[keys[i]] === 'object')
            crypt(events[keys[i]], pattern, callback, base64Key)
        if (pattern === undefined || pattern === "*") {
            events[keys[i]] = callback(events[keys[i]], base64Key);
        }
    }
}

function checkHeader(cfg, contentType) {
    if (!contentType) {
        return false;
    }
    return (cfg.contentHeaderCheck.check(contentType));
}

String.prototype.startsWith = function (string) {
    return (this.indexOf(string) === 0);
};

String.prototype.endsWith = function (str) {
    var lastIndex = this.lastIndexOf(str);
    return (lastIndex != -1) && (lastIndex + str.length == this.length);
};
