import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
import { enumKeyCrypto_AES, enumProxy } from '../../../shared/utils/enumerator';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-main-administration',
  templateUrl: './main-administration.component.html',
  styleUrls: ['./main-administration.component.css']
})
export class MainAdministrationComponent implements OnInit {


  
  results: Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeCartes: Array<any> = [];
  ListeBureaux: Array<any> = [];
  ListeClients : Array<any> = [];
  ListeSearchInput: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  oldValueSearchInput: String;
  currentPageSearch: number = 1;
  totalItemsSearch: number;
  itemsPerPageSearch: number = 7;
  isPaginationChange: boolean;
  searchInputSelected: any = {};
  currentCentreSelected: any = {};
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  tabActive : number = 2;
  customMaskContact : string;
  enumProxyValue : any = {};

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.enumProxyValue = enumProxy;
    this.itemsPerPage = 15;
    this.currentItemChecked = {};
    this.itemToSearch = {};
    let  maxLengthContact = 10; // this.utilities.decryptData(sessionStorage.getItem(enumKeyCrypto_AES.longueurContactParam)) || 0;
    this.customMaskContact = this.utilities.formatMaskContact(maxLengthContact);

    console.log(" this.user  ",this.user );
    
  }

  checkPermission(code1,code2,code3)
  {
    // console.log(" code1 ",code1); 

    let returnValue = true;

    // let ListeFonctionnalites = this.user.fonctionnalites;

    // // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    // let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2  || element.code == code3 ));

    // if(itemFonctionnalite) 
    // {
    //   returnValue = true;
    // }
    
    return returnValue;

  }

  hideModal() {
    this.modalService.config.animated = false;
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    console.log("itemToModified ", itemToModified);
    this.cancelSave();

    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);
      this.itemToSave.roleSelected = _.find(this.ListeRoles, { 'id': itemToModified.idRole });

    }
   
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));
  }

  pageChanged(event: any): void {
    // console.log('Page changed to: ' + event.page);
    // console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if (search_value && this.oldValueSearch) {
      if (search_value != this.oldValueSearch) {
        this.currentPage = 1;
      }
    }

    if (search_value) {
      if (search_value.length > 0 && search_value.length < 3) {
        if (!this.isChangeItermPerPage) {
          return;
        }
      }
      else {
        if (search_value == this.oldValueSearch) {
          return;
        }
        this.oldValueSearch = search_value;
        //this.currentPage = 1;
      }
    }
    else {
      if (this.oldValueSearch == "" && isSearch) {
        return;
      }

      this.oldValueSearch = "";
    }

    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: search_value || null,
        client : (this.itemToSearch && this.itemToSearch.clientSearch)  ? this.itemToSearch.clientSearch.id : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('utilisateurs/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          // console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );

  }

  getDataRole() {
    var request = {
      user: this.user.id,
      data: {
        isCommissariat : (this.user.commissariatDto && this.user.commissariatDto.id) ? true : false
      }

    }

    this.api.post('profils/getByCriteria', request)
      .subscribe(
        res => {
          // console.log('profils with s',res);
          if (res && res['items']) {
            this.ListeRoles = res['items'];
          } else {
            this.ListeRoles = [];
          }
        },
        err => {
          //this.notificationService.defaultMessage()
        }
      );

  }


  getDataSearch() {

    let search_value = this.itemToSave.searchInputSelected || null;
    let isSearch = this.itemToSave.searchInputSelected ? true : false;

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        return;
      }

      if (this.oldValueSearchInput != search_value) {
        this.currentPageSearch = 1;
      }

      if (this.oldValueSearchInput == search_value && !this.isPaginationChange) {
        return
      }

      this.oldValueSearchInput = search_value;
    }

    var request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSave.searchInputSelected || null,
      },
      index: (this.currentPageSearch - 1),
      size: this.itemsPerPageSearch ? this.itemsPerPageSearch : null
    }

    this.busySave = this.api.post('bureaux/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(res);

          if (res && res['items']) {
            this.ListeSearchInput = res['items'];
            // this.AllListeLocaliteSearch = res['items'];
            this.totalItemsSearch = res['count'];
            this.itemToSearch.isDisplayTable = true;
            this.isPaginationChange = false;
          } else {
            this.ListeSearchInput = [];
            this.itemToSearch.isDisplayTable = false;
            this.totalItemsSearch = 0;
          }

        },
        err => {
        }
      );

  }

  pageChangedSearch(event: any): void {
    this.currentPageSearch = event.page;
    this.isPaginationChange = true;
    this.getDataSearch();
  }

  selectedItemAgence(centre) {
    if (centre && centre.id) {
      this.currentCentreSelected = Object.assign({}, centre);
      this.itemToSave.searchInputSelected = this.currentCentreSelected.libelle;
      this.itemToSearch.isDisplayTable = false;
    }else{
      this.currentCentreSelected  = {};
    }

  }

  confirmDeleteItem(obj) {
    //this.lgModal.show();

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj);
      }
    })

  }

  cancelSave() {
    this.itemToSave = {};
    this.currentCentreSelected = null;
  }


  confirmSave(objUser) {

    let item = Object.assign({}, objUser);

    if (!item || !item.nom || !item.prenoms || !item.contact || !item.roleSelected || !item.email) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les champs obligatoires.")
      return;
    }

    if(!item.id) {
      item.login = item.contact; 
    }

    // if(!this.currentCentreSelected || !this.currentCentreSelected.id){
    //   this.notificationService.showMessageError("Information", "Veuillez sélectionner une agence.")
    //   return;
    // }
   
    console.log(" item.roleSelected ",item.roleSelected);
    
    
    item.profil = null;
    item.idRole = item.roleSelected.id;
    // item.client = (this.itemToSave.clientSelected && this.itemToSave.clientSelected.id) ? this.itemToSave.clientSelected.id : null;
    item.fonctionnalites = null;
    // item.clientSelected = null;
    item.roleSelected = null;
    // item.isAgentBie = item.isAgentBie ? true : false;
    // item.bureau = (this.currentCentreSelected && this.currentCentreSelected.id) ? this.currentCentreSelected.id : null;
    // item.localite = (this.currentCentreSelected && this.currentCentreSelected.id) ? this.currentCentreSelected.id : null;
    //item.isCollecte = item.isCollecte ? true : false;

    swal({
      title: !item.proxy ? 'Enregistrer un agent' : 'Modifier un agent',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('utilisateurs/' + (item.proxy ? 'update' : 'create'), request)
      .subscribe(
        res => {
          // console.log("resul", res);
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Agent", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busySave = this.api.post('utilisateurs/delete', request)
      .subscribe(
        res => {
          // console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", 'Suppression effectuée avec succès');

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          console.log("Error occured", err);
          this.notificationService.defaultMessage()
          // this.not
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }


  confirmReinitPasswordItem(obj) {
    //this.lgModal.show();

    swal({
      title: 'Réinitialiser mot de passe',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f29302",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.resetPassWordUser(obj);
      } 
    })
  }

  confirmActiveOrDesactiveItem(obj, isDesactiveActive) {

    swal({
      title: 'Utilisateur',
      text: !isDesactiveActive ? 'Voulez-vous activer ce compte utilisateur ?' : 'Voulez-vous désactiver ce compte utilisateur',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f29302",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.desactiveOrActiveUser(obj);
      } 
    })

  }

  desactiveOrActiveUser(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post('utilisateurs/activerDesactiver', request)
      .subscribe(
        res => {
          // console.log(res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Utilisateur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.oldValueSearch = null;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }

        },
        err => {
        }
      );
  }


  resetPassWordUser(user) {

    let item = user;
    if (!item) {
      this.notificationService.showMessageInfo("Information", "Veuillez sélectionner un utilisateur.");
      return;
    }

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busyGet = this.api.post('utilisateurs/resetPassword', request)
      .subscribe(
        res => {
          // console.log("resul", res);
          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Utilisateur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.oldValueSearch = null;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          console.log("Error occured", err);
          // this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }



  exportData() {

    if (!this.results || this.results.length == 0) {
      this.notificationService.showMessageError("Exportation", "Aucune donnée à exporter.");
      return;
    }

    let search_value = this.itemToSearch.fullText ? this.itemToSearch.fullText.trim() : null;

    if (search_value) {
      if (search_value.length < 3) {
        search_value = null;
      }
    }

    var request = {
      user: this.user.proxy,
      data: {
        searchFullText: search_value || null,
      }
    }

    this.busyGet = this.api.post('user/exporter', request)
      .subscribe(
        res => {
          if (res && res['urlFileToDownload']) {
            window.location.href = res ? res['urlFileToDownload'] : window.location.href;
          }
          else {
            this.notificationService.showMessageSucces("Exportation", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
          }
        },
        err => {
        }
      );
  }

  ngOnInit() {
    this.getData();
    this.getDataRole();
    // this.getDataClient();
    window.scrollTo(0, 0);
    this.userService.getStateRoutageAdministration(false); 

    sessionStorage.removeItem(enumKeyCrypto_AES.pasDeConnexionInternet); 
   
    sessionStorage.removeItem(enumKeyCrypto_AES.popUpDeconnexionIsLunch)
  }


}
