import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { Api } from '../../../shared/utils/api/api';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { UserService } from "../../../shared/user/user.service";
import * as _ from "lodash";
const swal = require('sweetalert2');
import { enumLibelleCustom } from '../../../shared/utils/enumerator';

@Component({
  selector: 'app-main-parametres-base',
  templateUrl: './main-parametres-base.component.html',
  styleUrls: ['./main-parametres-base.component.css']
})
export class MainParametresBaseComponent implements OnInit {

  results: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  user: any;
  itemsPerPage: any;
  itemToSearch: any;
  oldValueSearch: String = "";
  modalRef: BsModalRef;
  isChangeItermPerPage: boolean;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api: Api, private modalService: BsModalService, private notificationService: NotificationService, private utilities: UtilitiesService, private userService: UserService) {
    this.cancelSave();
    this.user = this.userService.getCurrentUser();
    this.itemsPerPage = 15;
    // this.currentItemChecked = {};
    this.itemToSearch = {};
  }


  checkPermission(code1, code2, code3) {
    // console.log(" code1 ",code1); 

    let returnValue = null;

    let ListeFonctionnalites = this.user.fonctionnalites;

    // console.log(" ListeFonctionnalites ",ListeFonctionnalites);

    let itemFonctionnalite = ListeFonctionnalites.find(element => (element.code == code1 || element.code == code2 || element.code == code3));

    if (itemFonctionnalite) {
      returnValue = true;
    }

    return returnValue;

  }

  hideModal() {
    this.modalRef.hide();
  }

  openFormModalModal(template, itemToModified) {
    this.cancelSave();
    if (itemToModified) {

      this.itemToSave = Object.assign({}, itemToModified);

    }
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'gray modal-md' }));

  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.fullText;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if (search_value && this.oldValueSearch) {
      if (search_value != this.oldValueSearch) {
        this.currentPage = 1;
      }
    }

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        if (!this.isChangeItermPerPage) {
          return;
        }
      }
      else {
        if (search_value == this.oldValueSearch) {
          return;
        }
        this.oldValueSearch = search_value;
        //this.currentPage = 1;
      }
    }
    else {
      if (this.oldValueSearch == "" && isSearch) {
        return;
      }

      this.oldValueSearch = "";
    }

    var request = {
      user: this.user.proxy,
      data: {
        libelle: search_value || null,
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    this.busyGet = this.api.post('parametres/getByCriteria', request)
      .subscribe(
        res => {

          if (res && res['items']) {
            this.results = res['items'];

            this.results.forEach(element => {
              element.valeurFormat = element.valeur;

              if (element.libelle.toLowerCase() == enumLibelleCustom.DureeInactivite || element.libelle.toLowerCase() == enumLibelleCustom.dureeModificartionCertificat || element.libelle.toLowerCase() == enumLibelleCustom.dureeImpressionCertificat) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) + (parseFloat(element.valeur) > 1 ? " minutes" : " minute")
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.DureeValiditeCertificat) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) + " Mois";
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.PrixCertificatResidence || element.libelle.toLowerCase() == enumLibelleCustom.PrixFeuilletA5Securise) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) + " FCFA"; 
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.NombreDataExportable) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur);
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.TailleMaxPJ) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) +" Mo";
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.seuilAlerteFeuillet) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) +" Feuillet"+(element.valeur > 1 ? "s":"");
              }

              if (element.libelle.toLowerCase() == enumLibelleCustom.dureeMoyenCertificat.toLowerCase()) {
                element.valeurFormat = this.utilities.utilsFormatMillier(element.valeur) +" séconde"+(element.valeur > 1 ? "s":"");
              }

            });

            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
            if (res['hasError'] && res['status'] && res['status']['message']) {
              this.utilities.checkDeconnectionUser(res);
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {

          this.notificationService.defaultMessage()
          // this.not
        }
      );

  }


  confirmDeleteItem(obj) {

    swal({
      title: 'Suppression',
      text: 'Désirez-vous poursuivre cette action ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7b0c",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.deleteItem(obj);
      }
    })
  }

  cancelSave() {
    this.itemToSave = {};
  }


  confirmSave(item) {

    if (!item || !item.libelle || !item.valeur) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les champs obligatoires.")
      return;
    }

    swal({
      title: !item.id ? 'Enregistrer un paramètre' : 'Modifier un paramètre',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        this.saveItem(item);
      } else {
      }
    })
  }

  saveItem(item) {

    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.busySave = this.api.post('parametres/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {

          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Paramètre", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.currentPage = 1;
            this.hideModal();
            this.cancelSave();
            this.getData();
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Erreur", this.utilities.messageInterpretor(err));
        }
      );
  }


  deleteItem(obj) {

    var request = {
      user: this.user.proxy,
      datas: [
        obj
      ]
    }

    this.busyGet = this.api.post('parametres/delete', request)
      .subscribe(
        res => {

          if (!res['hasError']) {
            this.notificationService.showMessageSucces("Suppression", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));

            this.currentPage = 1;
            this.getData();
          }
          else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", res['status']['message']);
            }
          }

        },
        err => {
          this.notificationService.defaultMessage()
        }
      );
  }

  cancelSearch() {
    this.itemToSearch = {};
    this.changeItermPerPage();
  }


  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 10) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }

}
