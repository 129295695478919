import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

import {HeaderModule} from "./header/header.module";
import {FooterComponent} from "./footer/footer.component";
import {NavigationModule} from "./navigation/navigation.module";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {ShortcutComponent} from "./shortcut/shortcut.component";
import { LayoutSwitcherComponent} from "./layout-switcher.component";
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { RouterModule} from "@angular/router";
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { BsDropdownModule} from "ngx-bootstrap";
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import { BusyModule } from "angular2-busy";
import { Preview,FormatMiller,SafeUrlPipe} from '../utils/pipe_volontarait';
import { Api} from '../utils/api/api';
import { UtilitiesService} from '../utils/utilities.service';
import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgxMaskModule} from 'ngx-mask';
import { NgDatepickerModule } from 'ng2-datepicker';
import { SmartadminInputModule} from "../forms/input/smartadmin-input.module";

import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormReinitPasswordComponent } from '../../+components/form-reinit-password/form-reinit-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormReconnectComponent } from "../../+components/component-app/form-reconnect/form-reconnect.component";
import { Daterangepicker } from "ng2-daterangepicker";
import {ProgressBarModule} from "angular-progress-bar";
import { MainDashbordComponent } from "../../+components/component-app/main-dashbord/main-dashbord.component";
import { FormCreateDemandeComponent } from "../../+components/component-app/form-create-demande/form-create-demande.component";
import {MatRadioModule} from '@angular/material/radio';
import { MainLayoutSidebarComponent } from "./app-layouts/main-layout-sidebar/main-layout-sidebar.component";
import { MainParametresComponent } from "../../+components/component-app/main-parametres/main-parametres.component";
import { MainParametresBaseComponent } from "../../+components/component-app/main-parametres-base/main-parametres-base.component";
import { MainProfilComponent } from "../../+components/component-app/main-profil/main-profil.component";
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormPreviewPrintComponent } from "../../+components/component-app/form-preview-print/form-preview-print.component";
import { MainAccesPlateformeComponent } from "../../+components/component-app/main-acces-plateforme/main-acces-plateforme.component";
import { PopoverModule } from 'ngx-bootstrap/popover';
// import { FormRechercheDemandeDgComponent } from "../../+components/component-app/form-recherche-demande-dg/form-recherche-demande-dg.component";
import { AutoLogoutComponent } from "./auto-logout/auto-logout.component";
import { MainAdministrationComponent } from "../../+components/component-app/main-administration/main-administration.component";
import { MainUserSessionActiveComponent } from "../../+components/component-app/main-user-session-active/main-user-session-active.component";
import { SearchLocaliteComponent } from "app/+components/component-app/search-localite/search-localite.component";
import { MainVueMapDashbordComponent } from "app/+components/component-app/main-vue-map-dashbord/main-vue-map-dashbord.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MainParametreMetierComponent } from "app/+components/component-app/main-parametre-metier/main-parametre-metier.component";
import { MainParametreNationaliteComponent } from "app/+components/component-app/main-parametre-nationalite/main-parametre-nationalite.component";
import { MainParametreCategoriePieceJointesComponent } from "app/+components/component-app/main-parametre-categorie-piece-jointes/main-parametre-categorie-piece-jointes.component";
import { MainParametreMotifDemandeComponent } from "app/+components/component-app/main-parametre-motif-demande/main-parametre-motif-demande.component";
import { MainParametrageComissariatComponent } from "app/+components/component-app/main-parametrage-comissariat/main-parametrage-comissariat.component";
import { FormCreateCertificatComponent } from "app/+components/component-app/form-create-certificat/form-create-certificat.component";
import { SearchInputWithLibelleComponent } from "app/+components/component-app/search-input-with-libelle/search-input-with-libelle.component";
import { DetailsDocumentsJointComponent } from "app/+components/component-app/details-documents-joint/details-documents-joint.component";
import { PreviewPdfComponent } from "app/+components/component-app/preview-pdf/preview-pdf.component";
import { MainHistoriqueConnexionComponent } from "app/+components/component-app/main-historique-connexion/main-historique-connexion.component";
import { MainApprovisionnementCommissariatComponent } from "app/+components/component-app/main-approvisionnement-commissariat/main-approvisionnement-commissariat.component";
import { MainApproStockComponent } from "app/+components/component-app/main-appro-stock/main-appro-stock.component";
import { StockCommissariatComponent } from "app/+components/component-app/stock-commissariat/stock-commissariat.component";
import { FormPaiementCinetpayComponent } from "app/+components/component-app/form-paiement-cinetpay/form-paiement-cinetpay.component";


@NgModule({
  imports: [
    
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    RouterModule,
    // UtilsModule,
    BusyModule,
    // TooltipModule,
    BsDropdownModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // TypeaheadModule.forRoot(),
    // OrderModule,
    NgxMaskModule.forRoot(),
    // ChartModule,
    // MatTabsModule,
    NgDatepickerModule,
    // ImageCropperModule,
    // CKEditorModule,
    
    //SmartadminFormsModule
    NgSelectModule,
    SmartadminInputModule,
    // FormWizardModule,
    // NgQrScannerModule,
    // WebcamModule, 
    // LeafletModule.forRoot(), 
    Daterangepicker,
    ProgressBarModule,
    // TypeaheadModule.forRoot(),
    // InfiniteScrollModule,
    // NgxLoadingModule.forRoot({}),
    MatRadioModule,
    MatCheckboxModule,
    ProgressBarModule,
    // CaptchaModule,
    PopoverModule.forRoot(),
    NgMultiSelectDropDownModule
  
  ],
  declarations: [
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent, 
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    MainLayoutSidebarComponent,
    RouteBreadcrumbsComponent,
    Preview,
    FormatMiller,
    SafeUrlPipe,

    /*MALIN  */
    FormReinitPasswordComponent,
    FormReconnectComponent,
    MainDashbordComponent,
    FormCreateDemandeComponent, 
    MainParametresComponent,
    MainParametresBaseComponent,
    MainProfilComponent,
    FormPreviewPrintComponent,
    MainAccesPlateformeComponent,
    AutoLogoutComponent,
    MainAdministrationComponent,
    MainUserSessionActiveComponent,
    SearchLocaliteComponent,
    MainVueMapDashbordComponent,
    MainParametreMetierComponent,
    MainParametreNationaliteComponent,
    MainParametreCategoriePieceJointesComponent,
    MainParametreMotifDemandeComponent,
    MainParametrageComissariatComponent,
    FormCreateCertificatComponent,
    SearchInputWithLibelleComponent,
    DetailsDocumentsJointComponent,
    PreviewPdfComponent,
    MainHistoriqueConnexionComponent,
    MainApprovisionnementCommissariatComponent,
    MainApproStockComponent,
    StockCommissariatComponent,
    FormPaiementCinetpayComponent
  ],
 
  exports:[
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,

    /* MALIN 1.0 */
    FormReinitPasswordComponent,
    FormReconnectComponent,
    MainDashbordComponent,
    FormCreateDemandeComponent,
    MainParametresComponent,
    MainParametresBaseComponent,
    MainProfilComponent,
    FormPreviewPrintComponent,
    MainAccesPlateformeComponent,
    AutoLogoutComponent,
    MainAdministrationComponent,
    MainUserSessionActiveComponent,
    SearchLocaliteComponent,
    MainVueMapDashbordComponent,
    MainParametreMetierComponent,
    MainParametreNationaliteComponent,
    MainParametreCategoriePieceJointesComponent,
    MainParametreMotifDemandeComponent,
    MainParametrageComissariatComponent,
    FormCreateCertificatComponent,
    SearchInputWithLibelleComponent,
    DetailsDocumentsJointComponent,
    PreviewPdfComponent,
    MainHistoriqueConnexionComponent,
    MainApprovisionnementCommissariatComponent,
    MainApproStockComponent,
    StockCommissariatComponent,
    FormPaiementCinetpayComponent
  ],
  providers : [Api,UtilitiesService],
  bootstrap: [FormReinitPasswordComponent,FormReconnectComponent,FormPreviewPrintComponent,FormPaiementCinetpayComponent]
})
export class SmartadminLayoutModule{

}
